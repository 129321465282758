/**
 *
 */
import React, {Component} from "react";
import {
    Select,
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Switch,
    DatePicker,
    Popconfirm,
    TreeSelect, Cascader
} from "antd";
import PageTitle from "../../common/pageTitle";
import MyTable from "../../common/myTable/myTable";
import {reqGetPageDevice, reqUpdateDevice, reqUpdateDevicePos} from "../../api/llzApi";
import {reqCloseVideo, reqGetPageApkList,reqAddDevice,getStatisticDevices} from '../../api/clApi'
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {PAGE_SIZE, SUCCESS} from "../../utils/constants";
import Map from "./map/deviceMap";
import SetPositionMap from "./map/setPositionDeviceMap";
import moment from "moment";
import {isArray} from "cmn-utils/es/utils";
import {regCompoundGetZone, regCompoundGetZoneChild} from "../../api";
const { confirm } = Modal;
const {Option} = Select
export default class  extends Component {
    state={
        cityOptions:[],
        deviceList:[],
        apkList:[],
        keyTime:"",
        deviceStatus:[
            {
                label:'正常',
                value:'normal'
            },
            {
                label:'离线',
                value:'offline'
            },
            {
                label:'温度异常',
                value:'temp-exception'
            },
            {
                label:'湿度异常',
                value:'hum-exception'
            },
            {
                label:'温湿度异常',
                value:'temp-hum-exception'
            },
            {
                label:'一键报警中',
                value:'one-key-alarming'
            },
            {
                label:'报警中',
                value:'alarming'
            },
        ],
        pageState:'deviceList',
        selectSetMarkDevice:{},
        deviceMapVisible:false,
        deviceUpdateVisible:false,
        deviceSetPointMapVisible:false,

    };
    searchForm =React.createRef()
    deviceMap=React.createRef()
    updateDeviceForm=React.createRef()
    deviceSetPointMap = React.createRef()
    downDeviceForm = React.createRef()
    addDeviceForm = React.createRef()

    componentWillMount(){
        this.initColumn()
    }

    componentDidMount(){
        this.getAllProvince()
        this.getDeviceList(1)
        this.getApkList()
        this.getStatistic()
    }
    /**
     *接口请求函数
     */
    getDeviceList=async(pageNum)=>{
        this.setState({tableLoading:true});
        let value = this.searchForm.current.getFieldsValue();
        const res = await reqGetPageDevice(pageNum,PAGE_SIZE,value?value:{});
        this.setState({tableLoading:false});
        if(res && res.code &&res.code===200){
            res.pageNum=pageNum;
            this.setState({deviceList:res,pageNum:pageNum})
        }
    };
    getStatistic=async ()=>{
        let res = await getStatisticDevices()
        if(res.code==200){
            this.setState({
                onlineNumber:res.result.onlineCountStatistic.onlineNum,
                offNumber:res.result.onlineCountStatistic.offlineNum,
            })
        }
        console.log(res)
    }
    getApkList=async()=>{
        const res = await reqGetPageApkList();
        if(res && res.code &&res.code===200){
            this.setState({apkList:isArray(res.results)?res.results:[]})
        }
    };

    updateLngLat = async(value) =>{
        const res = await reqUpdateDevicePos(value);
        if(res.code === 200){
            message.success("更改成功！")
            await this.getDeviceList(this.state.pageNum)
        }else {
            message.info('更改失败！')
        }
    };

    updateDevice=async(value)=>{
        const res = await reqUpdateDevice(value);
        if(res.code === 200){
            message.success("更改成功！")
        }else {
            message.info('更改失败！')
        }
    }

    closeVideo=async(e,num)=>{
        const res = await reqCloseVideo(e,num);
        if(res.code === 200){
            message.success("关闭成功！")
        }else {
            message.info('关闭失败！')
        }
    }
    startChange=(e,dateString)=>{this.setState({startTime:dateString})}
    endChange=(e,dateString)=>{this.setState({endTime:dateString})}
    render() {
        const {selectSetMarkDevice,deviceSetPointMapVisible,deviceStatus,apkList,loading,cityOptions,onlineNumber,offNumber,tableLoading,
            deviceUpdateVisible,deviceMapVisible,deviceList,pageState,videoDownVisible,showAddDevice} = this.state;
        const  contentList={
            card_title:<PageTitle title="设备管理"/>,
            card_extra:<div style={{display:'flex',alignItems:"center"}}>
                <h3 style={{margin:"0 20px",color:"green"}}>在线数：{onlineNumber}</h3>
                <h3 style={{margin:"0 20px",color:"red"}}>离线数：{offNumber}</h3>
                <Form ref={this.searchForm}>
                <Row gutter={15}>
                <Col>
                    <Form.Item style={{marginBottom: 10}} name="deviceId">
                        <Input style={{width: 150}} placeholder="设备编号"/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item style={{marginBottom: 10}} name="deviceStatus">
                        <Select  style={{ width: 120 }} placeholder="设备状态" allowClear>
                            <Option value={"1"}>在线</Option>
                            <Option value={"2"}>离线</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item style={{marginBottom: 10}} name="deployStatus">
                        <Select  style={{ width: 120 }} placeholder="部署状态" allowClear>
                            <Option value="deployed">已部署</Option>
                            <Option value="none-deployed">未部署</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col >
                    <Button type="primary" onClick={()=>{this.getDeviceList(1)}}>搜索</Button>
                    <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                    <Button style={{margin: '0 8px'}} onClick={()=>{this.setState({showAddDevice:true})}}>增加设备</Button>
                </Col>
                </Row>
            </Form></div>,
            deviceList:<div>
                <MyTable column={this.deviceColumn} loading={tableLoading} data={deviceList} pageFun={(c)=>{this.getDeviceList(c)}}/>
            </div>,
        };

        return (
            <div className="page_home">
                <Card className="card_main" title={contentList['card_title']} extra={contentList['card_extra']}>
                    {contentList[pageState]}
                </Card>
                <Modal
                    forceRender={true}
                    title="地图位置"
                    width="600px"
                    okText='确定'
                    cancelText='关闭'
                    visible={deviceMapVisible}
                    onOk={()=>{this.setState({deviceMapVisible:false})}}
                    onCancel={()=>{this.setState({deviceMapVisible:false})}}>
                    <Map ref={this.deviceMap}/>
                </Modal>
                <Modal
                    forceRender={true}
                    title="修改地图位置"
                    width="800px"
                    okText='确定'
                    cancelText='关闭'
                    visible={deviceSetPointMapVisible}
                    onOk={async()=>{
                        // this.setState({deviceSetPointMapVisible:false})
                        const {selectSetMarkDevice} = this.state;
                        if(selectSetMarkDevice.pos || selectSetMarkDevice.locationDescIsChange){
                            this.showConfirm(selectSetMarkDevice)
                        }else {
                            this.setState({deviceSetPointMapVisible:false,selectSetMarkDevice:{}})
                        }
                    }}
                    onCancel={()=>{
                        this.setState({deviceSetPointMapVisible:false,selectSetMarkDevice:{}})
                    }}>
                    <SetPositionMap parent={this} ref={this.deviceSetPointMap}/>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div>
                            位置描述：
                        </div>
                        <div style={{width:'80%'}} >
                            <Input onChange={(e)=>{
                                let selectSetMarkDevice = this.state.selectSetMarkDevice;
                                selectSetMarkDevice.locationDesc = e.target.value;
                                if(!selectSetMarkDevice.pos){
                                    selectSetMarkDevice.pos = ''
                                }
                                selectSetMarkDevice.locationDescIsChange = true
                                this.setState({selectSetMarkDevice:selectSetMarkDevice})

                            }}  value={selectSetMarkDevice.locationDesc?selectSetMarkDevice.locationDesc:null} placeholder={'请输入位置描述信息'}/>
                        </div>
                    </div>
                </Modal>
                <Modal
                    forceRender={true}
                    title="设备配置"
                    okText='确定'
                    cancelText='关闭'
                    visible={deviceUpdateVisible}
                    onOk={async()=>{
                        let validateValues = await this.updateDeviceForm.current.validateFields();
                        let updateDevice = this.state.updateDevice
                        // if(!validateValues.oneKeyNumber){
                        //     message.info('一键报警电话号码不能为空！')
                        //     return
                        // }else
                        if(!validateValues.reportFrequency){
                            message.info('设备状态上报频率不能为空！')
                            return
                        }else if(!validateValues.newVersion){
                            message.info('新版号不能为空！')
                            return
                        }
                        console.log(typeof(validateValues.newVersion))
                        let newVersion=""
                        let downloadUri=""
                        if(typeof(validateValues.newVersion)!="number"){
                             newVersion = validateValues.newVersion.split(",")[0]
                             downloadUri = validateValues.newVersion.split(",")[1]
                        }
                        console.log(newVersion,downloadUri)
                        let values = this.updateDeviceForm.current.getFieldsValue()
                        values.id = updateDevice.id
                        if(typeof(validateValues.newVersion)!="number"){
                            values.newVersion = newVersion
                            values.downloadUri = downloadUri
                        }
                        await this.updateDevice(values)
                        await this.getDeviceList(1)
                        this.setState({deviceUpdateVisible:false,updateDevice:{}})

                    }}
                    onCancel={()=>{this.setState({deviceUpdateVisible:false,updateDevice:{}})}}>
                    <Form ref={this.updateDeviceForm} style={{width:'100%'}}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="联系人" name="contact"  >
                                    <Input style={{width:'100%'}} placeholder="请输入联系人"  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="联系电话" name="contactNumber"  rules={[{
                                    pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/, message: '请输入正确的手机号'
                                }]}>
                                    <Input  placeholder="请输入联系电话" type={"number"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row gutter={24}>*/}
                        {/*    <Col span={24}>*/}
                        {/*        <Form.Item  style={{marginBottom:10}} label="一键报警电话号码" name="oneKeyNumber"  rules={[{*/}
                        {/*            pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/, message: '请输入正确的手机号'*/}
                        {/*        }]}>*/}
                        {/*            <Input  placeholder="请输入电话号码" type={"number"} />*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="设备状态上报频率" name="reportFrequency"  >
                                    <InputNumber style={{width:'100%'}} placeholder="请输入设备状态上报频率"  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="音　量" name="volume"  >
                                    <Input type={"number"} max="100" min="0" placeholder="音量（0~100）"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="新版号" name="newVersion"  >
                                    {/*<Input style={{width:'100%'}} placeholder="请输入新版号"  />*/}
                                    <Select
                                        style={{ width: 200 }}
                                        placeholder="选择版本"
                                        optionFilterProp="children"
                                        onChange={this.selectChangeVersion}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            apkList.map((item,index)=>{
                                                return <Option key={item.version} value={item.version+","+item.downloadUri}>{item.version}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    forceRender={true}
                    title="添加设备"
                    okText='确定'
                    cancelText='关闭'
                    visible={showAddDevice}
                    confirmLoading={loading}
                    key={this.state.keyTime}
                    onOk={async()=>{
                        let validateValues = await this.addDeviceForm.current.validateFields();
                        let updateReservoir = this.state.updateReservoir
                        let values = this.addDeviceForm.current.getFieldsValue()
                        values.zoneCode = values.zoneCode[values.zoneCode.length-1]
                        await this.addAddDevice(values)
                        this.setState({showAddDevice:false,keyTime:new Date(),updateReservoir:{}})

                    }}
                    onCancel={()=>{this.setState({showAddDevice:false,updateReservoir:{}})}}>
                    <Form ref={this.addDeviceForm} style={{width:'100%'}}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="设备序号" name="deviceId">
                                    <Input  placeholder="设备序号"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="设备类型" name="deviceType">
                                    <Input  placeholder="设备类型"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="设备电话" name="deviceNumber">
                                    <Input  placeholder="设备电话"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="接听电话" name="spPhone">
                                    <Input  placeholder="接听电话"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="设备位置" name="location">
                                    <Input  placeholder="设备位置"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="位置描述" name="locationDesc">
                                    <Input  placeholder="位置描述"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="联系人" name="contact">
                                    <Input  placeholder="联系人"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="联系电话" name="contactNumber">
                                    <Input  placeholder="联系电话"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="所属区域" name={'zoneCode'} style={{"margin":"0px 10px 0px 0px"}} rules={[{required: true, message: '请选择地区！'}]}>
                            <Cascader
                                options={cityOptions}
                                loadData={this.cascaderLoader}
                                changeOnSelect
                            />
                        </Form.Item>

                    </Form>
                </Modal>
                <Modal
                    forceRender={true}
                    title="视频回放"
                    okText='确定'
                    cancelText='关闭'
                    visible={videoDownVisible}
                    onOk={async()=>{
                        let validateValues = await this.downDeviceForm.current.validateFields();
                        let updateDevice = this.state.updateDevice
                        console.log(moment(validateValues.startTime).unix())
                        console.log(moment(validateValues.endTime).unix())
                        if(moment(validateValues.endTime).unix()<moment(validateValues.startTime).unix()){
                            message.info("结束时间不得小于开始时间")
                            return
                        }
                        if(moment(validateValues.endTime).unix()-moment(validateValues.startTime).unix()>30){
                            message.info("视频超过30秒")
                            return
                        }
                        if(!validateValues.startTime){
                            message.info('时间不能为空！')
                            return
                        }
                        let values = this.downDeviceForm.current.getFieldsValue()
                        values.id = updateDevice.id
                        values.startTime = this.state.startTime
                        values.endTime = this.state.endTime
                        console.log(values,updateDevice)
                        // await this.updateDevice(values)
                        this.setState({videoDownVisible:false,updateDevice:{}})

                    }}
                    onCancel={()=>{this.setState({videoDownVisible:false,updateDevice:{}})}}>
                    <div>视频长度不要超过30秒</div>
                    <Form ref={this.downDeviceForm} style={{width:'100%'}}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="开始时间" name="startTime"  >
                                    <DatePicker showTime onChange={this.startChange}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="结束时间" name="endTime"  >
                                    <DatePicker showTime onChange={this.endChange}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        )
    }
    //函数
    cascaderLoader=async (selectedOptions)=>{
        const selectedOption = selectedOptions[selectedOptions.length-1];
        selectedOption.loading=true;
        const {cityOptions} = this.state;
        //加载效果
        await this.setState({
            cityOptions: cityOptions
        });
        selectedOption.children = await this.getZoneChildList(selectedOption.value);
        selectedOption.loading=false
        //刷新数据
        this.setState({
            cityOptions: cityOptions
        });
    }
    //获取所有省
    getAllProvince=async ()=>{
        let res = await regCompoundGetZone();
        if(res && res.code && res.code === SUCCESS){
            const options = this.getYunFirstOptions(res.results);
            this.setState({
                cityOptions: options
            })
        }else{
            message.error("获取省市区列表失败");
        }
    }
    getYunFirstOptions=(list)=>{
        let options = [];
        list.forEach(item => {
            options.push({
                value: item.code,
                label: item.name,
                isLeaf: false,
            })
        })
        return options;
    }
    getZoneChildList=async (zoneCode)=>{
        let res = await regCompoundGetZoneChild({code:zoneCode});
        if(res && res.code && res.code===SUCCESS){
            return res.results.map(zone => {
                return {
                    value: zone.code,
                    label: zone.name,
                    isLeaf: zone.level===3,
                }
            })
        }else{
            message.error("网络错误");
        }
    }
    showConfirm = (value)=> {
        const _that = this;
        confirm({
            title: '确定要修改设备在地图位置吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确定',
            // okType: 'danger',
            cancelText: '取消',
            onOk (){
                _that.updateLngLat(value).then(r => r);
                _that.setState({deviceSetPointMapVisible:false,selectSetMarkDevice:{}})
            },
            onCancel() {

            },
        });
    }

    setSelectSetMark=(pos)=>{
        const { selectSetMarkDevice} = this.state;
        let posObject = {
            pos:pos
        }
        this.setState({selectSetMarkDevice:{...selectSetMarkDevice,...posObject}})
    };
    //重启
    restart=async (e)=>{
        let id = e.id
        let value = {
            id:id,
            isReboot:1
        }
        let res = await reqUpdateDevice(value)
        console.log(res)
        if(res.code==200){
            message.success("重启成功")
        }else{
            message.success("重启失败")
        }
    }
    //更新
    update=async (e)=>{
        let id = e.id
        let value = {
            id:id,
            isNowUpdate:1
        }
        let res = await reqUpdateDevice(value)
        console.log(res)
        if(res.code==200){
            message.success("更新成功")
        }else{
            message.success("更新失败")
        }
    }
    //选择版本
    selectChangeVersion=(e)=>{
        console.log(e)
    }
    //添加设备
    addAddDevice=async (values)=>{
        this.setState({loading:true})
        let res = await reqAddDevice(values)
        this.setState({loading:false})

        if(res.code==200){
            message.success("添加成功")
            this.getDeviceList(1)
        }else{
            message.error("网络繁忙")
        }
        console.log(values)
    }
    initColumn=()=>{
        this.deviceColumn=[
            {
                title: '设备序号',
                dataIndex: 'deviceId',
                key: 'deviceId',
                align: 'center',
                fixed:'left'
            },
            {
                title: '设备电话',
                dataIndex: 'deviceNumber',
                key: 'deviceNumber',
                align: 'center',
            },
            {
                title: '设备状态',
                dataIndex: 'deviceStatus',
                key: 'deviceStatus',
                align: 'center',
                render:(e)=>{
                    return(
                        <div>{this.state.deviceStatus.find(item=>item.value===e)?this.state.deviceStatus.find(item=>item.value===e).label:null}</div>

                    )
                }
            },
            {
                title: '设备类型',
                dataIndex: 'deviceType',
                key: 'deviceType',
                align: 'center',
            },
            {
                title: '联系人',
                dataIndex: 'contact',
                key: 'contact',
                align: 'center',
            },
            {
                title: '联系人电话',
                dataIndex: 'contactNumber',
                key: 'contactNumber',
                align: 'center',
            },
            {
                title: '位置',
                dataIndex: 'location',
                key: 'location',
                align: 'center',
                width: '200px',
            },
            {
                title: '位置描述',
                dataIndex: 'locationDesc',
                key: 'locationDesc',
                align: 'center',
            },
            {
                title: '部署状态',
                key: 'deployStatus',
                align: 'center',
                render:(e)=>{
                    let status=e.deployStatus==='deployed'?true:false
                    return(
                        <Switch checkedChildren="已部署" unCheckedChildren="未部署" checked={status} onChange={async(checked)=>{
                            let status = checked?'deployed':'none-deployed'
                            await this.updateDevice({id:e.id,deployStatus:status})
                            await this.getDeviceList(this.state.pageNum)
                        }} />
                    )
                }
            },
            // {
            //     title: 'FRP状态',
            //     key: 'frpStatus',
            //     align: 'center',
            //     render:(e)=>{
            //         let status=e.frpStatus==='open'?true:false
            //         return(
            //             <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={status} onChange={async(checked)=>{
            //                 let status = checked?'open':'close'
            //                 await this.updateDevice({id:e.id,frpStatus:status})
            //                 await this.getDeviceList(this.state.pageNum)
            //             }} />
            //         )
            //     }
            // },
            {
                title: '心跳时间',
                dataIndex: 'heartbeatTime',
                key: 'heartbeatTime',
                align: 'center',
            },
            {
                title: '地图位置',
                key: 'pos',
                align: 'center',
                width: 100,
                render:(e)=>{
                    console.log(e)
                    return(
                        <div>
                            <Button type="link" onClick={()=>{this.setState({MapPos:e.coordinate},()=>{
                                this.deviceMap.current.initMarker(e.coordinate);
                                this.setState({deviceMapVisible:true})
                            })}}>地图位置</Button>
                            <Button type="link" onClick={()=>{
                                this.setState({selectSetMarkDevice:{id:e.id}},()=>{
                                    this.deviceSetPointMap.current.initMarker(e.coordinate);
                                    this.state.selectSetMarkDevice.locationDesc = e.locationDesc
                                    this.setState({deviceSetPointMapVisible:true})
                                });
                            }}>更新</Button>
                        </div>


                    )
                }
            },
            {
                title: '操作',
                key: 'update',
                width:'100px',
                align: 'center',
                render:(e)=>{
                    return(
                        <Button type="link" onClick={()=>{
                            this.setState({deviceUpdateVisible:true,updateDevice:e},()=>{
                                this.updateDeviceForm.current.setFieldsValue({
                                    oneKeyNumber:e.oneKeyNumber,
                                    reportFrequency:e.reportFrequency,
                                    newVersion:e.newVersion,
                                    volume:e.volume
                                })
                            })
                        }}>配置</Button>
                    )
                }
            },
            // {
            //     title: '更新',
            //     key: 'restart',
            //     align: 'center',
            //     render:(e)=>{
            //         return  <Popconfirm title="是否更新？" onConfirm={this.update.bind(this,e)} okText="确定" cancelText="取消">
            //                     <Button type="primary" >更新</Button>
            //                 </Popconfirm>
            //
            //     }
            // },
            // {
            //     title: '重启',
            //     key: 'restart',
            //     align: 'center',
            //     render:(e)=>{
            //         return  <Popconfirm title="是否重启？" onConfirm={this.restart.bind(this,e)} okText="确定" cancelText="取消">
            //                     <Button type="danger" >重启</Button>
            //                 </Popconfirm>
            //     }
            // },
            {
                title: '关闭视频',
                key: 'close',
                fixed:'right',
                width:'100px',
                align: 'center',
                render:(e)=>{
                    return(
                        <div>
                            <Button type="link" onClick={this.closeVideo.bind(this,e,0)}>关闭0</Button>
                            <Button type="link" onClick={this.closeVideo.bind(this,e,1)}>关闭1</Button>
                        </div>

                    )
                }
            },
            // {
            //     title: '视频回放',
            //     key: 'downLoad',
            //     fixed:'right',
            //     width:'100px',
            //     align: 'center',
            //     render:(e)=>{
            //         return(
            //             <Button type="link" onClick={()=>{
            //                 this.setState({videoDownVisible:true,updateDevice:e})
            //             }}>下载</Button>
            //         )
            //     }
            // },
        ]
    }
}