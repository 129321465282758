import React, {Component} from 'react';
import './index.css'
import {
    reqCloseStream,
    reqGetDeviceChannelList,
    reqGetDeviceChannelUpdateStatus,
    reqOpenStream,
    reqUpdateDeviceChannel
} from '../../api/index'
import {Button, Form, message, Select} from "antd";
import 'antd/dist/antd.css'
import header1 from '../../assets/videoImages/header1.png'
import goBack from '../../assets/videoImages/goback.png'
import sessionUtils from "../../utils/sessionUtils";
import {SUCCESS} from "../../utils/constants";
import FlvPlayer from "xgplayer-flv";

let player = null;
class matter extends Component{
    constructor(props) {
        super(props);
        this.state={
            list:[],
            loading:false,
            deviceInfo: {},
            isLive: false,
            channelList:[],
            channelLoading: false,
            videoUrl: null,
        }
        this.searchFormRef = React.createRef();
        this.deviceChannelUpdateTimer = "";
    }

    componentWillUnmount() {
        if(this.deviceChannelUpdateTimer){
            clearInterval(this.deviceChannelUpdateTimer);
        }
    }

    componentDidMount(){
        let deviceInfo = sessionUtils.getDeviceInfo();
        if(!deviceInfo || !deviceInfo.deviceId){
            message.error("获取摄像头数据失败")
        }
        this.getDeviceChannelList(deviceInfo.deviceId);
        this.setState({
            deviceInfo: deviceInfo
        })
        player = new FlvPlayer({
            id: 'mse',
            isLive: true,
            playsinline: true,
            height: '405px',
            width: '720px',
            controls: false,
            url:'',
            errorTips: `请<span>刷新</span>试试`
        });
    }

    render() {
        const {channelList, loading, deviceInfo, isLive, channelLoading} = this.state;
        return (
            <div className={'video'} style={{width: "100%", height: "100%"}}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "rgb(8,9,102)",
                    padding: "5px",
                    alignItems: "center"
                }}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <img src={header1} alt="" style={{width: "70px", height: "50px"}}/>
                        <h2 style={{color: "white", fontWeight: "600"}}>视频系统</h2>
                    </div>
                    <div style={{marginRight: "20px", cursor: "pointer"}} onClick={this.goBack}>
                        <img src={goBack} alt="" style={{width: "40px", height: "40px"}}/>
                        <p style={{color: "white", textAlign: "center", marginBottom: "0", fontWeight: "600"}}>返回</p>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", paddingTop: "2%", height: "88%"}}>
                    <div style={{
                        width: "40%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                    >
                        <div id='mse' />
                    </div>

                    <div className={"MatterRight"} style={{width: "40%", height: "100%"}}>
                        <div style={{borderBottom: "1px solid white", paddingBottom: "20px", marginBottom: "20px"}}>
                            <h2 style={{color: "white"}}>基本信息</h2>
                            <div style={{display: "flex", color: "white", justifyContent: "space-between"}}>
                                <div><span>摄像头序列号：</span>{deviceInfo.deviceId}</div>
                                {/*<div><span>所属服务：</span>{deviceInfo.localName}</div>*/}
                                {/*<div><span>所属组织：</span>{deviceInfo.organizationName}</div>*/}
                            </div>
                        </div>
                        <Button type="primary" loading={isLive} onClick={this.start}>开始直播</Button>
                        <Button type="danger" onClick={this.close} style={{marginLeft: "20px"}}>结束直播</Button>
                        <div style={{display:"inline-block"}}>
                            <Form ref={this.searchFormRef}
                                  style={{margin:"0 15px ", display:"inline-block"}}
                                  name="advanced_search"
                                  className="ant-advanced-search-form"
                            >
                                <Form.Item  style={{marginBottom:10}} name="channelId" rules={[{required:true, message:"请选择通道"}]} >
                                    <Select placeholder="选择通道" style={{width:"250px"}}>
                                        {channelList.map(channel => <Select.Option value={channel.channelId} key={"channel" + channel.channelId}>
                                            {channel.model} | {channel.channelId}
                                        </Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>
                        <Button
                            loading={channelLoading}
                            onClick={this.updateDeviceChannel}>
                            更新通道
                        </Button>
                        {/*<div style={{position: "relative",marginTop:"20px"}}>*/}
                        {/*    <div style={{color:"white"}}>云台控制</div>*/}
                        {/*    <PanTilt></PanTilt>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }

    start=async ()=>{
        const form = this.searchFormRef.current;
        const _that = this;
        form.validateFields().then(async (values)=>{
            _that.setState({
                isLive:true,
                loading:true
            },async ()=>{
                const {deviceInfo} = _that.state
                let res = await reqOpenStream(deviceInfo.deviceId, values.channelId)
                _that.setState({loading: false, channelId: values.channelId})
                if(res && res.code === 200){
                    if(player){
                        player.destroy(false);
                    }
                    player = new FlvPlayer({
                        id: 'mse',
                        isLive: true,
                        playsinline: true,
                        height: '405px',
                        width: '720px',
                        url: res.result,
                        autoplay:true,
                    });
                }else{
                    message.error("点播失败")
                }
            })
        })
    }

    close=async ()=>{
        const {deviceInfo, channelId} = this.state
        message.destroy()
        let res = await reqCloseStream({
            deviceId:deviceInfo.deviceId,
            channelId:channelId
        })
        if(res && res.code === 200){
            if(player){
                player.destroy(false);
            }
            player = new FlvPlayer({
                id: 'mse',
                isLive: true,
                playsinline: true,
                height: '405px',
                width: '720px',
                controls: false,
                url:'',
            });
            this.setState({
                isLive:false,
                loading:false,
                videoUrl: null,
            })
        }
    }
    goBack=()=>{
        window.history.back()
    }

    getDeviceChannelList=async (deviceId)=>{
        const res = await reqGetDeviceChannelList(deviceId);
        if(res && res.code && res.code === SUCCESS){
            this.setState({
                channelList: res.results
            })
        }
    }

    updateDeviceChannel=async ()=>{
        const {deviceInfo} = this.state;
        this.setState({channelLoading: true});
        const res = await reqUpdateDeviceChannel({deviceId:deviceInfo.deviceId});
        if(res && res.code && res.code === SUCCESS){
            //开启轮询
            this.deviceChannelUpdateTimer = setInterval(() => this.getDeviceChannelUpdateStatus(), 1000);
        }else{
            this.setState({channelLoading: false})
            message.error("更新失败");
        }
    }

    getDeviceChannelUpdateStatus=async ()=>{
        const {deviceInfo} = this.state;
        const res = await reqGetDeviceChannelUpdateStatus({deviceId:deviceInfo.deviceId});
        if(res && res.code && res.code === SUCCESS){
            const {result} = res;
            if(result && result.current === 1){
                //轮询结束
                this.setState({channelLoading: false})
                if(this.deviceChannelUpdateTimer){
                    clearInterval(this.deviceChannelUpdateTimer);
                }
                message.success('更新成功，共获取'+ result.total +'条通道数据');
                this.getDeviceChannelList(deviceInfo.deviceId);
            }else if(result && result.errorMsg){
                message.error(result.errorMsg);
                if(this.deviceChannelUpdateTimer){
                    clearInterval(this.deviceChannelUpdateTimer);
                }
            }
        }else{
            this.setState({channelLoading: false});
            message.error("更新失败");
            if(this.deviceChannelUpdateTimer){
                clearInterval(this.deviceChannelUpdateTimer);
            }
        }
    }

}
export default matter;
