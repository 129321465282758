import React, {Component} from 'react'
import {Button, Card, Col, Descriptions, Form, Input, message, Modal, Row, Select, Table, TreeSelect} from 'antd'
import jiahao from '../../assets/Img/加号.png'
import {
    reqRoleGetPermissionList,
    reqRoleGetRoleList,
    reqRoleInsertRole,
    reqRolePermissionList,
    reqRoleUpdateRole
} from '../../api'
import {SUCCESS} from "../../utils/constants";
import {removePropertyOfNull} from "../../utils/aladdin";

const {Option} = Select;
export default class Role extends Component {
    constructor(props) {
        super(props);
        this.state={
            permissions:[],
            selectedPermissions:[],
            updatePermissions:[],
            roles:[],
            roleTotal:0,
            oldRoleName:null,
            oldRoleDesc:null,
            oldRoleId:null,
            visible:false,//更新
            visible2:false,//新增
            loading:true,
            show:false,
            count:0,
            uploading:false,
            //权限树
            permissionTree:[],
            treeSelectValue:[],
            oldTreeSelectValue:[],
            updateTreeSelectValue:[],

            //Modal里面的受控组件属性
            updateRoleName:'',
            updateRoleDesc:'',
            roleName:'',
            roleDesc:'',
            showPermission:false,
            showRecord:null,
            selectNode:null,
            pageNum:1,
        }

        this.searchFormRef = React.createRef()
    }
    initColumns =()=>{
        this.column = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                render:(text,record,index)=>{
                    return index+1;
                }
            },
            {
                title: '角色名称',
                dataIndex: 'roleDesc',
                key: 'roleDesc',
                align: 'center',
            },
            {
                title: '权限',
                dataIndex: 'permissions',
                key: 'permissions',
                align: 'center',
                width:'300px',
                render:(text, record)=>{
                    const permissionStr = this.getPermissionModule(record.permissions);
                    if(permissionStr.length>50){
                        return (
                            <div>
                                {permissionStr.substring(0,50)+"..."}
                                <Button type={'link'} onClick={()=>{this.showPermissionDetails(permissionStr)}}>详情</Button>
                            </div>
                        );
                    }else{
                        return (
                            <div>
                                {permissionStr}
                            </div>
                        );
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                align: 'center',
                render:(text, record, index)=>{
                    return (
                        <div>
                            <Button type="primary" onClick={(e)=>{this.showRoleUpdate(e, record)}}>修改</Button>
                        </div>
                    )
                }
            }
        ]
    }

    searchOnFinish=(values)=>{
        removePropertyOfNull(values);
        this.getRoleList(values);
    }

    //获取权限树
    getAllPermissionList = async ()=>{
        const res = await reqRolePermissionList();
        if(res.code === SUCCESS){
            let nodes = this.dto2treeData(res.results);
            this.setState({
                permissionTree:nodes
            })
        }

    }
    dto2treeData(treeData){
        let nodes = [];
        if(treeData){
            treeData.map(e => {
                let node = {
                    "key":e.id,
                    "title":e.permissionDesc,
                    "value":e.id
                };
                if(e.children){
                    node.children = this.dto2treeData(e.children);
                }
                nodes.push(node);
            })
        }
        return nodes;
    }

    //获取权限列表
    getPermissionList= async (param)=>{
        let res = await reqRoleGetPermissionList(param);
        if(res.code === SUCCESS){
            if(res.results && res.results.length>0){
                this.getPermissionOptions(res.results);
            }
        }else{
            message.error("获取权限列表失败");
        }
    }
    //渲染权限单选框
    getPermissionOptions=(permissionList)=>{
        let options = [];
        if(permissionList && permissionList.length>0) {
            permissionList.map((e, i) => {
                options.push(<Option key={e.id} value={e.id}>{e.permissionDesc}</Option>);
            });
        }
        this.setState({
            permissionOptions: options
        })
    }
    //获取用户列表
    getRoleList=async (param)=>{
        this.tableLoading(true);
        const res = await reqRoleGetRoleList(param);
        this.tableLoading(false);
        if(res.code===200){
            this.setState({
                roles:res.result.content,
                roleTotal:res.result.totalElements,
                pageNum: param.pageNum?param.pageNum:1,
            });
        }
    }
    freshTable=async (node)=>{
        let param = this.getSearchValues();
        this.getRoleList(param)
    }

    //获取表格权限模块
    getPermissionModule=(permissions)=>{
        let map = permissions.map((permission)=>{
            return permission.permissionDesc;
        });
        return map.join("，");
    }
    //渲染表格加载效果
    tableLoading=(flag)=>{
        this.setState({
            loading:flag
        })
    }
    //重置搜索框
    handleResetClick=()=>{
        this.searchFormRef.current.resetFields();
    }
    //获取搜索参数
    getSearchValues=()=>{
        const form = this.searchFormRef.current;
        let searchValues = form.getFieldsValue();
        removePropertyOfNull(searchValues);
        return searchValues;
    }

    handleOk=()=>{
        const updateRoleName = this.state.updateRoleName;
        const updateRoleDesc = this.state.updateRoleDesc;
        const permissions = this.state.updateTreeSelectValue;
        const id = this.state.oldRoleId;
        let param = {};
        let flag = 0;
        if(updateRoleName){
            param.roleName = updateRoleName;
            flag++;
        }
        if(updateRoleDesc){
            param.roleDesc = updateRoleDesc;
            flag++;
        }
        if(id){
            param.id = id;
            flag++;
        }
        if(permissions){
            param.permissions = permissions.map((e) => {
                return {
                    id:e
                }
            });
            flag++;
        }
        if(flag === 0){
            message.error("无法修改角色");
            return;
        }
        this.setState({
            visible:false
        },async ()=>{
            const res = await reqRoleUpdateRole(param);
            if(res.code && res.code === 200){
                message.success("修改成功")
                this.getRoleList({});
                this.getAllPermissionList();
            }else{
                message.error("修改失败")
            }
        })

    }
    //打开新增弹窗
    showAdd=()=>{
        this.setState({
            visible2:true
        })
    }

    handleOk2=()=>{
        const roleName = this.state.roleName
        const roleDesc = this.state.roleDesc
        const selectedPermissions = this.state.treeSelectValue;
        let param = {};
        if(roleName){
            param.roleName = roleName;
        }
        if(!roleDesc){
            message.error("角色名不能为空")
            return
        }else {
            param.roleDesc = roleDesc;
        }
        if(!selectedPermissions || selectedPermissions.length===0){
            message.error("请选择角色权限")
            return
        }
        const permissions = selectedPermissions.map((p) => {
            return {
                id:p
            }
        });
        param.permissions = permissions;
        this.setState({
            visible2:false
        },async ()=>{
            const res = await reqRoleInsertRole(param);
            if(res.code && res.code === 200){
                message.success("添加成功")
                setInterval(()=> window.location.reload(), 500);
            }else{
                message.error("添加失败")
            }
        })
    }

    handleCancel=()=>{
        Modal.destroyAll();
        this.setState({
            visible:false,
            visible2:false,
            addPicShow:false,
            treeSelectValue:[],
            oldRoleName:null,
            oldRoleDesc:null,
            oldRoleId:null,
        });
        const roleName = document.getElementById("roleName");
        if(roleName){
            roleName.value = null;
        }
        const roleDesc = document.getElementById("roleDesc");
        if(roleDesc){
            roleDesc.value = null
        }
    }

    //打开修改用户弹窗
    showRoleUpdate=(e, record)=>{
        const selectData = this.getOldPermissions(record);
        this.setState({
            visible:true,
            oldRoleName:record.roleName,
            oldRoleDesc:record.roleDesc,
            oldRoleId:record.id,
            oldTreeSelectValue:selectData,
            updateTreeSelectValue:selectData,
        })
    }

    getOldPermissions(record){
        if(record && record.permissions && record.permissions.length>0){
            return record.permissions.map((e)=>{
                return e.id;
            })
        }
        return [];
    }
    //树形选择器
    onTreeSelectChange=(value)=>{
        this.setState({
            treeSelectValue:value
        })
    }
    onTreeSelectUpdateChange=(value)=>{
        this.setState({
            updateTreeSelectValue:value
        })
    }

    componentWillMount(){
        this.initColumns();
    }

    componentDidMount(){
        this.getAllPermissionList();
        this.getRoleList({});
        this.getPermissionList({});
    }

    render() {
        const { SHOW_PARENT } = TreeSelect;
        const _that =this
        const {showPermission,showRecord,pageNum} = this.state;
        const contentList = {
            permissionModal: <Modal
                title="详情"
                visible={showPermission}
                onOk={()=>{this.setState({showPermission: false})}}
                onCancel={()=>{this.setState({showPermission: false})}}
            >
                <p>
                    {showRecord?showRecord:null}
                </p>
            </Modal>,
        };
        return (
            <div className="page_home">
                <Card className="card_main"
                      title="角色管理"
                      headStyle={{fontSize: 20, letterSpacing: 5, fontWeight: 600}}
                      extra={<div style={{margin:"10px 0",position:"relative"}}>
                          <Button type="primary" onClick={this.showAdd} style={{margin:"0 10px", display:"inline-block"}}>
                              <img src={jiahao} alt="" style={{width:"20px",height:"20px",marginRight:"3px"}}/>添加角色
                          </Button>
                          <Form ref={this.searchFormRef}
                                size="small"
                                style={{margin:"0 15px ", display:"inline-block"}}
                                name="advanced_search"
                                className="ant-advanced-search-form"
                                onFinish={this.searchOnFinish} >
                              <Row gutter={24}>
                                  <Col >
                                      <Form.Item  style={{marginBottom:10}} name="roleDesc"  >
                                          <Input style={{width:150}} placeholder="请输入角色名"/>
                                      </Form.Item>
                                  </Col>
                                  <Col >
                                      <Form.Item  style={{marginBottom:10}} name="permissionId"  >
                                          <Select style={{width:150}} placeholder="请选择权限">
                                              {this.state.permissionOptions}
                                          </Select>
                                      </Form.Item>
                                  </Col>
                                  <Col >
                                      <Button type="primary" htmlType="submit">搜索</Button>
                                      <Button style={{ margin: '0 8px' }} onClick={this.handleResetClick}>重置</Button>
                                  </Col>
                              </Row>
                          </Form>
                      </div>}>
                    <div style={{height:'100%',display:'flex'}}>
                        <div style={{height:'100%',width:'100%',margin:10}}>
                            <Table  columns={this.column} bordered rowKey={record => record.id} dataSource={this.state.roles} loading={this.state.loading}
                                    rowClassName={(record, index) => {
                                        let className = 'light-row';
                                        if (index % 2 === 1) className = 'dark-row';
                                        return className;
                                    }}
                                    pagination={{
                                        total: _that.state.roleTotal,
                                        showTotal: function () {  //设置显示一共几条数据
                                            return '共 ' + _that.state.roleTotal + ' 条数据';
                                        },
                                        current:pageNum,
                                        pageSize: 10,
                                        onChange(current) {
                                            let param = _that.getSearchValues();
                                            param.pageNum = current;
                                            _that.getRoleList(param);
                                        },
                                    }}
                            />
                        </div>
                    </div>
                </Card>
                <div>
                    {contentList['permissionModal']}
                    <Modal title="修改角色" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} key={this.state.key} maskClosable={false}>
                        <Descriptions column={1}>
                            <Descriptions.Item label="角&nbsp;&nbsp;色&nbsp;&nbsp;名">
                                <Input type="text" style={{display:"block",width:"80%",marginBottom:"5px"}} defaultValue={this.state.oldRoleDesc}
                                       onChange={event=>{this.setState({updateRoleDesc:event.target.value})}}/>
                            </Descriptions.Item>
                            <Descriptions.Item label="权&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;限">
                                <TreeSelect
                                    treeData={this.state.permissionTree}
                                    onChange={this.onTreeSelectUpdateChange}
                                    value={this.state.updateTreeSelectValue}
                                    placeholder={"请选择权限"}
                                    treeCheckable={true}
                                    showCheckedStrategy={SHOW_PARENT}
                                    style={{"width":"80%"}}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    </Modal>
                    <Modal title="新增角色" visible={this.state.visible2} onOk={this.handleOk2} onCancel={this.handleCancel} key={this.state.key+1} maskClosable={false}>
                        <Descriptions column={1}>
                            <Descriptions.Item label="角&nbsp;&nbsp;色&nbsp;&nbsp;名">
                                <Input type="text" placeholder="请输入角色名"  style={{display:"block",width:"80%",marginBottom:"5px"}}  onChange={event=>{this.setState({roleDesc:event.target.value})}}/>
                            </Descriptions.Item>
                            <Descriptions.Item label="权&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;限">
                                <TreeSelect
                                    treeData={this.state.permissionTree}
                                    onChange={this.onTreeSelectChange}
                                    value={this.state.treeSelectValue}
                                    placeholder={"请选择权限"}
                                    treeCheckable={true}
                                    showCheckedStrategy={SHOW_PARENT}
                                    style={{"width":"80%"}}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    </Modal>
                </div>
            </div>
        )
    }
    showPermissionDetails=(permissions)=>{
        this.setState({
            showPermission: true,
            showRecord: permissions,
        })
    }
}