import  {HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete} from './AxiosUtils'
import {message} from 'antd'
import {URL_SAFONE} from '../config/urlConf/urlConf'
import jsonp from 'jsonp';
import {values} from "mobx";
//{login调用}
//登录接口
export const reqLogin = (value) =>{
    return HttpClientpost(URL_SAFONE+"/api-platform/login",value);
}

//{/backend/role调用}
//获取权限树
export const reqRolePermissionList = () => HttpClientget(URL_SAFONE + "/api-platform/permission/allPermission", {}, {});
//获取权限列表
export const reqRoleGetPermissionList = (param) => HttpClientpost(URL_SAFONE+"/api-platform/permission/list",{param},{});
//获取用户列表
export const reqRoleGetRoleList = (param) => HttpClientpost(URL_SAFONE+"/api-platform/role/list",{...param},{});
//修改角色
export const reqRoleUpdateRole = (param) =>HttpClientpost(URL_SAFONE+`/api-platform/role/update`,{...param},{});
//添加角色
export const reqRoleInsertRole = (param) =>HttpClientpost(URL_SAFONE+`/api-platform/role/add`,{...param},{});

//{/backend/user调用}
//获取角色列表
export const reqUserGetRoleList = (param) =>  HttpClientget(URL_SAFONE+"/api-platform/role/allList",param,{});
//获取账号列表
export const reqUserGetUserList = (param) =>HttpClientpost(URL_SAFONE+"/api-platform/user/list",{...param},{});
//停用账号
export const reqUserStop = (record)=>HttpClientget(URL_SAFONE+`/api-platform/user/stopUsing/`+record.id,{},{});
//启用账号
export const reqUserReuseing = (record)=>HttpClientget(URL_SAFONE+`/api-platform/user/reusing/`+record.id,{},{});
//修改账户
export const reqUserUpdate = (param)=>HttpClientpost(URL_SAFONE+`/api-platform/user/update`, {...param},{});
//添加账户
export const reqUserInsert = (param)=> HttpClientpost(URL_SAFONE+`/api-platform/user/add`,{...param},{});
//修改密码
export const reqUserUpdatePassword = (param)=>HttpClientpost(URL_SAFONE+`/api-platform/user/update`,{...param},{});
//移除部门
export const reqUserRemoveDepartment = (id)=>HttpClientget(URL_SAFONE+'/api-platform/user/removeUserDepartment?id='+id, {}, {});
//第三方授权登陆
export const reqUserAuth= (jwt)=>HttpClientget(URL_SAFONE + "/api-platform/login/userAuth", {jwt: jwt}, {})


//上传图片接口(获取权限)
export const reqCompoundGetAuth =(file)=>HttpClientget(URL_SAFONE+"/api-platform/aliyun/getAuth",{},{});
//获取行政区相关接口
//获取所有省份
export const regCompoundGetZone=(compound)=>HttpClientget(URL_SAFONE+"/api-platform/zone/provinceList",compound,{})
//获取所有省份的子节点
export const regCompoundGetZoneChild=(compound)=>HttpClientpost(URL_SAFONE+"/api-platform/zone/childList",compound,{})
//根据查询行政区域编码，反查所有父级行政编码及其所属层级列表
export const regCompoundGetZoneFather=(code)=>HttpClientpost(URL_SAFONE+"/api-platform/zone/fatherDto/list",code,{})


//组织架构接口
//获取所有子节点列表，不包含id
export const reqGetAllPrimaryOrganizationTree=()=>HttpClientget(URL_SAFONE+"/api-platform/organization/allPrimary",{}, {})
export const reqAddOrganization=(param)=>HttpClientpost(URL_SAFONE+"/api-platform/organization/add", {...param}, {})
export const reqDeleteOrganization=(param)=>HttpClientpost(URL_SAFONE+"/api-platform/organization/delete",{...param}, {})
export const reqAddDepartment=(param)=>HttpClientpost(URL_SAFONE+"/api-platform/organization/department/add", {...param}, {});
export const reqUpdateOrganization=(param)=>HttpClientpost(URL_SAFONE+"/api-platform/organization/update", {...param}, {});

//字典相关接口
//获取字典分类
export const reqGetAllType=()=>HttpClientget(URL_SAFONE+"/api-platform/safe/cms/dictionary/getAllType",{}, {})
//获取字典
export const reqGetDataByType=(value)=>HttpClientget(URL_SAFONE+"/api-platform/safe/cms/dictionary/getDataByType",{type:value}, {})
//获取当前用户部门下所有用户列表
export const reqGetDepartmentUser=(value)=>HttpClientget(URL_SAFONE+"/api-platform/user/department/userList",value, {})

//获取上传授权
export const reqGetUploadAuth=()=>HttpClientget(URL_SAFONE + "/api-platform/apk/getAuth", {}, {});

//设备
//获取设备列表
export const reqGetDeviceList=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/device/query", {...param}, {});
//删除设备
export const reqDeleteDevice=(deviceId)=>HttpClientget(URL_SAFONE + "/api-platform/device/delete?deviceId="+deviceId, {}, {});
//获取设备频道列表
export const reqGetDeviceChannelList=(deviceId)=>HttpClientget(URL_SAFONE + "/api-platform/device/getDeviceChannelList", {deviceId: deviceId}, {});

//视频接口
//开启视频流转码
export const reqOpenStream=(deviceId, channelId)=>HttpClientget(URL_SAFONE+"/api-platform/platform/openStream", {deviceId: deviceId, channelId: channelId}, {});
//关闭视频流转码
export const reqCloseStream=(param)=>HttpClientget(URL_SAFONE+"/api-platform/platform/closeStream", {...param}, {});
//云台控制
export const ptzControl = (param)=>HttpClientpost(URL_SAFONE+'/api-platform/platform/ptzControl',{...param},{})
//获取国标录像
export const reqGetGBRecordList=(param)=>HttpClientpost(URL_SAFONE + '/api-platform/platform/getDeviceRecordList', {...param}, {});
//播放国标录像
export const reqPlayGBRecord=(param)=>HttpClientpost(URL_SAFONE + '/api-platform/platform/playRecord', {...param}, {});
//获取录像列表
export const reqGetRecordList =(param) =>HttpClientget(URL_SAFONE + "/api-platform/platform/getRecordVideoList", {...param}, {});
//播放录像
export const reqPlayRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/getRecordCloudPlayURL_SAFONE", {...param}, {});
//获取报警列表
export const reqGetAlarmList=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/getAlarmList", {...param} ,{});
//停止录像播放
export const reqStopPlayingRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/playStop", {...param}, {});
//拖拽录像播放
export const reqSeekPlayingRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/playSeek", {...param}, {});
//倍速录像播放
export const reqSpeedPlayingRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/playSpeed", {...param}, {});
//下载录像
export const reqGetRecordDownloadStart=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/record/task/add", {...param}, {});
//停止下载录像
export const reqGetRecordDownloadStop=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/record/task/stop", {...param}, {});
//分页查询录像列表
export const reqGetRecordTaskList=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/record/task/query", {...param}, {});