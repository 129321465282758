import  {HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete,FetchApi} from './AxiosUtils'
import {message} from 'antd'
import {URL,URL_SAFONE,zoneCodeConfig} from '../config/urlConf/urlConf'
import jsonp from 'jsonp';
import {param} from "cmn-utils/es/utils";
import {values} from "mobx";
//{login调用}
//登录接口
export const reqLogin = (value) =>{
    value.projectCode = 'safe-system'
    return HttpClientpost(URL+"/api/usercenter/login",value);
}

//{/backend/role调用}
//获取权限树
export const reqRolePermissionList = () => HttpClientget(URL + "/api/usercenter/permission/allPermission", {}, {});
//获取权限列表
export const reqRoleGetPermissionList = (param) => HttpClientpost(URL+"/api/usercenter/permission/list",{param},{});
//获取用户列表
export const reqRoleGetRoleList = (param) => HttpClientpost(URL+"/api/usercenter/role/list",{...param},{});
//修改角色
export const reqRoleUpdateRole = (param) =>HttpClientpost(URL+`/api/usercenter/role/update`,{...param},{});
//添加角色
export const reqRoleInsertRole = (param) =>HttpClientpost(URL+`/api/usercenter/role/add`,{...param},{});

//{/backend/user调用}
//获取角色列表
export const reqUserGetRoleList = (param) =>  HttpClientget(URL+"/api/usercenter/role/allList",param,{});
//获取账号列表
export const reqUserGetUserList = (param) =>HttpClientpost(URL+"/api/usercenter/user/list",{...param},{});
//停用账号
export const reqUserStop = (record)=>HttpClientget(URL+`/api/usercenter/user/stopUsing/`+record.id,{},{});
//启用账号
export const reqUserReuseing = (record)=>HttpClientget(URL+`/api/usercenter/user/reusing/`+record.id,{},{});
//修改账户
export const reqUserUpdate = (param)=>HttpClientpost(URL+`/api/usercenter/user/update`, {...param},{});
//添加账户
export const reqUserInsert = (param)=> HttpClientpost(URL+`/api/usercenter/user/add`,{...param},{});
//修改密码
export const reqUserUpdatePassword = (param)=>HttpClientpost(URL+`/api/usercenter/user/update`,{...param},{});
//移除部门
export const reqUserRemoveDepartment = (id)=>HttpClientget(URL+'/api/usercenter/user/removeUserDepartment?id='+id, {}, {});


//上传图片接口(获取权限)
export const reqCompoundGetAuth =(file)=>HttpClientget(URL+"/api/aliyun/getAuth",{},{});
//获取行政区相关接口
//获取所有省份
export const regCompoundGetZone=(compound)=>HttpClientget(URL+"/api/usercenter/zone/provinceList",compound,{})
//获取所有省份的子节点
export const regCompoundGetZoneChild=(compound)=>HttpClientpost(URL+"/api/usercenter/zone/childList",compound,{})
//根据查询行政区域编码，反查所有父级行政编码及其所属层级列表
export const regCompoundGetZoneFather=(code)=>HttpClientpost(URL+"/api/usercenter/zone/fatherDto/list",code,{})


//组织架构接口
//获取所有子节点列表，不包含id
export const reqGetAllPrimaryOrganizationTree=(value)=>HttpClientget(URL+"/api/usercenter/organization/allPrimary",value, {})
export const reqAddOrganization=(param)=>HttpClientpost(URL+"/api/usercenter/organization/add", {...param}, {})
export const reqDeleteOrganization=(param)=>HttpClientpost(URL+"/api/usercenter/organization/delete",{...param}, {})
export const reqAddDepartment=(param)=>HttpClientpost(URL+"/api/usercenter/organization/department/add", {...param}, {});
export const reqUpdateOrganization=(param)=>HttpClientpost(URL+"/api/usercenter/organization/update", {...param}, {});
//上级平台
export const reqAddDevice2Upstream=(id)=>HttpClientget(URL_SAFONE+"/api-platform/upstream/platform/add?id="+id, {}, {});
export const reqUpdateDevice2Upstream=(id)=>HttpClientget(URL_SAFONE+"/api-platform/upstream/platform/update?id="+id, {}, {});


//文字转语音
//文本转语音文件
export const reqGetText2Audio=(param)=>HttpClientget(URL_SAFONE + '/api-platform/common/text2audio',{...param},{})
//获取语音文件转换状态
export const reqGetText2audioStatus=(param)=>HttpClientget(URL_SAFONE + '/api-platform/common/getText2audioStatus',{...param},{})
//下载语音文件
export const reqGetDownloadText2audio=(param)=>HttpClientget(URL_SAFONE + '/api-platform/common/downloadText2audio',{...param},{})

//设备
//获取设备列表
export const reqGetDeviceList=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/safe/device/query", {...param}, {});
//新增设备
export const reqAddDevice=(values)=>HttpClientpost(URL_SAFONE + "/api-platform/safe/device/add", {...values}, {});
//修改设备
export const reqUpdateDevice=(values)=>HttpClientpost(URL_SAFONE + "/api-platform/safe/device/update", {...values}, {})
//查询安防设备可选摄像头列表
export const reqGetDeviceListUnbind=(values)=>HttpClientget(URL_SAFONE + "/api-platform/safe/device/getDeviceListUnbind", {...values}, {})
//获取设备频道列表
export const reqGetDeviceChannelList=(deviceId)=>HttpClientget(URL_SAFONE + "/api-platform/device/getDeviceChannelList", {deviceId: deviceId}, {});
//获取所有本地服务
export const reqGetAllLocal=()=>HttpClientget(URL_SAFONE+"/api-platform/local/getAll", {}, {});
//更新设备通道
export const reqUpdateDeviceChannel=(param)=>HttpClientget(URL_SAFONE+"/api-platform/device/updateDeviceChannel", {...param}, {});
//获取更新通道状态
export const reqGetDeviceChannelUpdateStatus=(param)=>HttpClientget(URL_SAFONE+"/api-platform/device/getDeviceChannelUpdateStatus", {...param}, {});

//设备标签
//获取设备标签列表
export const reqGetDeviceTagList=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/deviceTag/query", {...param}, {});
//新增设备标签
export const reqAddDeviceTag=(values)=>HttpClientpost(URL_SAFONE + "/api-platform/deviceTag/add", {...values}, {});
//修改设备标签
export const reqUpdateDeviceTag=(values)=>HttpClientpost(URL_SAFONE + "/api-platform/deviceTag/update", {...values}, {})
//删除设备标签
export const reqDeleteDeviceTag=(id)=>HttpClientget(URL_SAFONE + "/api-platform/deviceTag/delete", {id:id}, {});
//获取所有设备标签
export const reqGetAllDeviceTagList=()=>HttpClientget(URL_SAFONE + "/api-platform/deviceTag/list", {}, {});
//设备分组
//获取所有设备标签
export const reqGetAllDeviceGroupList=()=>HttpClientget(URL_SAFONE + "/api-platform/deviceGroup/list", {}, {});

//视频接口
//开启视频流转码
export const reqOpenStream=(deviceId, channelId)=>HttpClientget(URL_SAFONE+"/api-platform/platform/openStream", {deviceId: deviceId, channelId: channelId}, {});
//关闭视频流转码
export const reqCloseStream=(param)=>HttpClientget(URL_SAFONE+"/api-platform/platform/closeStream", {...param}, {});
//云台控制
export const ptzControl = (param)=>HttpClientpost(URL_SAFONE+'/api-platform/platform/ptzControl',{...param},{})
//获取国标录像
export const reqGetGBRecordList=(param)=>HttpClientpost(URL_SAFONE + '/api-platform/platform/getDeviceRecordList', {...param}, {});
//播放国标录像
export const reqPlayGBRecord=(param)=>HttpClientpost(URL_SAFONE + '/api-platform/platform/playRecord', {...param}, {});
//获取录像列表
export const reqGetRecordList =(param) =>HttpClientget(URL_SAFONE + "/api-platform/platform/getRecordVideoList", {...param}, {});
//播放录像
export const reqPlayRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/getRecordCloudPlayUrl", {...param}, {});
//获取报警列表
export const reqGetAlarmList=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/getAlarmList", {...param} ,{});
//查询转码任务
export const reqGetVideoRecordStatus=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/getRecordStatus", {...param}, {});
//停止录像播放
export const reqStopPlayingRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/playStop", {...param}, {});
//拖拽录像播放
export const reqSeekPlayingRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/playSeek", {...param}, {});
//倍速录像播放
export const reqSpeedPlayingRecord=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/platform/playSpeed", {...param}, {});
//下载录像
export const reqGetRecordDownloadStart=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/record/task/add", {...param}, {});
//停止下载录像
export const reqGetRecordDownloadStop=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/record/task/stop", {...param}, {});
//分页查询录像列表
export const reqGetRecordTaskList=(param)=>HttpClientpost(URL_SAFONE + "/api-platform/record/task/query", {...param}, {});
//根据设备号直接开启视频流
export const reqOpenStreamByDeviceId=(param)=>HttpClientget(URL_SAFONE+"/api-platform/platform/openStreamByDeviceId", {...param}, {});

//字典相关接口
//获取字典分类
export const reqGetAllType=()=>HttpClientget(URL+"/api/safe/cms/dictionary/getAllType",{}, {})
//获取字典
export const reqGetDataByType=(value)=>HttpClientget(URL+"/api/safe/cms/dictionary/getDataByType",{type:value}, {})
//获取当前用户部门下所有用户列表
export const reqGetDepartmentUser=(value)=>HttpClientget(URL+"/api/usercenter/user/department/userList",value, {})


//风险类型接口
//获取风险分类列表
export const reqGetRiskType=(value)=>HttpClientget(URL+"/api/safe/cms/riskType/getRiskTypeList",value, {})
//获取风险列表
export const reqGetRiskListByType=(value)=>HttpClientget(URL+"/api/safe/cms/riskType/findListByType",value, {})




//获取所有最高级组织架构
export const reqCollegeGetAllPrimary = (type)=> HttpClientget(URL+"/api/usercenter/organization/allPrimary/?"+"type="+type,{},{});

//安防设备
//获取安防设备详情
export const reqGetSafeDeviceByDeviceId = (param) => HttpClientget(URL_SAFONE + "/api-platform/safe/device/getSafeDeviceByDeviceId", {...param} ,{});


//通用接口
//获取防溺水设备区域数据
export const reqGetPreventDeviceAreaData = () => HttpClientget(URL+"/api/safe/device/area/centerAreaTree?zoneCode="+zoneCodeConfig, {} ,{});


/**
 * jsonp 请求的接口请求函数
 */
export const reqWeather = () =>{
    return new Promise((resolve,reject)=>{
        const  url = "https://restapi.amap.com/v3/weather/weatherInfo?city=500000&key=e2d5ef9729c069c0db50d2173572d12f";
        jsonp(url,{},(error,data)=>{
            console.log('jsonp',error,data);
            if(!error && data.status==='1'){
                console.log(data);
                resolve(data);
            }else {
                message.error("请求出错：",error.message);
            }
        })
    })
}