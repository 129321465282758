import React,{Component} from 'react'
import {CustomMenu}  from "../../common/customMenu/index";

class SiderNav extends Component {
  render() {

    return (
      <div>
        {/*<div styles={styles.logo} >*/}
          {/*<span styles={{color:'white'}}>logo</span>*/}
        {/*</div>*/}
        <CustomMenu theme={this.props.theme} menus={this.props.menuList}/>
      </div>
    )
  }
}

const styles = {
  logo: {
    height: '64px',
    background: 'rgba(255, 255, 255, .2)',
    margin: '16px'
  }
}

export default SiderNav