/**
 *
 */
import React, {Component} from 'react'
export default class  extends Component {

    render() {
        const {title,extra,bodyStyle,style} = this.props;

        return (
            <div style={{width:"100%",height:'100%',display:'flex',flexDirection:'column',background:'white',...style}}>
               <div style={{width:'100%',padding:'16px 24px 16px 24px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                   <div>
                       {title?title:""}
                   </div>
                   <div>
                       {extra?extra:''}
                   </div>
               </div>
                <div style={{width:"100%",height:'1px',background:'#F0F0F0'}}/>
                    <div style={{flexGrow:1,width:'100%',height:'100%',overflowY:'scroll',...bodyStyle}}>
                        {this.props.children}
                    </div>
            </div>
        )
    }



}