import React, {Component} from 'react';
//import { Button,message } from 'antd';
/**
 * BrowserRouter :路由器
 * HashRouter 带#号
 *
 * Route:路由
 */
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom'
/**
 * 路由页面
 */
import Login from './pages/login'
import Admin from './pages/admin/admin'
import Screen from './components/screenHome/screenHome'
import Scroll from './components/screenHome/componets/scroll'
import SelectProvince from './pages/selectProvince/selectProvince'
import Video from "./components/video";
import Record from "./components/record";

var width = document.documentElement.clientWidth
document.documentElement.style.fontSize=(width/10)+"px"
export default class App extends Component{

    render(){
        return (
            <HashRouter>
                <Switch>/*只匹配其中一个匹配到了就不再匹配*/
                    <Route path='/login' component={Login}/>
                    <Route path="/video" component={Video} />
                    <Route path="/record" component={Record} />
                    {/*<Route path='/oldHome' component={OldHome}/>*/}
                    <Route path='/screen' component={Screen}/>
                    <Route path='/scroll' component={Scroll}/>
                    <Route path='/selectProvince' component={SelectProvince}/>
                    <Route path='/' component={Admin}/>
                    <Redirect to="/selectProvince"/>
                </Switch>
            </HashRouter>
        )
    }
}
