/**
 *type:title的类型 'return'带返回的 /默认不带返回的
 * title:名字
 * returnFun：点击事件
 */
import React, {Component} from 'react'
import {ArrowLeftOutlined} from '@ant-design/icons';
export default class  extends Component {
    render() {
        const {returnFun,title}=this.props
        return (
            <div>
                {this.props.type==='return'?
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ArrowLeftOutlined style={{marginRight: 20, cursor: 'pointer'}}
                                           onClick={returnFun}/>
                        <span style={{fontSize: 20, letterSpacing: 5, fontWeight: 600}}>{title}</span>
                    </div>
                    :
                    <span style={{fontSize: 20, letterSpacing: 5, fontWeight: 600}}>{title}</span>
                }
            </div>
        )
    }
}