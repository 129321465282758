/**
 *
 */
import React, {Component} from 'react'
import {Select,Card,Button,Form,Row,Col,Input} from 'antd'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import guanbi from '../../assets/Img/叉叉.png'
import {PAGE_SIZE} from '../../utils/constants'
import {reqGetNoteList,getAlarmMessageDetail} from '../../api/clApi'
const {Option}  = Select
var timer=""
export default class  extends Component {
    state={
        pageState:'alarmList',
        imgList:[],
        detailList:[],
        loading:true
    }
    searchForm=React.createRef()

    componentWillMount(){
        this.initColumn()
    }
    componentWillUnmount(){
        clearInterval(timer)
    }

    componentDidMount(){
        this.getList(1)
    }

    render() {
        const {pageState,show,imgList,imgNum,visible,alarmData,alarmDetail,loading} = this.state
        const  contentList={
            card_title:<PageTitle title="短信记录"  />,
            card_extra:<div style={{display:'flex'}}>
                <Form ref={this.searchForm}>
                    <Row gutter={15}>
                        {/*<Col>*/}
                        {/*    <Form.Item style={{marginBottom: 10}} name="deviceId">*/}
                        {/*        <Input style={{width: 150}} placeholder="设备编号"/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="receivedPhone">
                                <Input style={{width: 150}} type={"number"} placeholder="接收号码"/>
                            </Form.Item>
                        </Col>

                        <Col >
                            <Button type="primary" onClick={()=>{this.getList(1)}}>搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            alarmList:<MyTable column={this.dialColumn} data={alarmData} loading={loading} pageFun={(c)=>{this.getList(c,PAGE_SIZE)}}/>
        }

        return (
            <div className="page_home">
                <Card className="card_main" extra={contentList['card_extra']} title={contentList['card_title']}>
                    {contentList[pageState]}
                </Card>
            </div>
        )
    }
    goBack=()=>{
        this.setState({
            visible:false
        })
    }


    //获取短信记录信息
    getList=async(page)=>{
        this.setState({loading:true})
        let value = this.searchForm.current.getFieldsValue();
        value.page = page
        value.size = PAGE_SIZE
        let res =await reqGetNoteList(value?value:{})
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            console.log(res)
            res.pageNum=page
            this.setState({alarmData:res, pageNum:page})
        }


    }
    //获取详情
    getDetail=async(e)=>{
        let res =await getAlarmMessageDetail(e)
        if(res.code==200){
            console.log(res)
            this.setState({
                alarmDetail:res,
                loading:false
            })
        }

    }

    lookDetail=(e)=>{
        console.log(e.id)
        this.setState({
            visible:true,
            detailId:e.id,
            loading:true
        },()=>{
            this.getDetail(e)
        })

    }

    close=()=>{
        clearInterval(timer)
        this.setState({
            show:false
        })
    }

    look=(e)=>{
        console.log(e)
        this.setState({
            show:true,
            imgList:e,
            imgNum:0
        },()=>{
            timer = setInterval(()=>{
                console.log(this.state.imgNum)
                this.setState({
                    imgNum:this.state.imgNum==this.state.imgList.length-1?this.state.imgList.length-1:this.state.imgNum+1
                })
            },1000)
        })
    }
    /**
     * 初始化数据
     */
    initColumn = ()=>{

        this.dialColumn=[
            {
                title: '接收号码',
                dataIndex: 'receivedPhone',
                key: 'receivedPhone',
            },
            {
                title: '短信内容',
                dataIndex: 'messageContent',
                key: 'messageContent',
            },
            {
                title: '发送时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },


        ]
    }
}