
import React, { Component } from 'react';
import {HttpClient, HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete,FetchApiGet} from '../../../api/AxiosUtils';
import {Timeline,Layout,Image,message,Tree, Input,Button,notification,Spin,Alert} from 'antd'
import './scroll.css'
import {URL,provinceTitle,zoneCodeConfig} from '../../../config/urlConf/urlConf'

const { Header, Content, Footer, Sider } = Layout;
var timer=""
var timerFace=""
var scrollFacetimer=""
var scrollListimer=""
class Scorll2 extends Component {
    constructor(props) {
        super(props);
        this.state={
            List:[],
            faceList:[],
            num:0,
            show:false,
        }
    }

    componentDidMount(){
        HttpClientget(URL+`/api/safe/device/statistic/alarm/live/picture.json?zoneCode=`+zoneCodeConfig,{},{}).then((e)=>{

            if(e.code==200){
            if(JSON.stringify(e.results)!=JSON.stringify(this.state.List)){
                console.log("List",e)
                this.setState({
                    List:e.results,
                    show:true,
                    resetScrollList:true
                },()=>{
                    this.scrollList()
            })
            }else{

            }
        }

    })

        HttpClientget(URL+`/api/safe/device/statistic/alarm/face.json?zoneCode=`+zoneCodeConfig,{},{}).then((e)=>{

            if(e.code==200){
            if(JSON.stringify(e.results)!=JSON.stringify(this.state.faceList)){
                console.log("face",e)
                this.setState({
                    faceList:e.results,
                    show:true,
                    resetScrollFace:true
                },()=>{
                    var audio = document.getElementById("audio")
                    audio.play()
                this.scrollFace()
            })
            }else{

            }
        }


    })

        this.getList()
        this.getfaceList()
    }





    componentDidUpdate(){


    }
    componentWillUnmount(){
        clearInterval(timer)
        clearInterval(timerFace)
        clearInterval(scrollFacetimer)
        clearInterval(scrollListimer)
    }

    scrollFace=(time)=>{
    let faceBox = document.getElementById("faceBox")
    console.log(faceBox.scrollWidth)
    scrollFacetimer = setInterval(()=>{
        faceBox.scrollLeft+=2
    // console.log(faceBox.scrollWidth-faceBox.scrollLeft,faceBox.clientWidth)
    if((faceBox.scrollWidth-faceBox.scrollLeft)<(faceBox.clientWidth+20)||this.state.resetScrollFace){
    faceBox.scrollLeft=0
    this.setState({
                      resetScrollFace:false
                  })
}
},50)
}
scrollList=(time)=>{
    let listBox = document.getElementById("listBox")
    // console.log(listBox.scrollWidth)
    scrollListimer = setInterval(()=>{
        listBox.scrollLeft+=2
    // console.log(listBox.scrollLeft)
    if((listBox.scrollWidth-listBox.scrollLeft)<(listBox.clientWidth+20)||this.state.resetScrollList){
        listBox.scrollLeft=0
        this.setState({
            resetScrollList:false
        })
    }
},20)
}

getList=()=>{
    timer=setInterval(()=>{
            const {num}=this.state
            HttpClientget(URL+`/api/safe/device/statistic/alarm/live/picture.json?zoneCode=`+zoneCodeConfig,{},{}).then((e)=>{

            if(e.code==200){
            if(JSON.stringify(e.results)!=JSON.stringify(this.state.List)){
                console.log("List",e)
                clearInterval(scrollListimer)
                this.setState({
                    List:e.results,
                    show:true,
                    resetScrollList:true
                },()=>{
                    this.scrollList()
            })
            }else{

            }
        }

})
},30000)

}
getfaceList=()=>{
    timerFace=setInterval(()=>{
            const {num}=this.state
            HttpClientget(URL+`/api/safe/device/statistic/alarm/face.json?zoneCode=`+zoneCodeConfig,{},{}).then((e)=>{

        if(e.code==200){
        if(JSON.stringify(e.results)!=JSON.stringify(this.state.faceList)){
            clearInterval(scrollFacetimer)
            console.log("face",e)
            this.setState({
                faceList:e.results,
                show:true,
                resetScrollFace:true
            },()=>{
                var audio = document.getElementById("audio")
                audio.play()
            this.scrollFace()
        })
        }else{

        }
    }


})
},30000)

}

render() {
    const {List,show} =this.state
    return (
        <div style={{width:'100%',height:'100%',background:'#34384D'}}>
            <Header className="screen_header" style={{padding:0}}>
            <img className="header_left" src={require('../../../assets/screen/titlePicLeft.png')}/>
            <h1 className="header_center" style={{height:"20%"}}>{provinceTitle+"防溺水预警数据中心"}</h1>
                <img className="header_right" src={require("../../../assets/screen/titlePicRight.png")}/>
            </Header>
                <div style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",color:"white"}}>
            <h1 style={{color:"white",marginBottom:"25px"}}>现场检测</h1>
                <div style={{width:"100%",height:"450px"}}>
                {
                    show?
                <div id="listBox" style={{width:"100%",overflowX:"scroll",display:"flex"}}>

                    {this.state.List.map((items,imgs_index)=>{
                        return (
                            items.imageUrl.length>0?
                    <div  key={imgs_index+"img1"} style={{marginLeft:"20px"}}>
                    <Image src={items.imageUrl}  alt='' width={500} height={400}></Image>
                        </div>:""
                    )
                    })}

                </div>: <Spin tip="Loading...">
                    <Alert
                    message="加载中"
                    description="正在获取最新数据"
                    type="info"
                        />
                        </Spin>
                }

            </div>

                <h1 style={{color:"white",marginTop:"40px"}}>闯入人员检测</h1>
                <div style={{width:"100%",height:"500px"}}>
                {
                    show?
                <div id="faceBox" style={{width:"100%",overflowX:"scroll",display:"flex"}}>

                    {this.state.faceList.map((items,imgs_index)=>{
                        return (
                            items.faceUrl.length>0?
                    <div key={imgs_index+"img1"} style={{marginLeft:"20px"}}>
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <p>{items.happenedAtStr}</p>
                    <p>
                    姓名：{items.matchUserName!=""?items.matchUserName.replace(items.matchUserName.substring(1,2),"*"):"无"}
                    </p>
                    <p>组织：{items.organizationFullName!=""?items.organizationFullName:"无"}</p>
                    <p style={{fontSize:"14px",maxWidth:"200px"}}>{items.location}</p>
                    <img src={items.faceUrl}  alt='' style={{width:"200px",height:"200px",display:"block"}}></img>
                    </div>
                    </div>:""
                    )
                    })}

                </div>
                :<Spin tip="Loading...">
                    <Alert
                    message="加载中"
                    description="正在获取最新数据"
                    type="info"
                        />
                        </Spin>
                }
            </div>
                </div>
                <audio src={require("./waring.mp3")} id="audio" style={{opacity:"0"}}></audio>
                </div>

)
}

}

export default Scorll2;
