/**
 * 常用工具类
 */


export const removePropertyOfNull=(obj)=> {
    Object.keys(obj).forEach(item=>{
        if(!obj[item])  delete obj[item]
    })
    return obj;
}

export const formatLocalDateTime=(localDateTime)=>{
    let timeStr = localDateTime.substring(0,localDateTime.lastIndexOf('.')===-1?localDateTime.length:localDateTime.lastIndexOf('.'));
    return timeStr.replaceAll("T", " ");
}

export const compareObjDiff=(oldOne, newOne)=>{
    let result = {};
    if(oldOne && newOne){
        Object.keys(newOne).forEach(field => {
            if(oldOne[field]!==newOne[field]){
                result[field] = newOne[field];
            }
        })
    }
    return result;
}

export const getPageParam=(num, size)=>{
    let param = {pageNum:1, pageSize:10};
    if(num){
        param.pageNum = num;
    }
    if(size){
        param.pageSize = size;
    }
    return param;
}

export const list2tressNode=(list)=>{
    let mList = list
    mList.forEach(item =>{
        item.key = item.id;
        item.title = item.name;
        item.value = item.id;
        if(item.children){
            item.children = list2tressNode(item.children)
        }
    })
    return mList
}

export const cutString=(string, length, replaceStr)=>{
    if(string && length && replaceStr){
        return string.length>length?string.substring(0, length)+replaceStr:string;
    }
    return string;
}
/**
 * 遍历树状列表，查找
 * @param treeList  树状数据列表
 * @param id        查找数据
 * @param field     查找字段
 * @param childField    子数据字段
 */
export const treeFind=(treeList, id, field, childField)=>{
    if(treeList && treeList.length>0){
        for(let i=0,size=treeList.length;i<size;i++){
            let tFound = find(treeList[i], id, field, childField);
            if(tFound){
                return  tFound;
            }
        }
    }
    return null;
}

function find(node, id, field, childField){
    if(node[field]===id){
        return node;
    }else{
        if(node[childField] && node[childField].length>0){
            for(let i=0,size=node[childField].length;i<size;i++){
                let tFount = find(node[childField][i], id, field, childField);
                if(tFount){
                    return tFount;
                }
            }
            return null;
        }else{
            return null;
        }
    }
}

/**
 * 在树状列表中，查找对象属性的父节点
 * @param treeList  树状列表
 * @param cid       查找参数
 * @param field     查找字段
 * @param childField    子元素字段
 */
export const treeFindFather=(treeList, cid, field, childField)=>{
    //同步循环
    if(treeList && treeList.length>0){
        for(let i=0,size=treeList.length;i<size;i++){
            let tFound = findFather(treeList[i], cid, field, childField);
            if(tFound){
                return tFound;
            }
        }
    }
    return null;
}

function findFather(node, cid, field, childField){
    if(node[childField] && node[childField].length>0){
        for(let i=0,size=node[childField].length;i<size;i++){
            if(cid===node[childField][i][field]){
                return node;
            }
            let tFound = findFather(node[childField][i], cid, field, childField);
            if(tFound){
                return tFound;
            }
        }
    }else{
        return null;
    }
}

export const parseXhrResponse=(xhr)=>{
    if(xhr && xhr.responseText){
        return JSON.parse(xhr.responseText);
    }
    return null;
}

export const dto2treeData=(treeData)=>{
    let nodes = [];
    if(treeData){
        treeData.map(e => {
            let node = {
                "key":e.id,
                "title":e.name,
                "label":e.name,
                "value":e.id
            };
            if(e.children){
                node.children = dto2treeData(e.children);
            }
            nodes.push(node);
        })
    }
    return nodes;
}

export const removeH5Tag=(richText)=>{
    if(richText){
        let regex = /(<([^>]+)>)/ig
        return richText.replace(regex, "")
    }else{
        return "";
    }
}













