import React, {Component, useState} from 'react';
import {OSS_SHCOOLICON_RULE} from '../../utils/constants'
import './header.less';
import zhuxiao from '../../assets/Img/关机.png';
import {Image, Popover, Drawer, Button, Modal, Input, Radio, message, Form, Select, Tabs} from 'antd';
import 'moment/locale/zh-cn';
import moment from 'moment';
import Recorder from 'recorder-core';
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
import 'recorder-core/src/engine/pcm';
import 'recorder-core/src/engine/wav';
import 'recorder-core/src/extensions/waveview';
import {Link} from 'react-router-dom';
import sessionUtils from '../../utils/sessionUtils';
import {reqGetText2Audio, reqGetText2audioStatus, reqGetDownloadText2audio} from '../../api/index';
import {URL_SAFONE} from '../../config/urlConf/urlConf';

/**图标资源*/
import UserIcon from '../../assets/icons/u4.png'
import KanbanIcon from '../../assets/icons/kanban.svg'
import ScrollIcon from '../../assets/icons/ryjk.svg'
import Memory from '../../utils/memeryUtils'
import {PNG_HomeLogo, PNG_Clock} from '../../assets'
import {ToolOutlined} from '@ant-design/icons';

/**引入组件*/


class HeaderBar extends Component {
    // moduleId: 0
    // platUsingNumber: 38
    // schoolLogo: "http://safe-system.oss-cn-chengdu.aliyuncs.com/safe-school/09293fa3-01d2-4bf2-91bf-ce9cf29b9436.png"
    // schoolName: "经贸外事学校"
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            textArea: "",
            type: 0,
            userName: "",
            confirmLoading: false,
            userInfo: {
                // moduleId: 0,
                // platUsingNumber: 38,
                // schoolLogo: "http://safe-system.oss-cn-chengdu.aliyuncs.com/safe-school/09293fa3-01d2-4bf2-91bf-ce9cf29b9436.png",
                // schoolName: "经贸外事学校" ,
            },
            time: moment().format('YYYY.MM.DD HH:mm:ss'),
            titleType: Memory.title,
            recorderInfo: {
                type: "mp3",
                bitRate: 16,
                sampleRate: 16000
            },
            duration: 0
        }
    }

    setFormRef = React.createRef();

    componentWillUnmount() {
        clearInterval(this.startSetInterval);
        clearInterval(this.timer)
    }

    componentDidMount() {
        this.setUserName()
        this.goTimeInterval()
    }

    showDrawer = () => {
        this.setState({
            visible: true
        })
    };

    onClose = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        const {userName, titleType, userInfo, time, isShowScroll, isShowKanban, duration} = this.state;
        //className={titleType==='dark'?'header_dark':titleType==='light'?'header_light':null}

        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 6},
        };

        const {Option} = Select;
        const {TabPane} = Tabs;
        const {TextArea} = Input;

        return <header className={"header_dark"}>
            <div className="header_logo">
                <Link to="/" className="header_logo_icon" style={{marginLeft: 20}}>
                    <Image preview={false} width="100%" height="100%" src={PNG_HomeLogo}/>
                </Link>
            </div>
            <div className="header_menu">
                <div style={{
                    color: "white",
                    marginRight: "10px",
                    fontSize: "22px",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div style={{
                        display: isShowScroll ? 'flex' : "none",
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginRight: 20,
                        flexDirection: "column"
                    }} onClick={() => {
                        window.open('./#/scroll')
                    }}>
                        <Image preview={false} width="40px" height="40px" src={ScrollIcon} alt=""/>
                        <div style={{fontSize: "14px"}}>人员监控</div>
                    </div>
                    <div style={{
                        // display: isShowKanban ? 'flex' : "none",
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginRight: 20,
                        flexDirection: "column"
                    }} onClick={() => {
                        window.open('./#/screen')
                    }}>
                        <Image preview={false} width="40px" height="40px" src={KanbanIcon} alt=""/>
                        <div style={{fontSize: "14px"}}>指挥中心</div>
                    </div>
                    <div style={{background: "#7dc869", padding: "4px 20px", fontSize: "15px", marginRight: "30px"}}>
                        您好，欢迎进入防溺水后台管理系统
                    </div>
                    <div>
                        <img src={PNG_Clock} alt="" style={{width: "25px", height: "25px"}}/>
                    </div>
                    <div style={{borderRight: "1px solid white", fontSize: "15px", padding: "10px"}}>{time}</div>
                    <div style={{
                        borderRight: "1px solid white",
                        fontSize: "15px",
                        padding: "10px"
                    }}>用户名：{this.state.userName}</div>
                    <div style={{
                        fontSize: "15px",
                        padding: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        注销
                        <img src={zhuxiao} onClick={this.goLogin}
                             style={{width: "30px", height: "30px", marginLeft: "10px"}} alt="注销"/>
                    </div>

                </div>
                <Button type='primary' onClick={this.showDrawer} icon={<ToolOutlined/>} style={{
                    position: "absolute",
                    right: "1px",
                    top: "50%",
                    zIndex: 10,
                    backgroundColor: "#1A2F50",
                    borderColor: "white",
                    borderRadius: "5px"
                }}/>
                <Drawer title={"工具箱"} placement={"right"} onClose={this.onClose} visible={this.state.visible}
                        width={'25%'} headerStyle={{textAlign: "center"}} destroyOnClose>
                    <Tabs defaultActiveKey={1} destroyInactiveTabPane>
                        <TabPane tab={"文字转语音"} key={1}>
                            <div>
                                <TextArea type={"text"} placeholder={"请输入需要转语音的文字"} allowClear
                                          onChange={this.setInputValue} maxLength={1000} style={{height: 120}}
                                />
                                <Radio.Group placeholder="请选择播放类型" onChange={(e) => this.radioChange(e)} style={{
                                    margin: "20px auto",
                                    width: "90%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around"
                                }}>
                                    <Radio.Button value={3}>mp3</Radio.Button>
                                    <Radio.Button value={4}>pcm16</Radio.Button>
                                    <Radio.Button value={5}>pcm8</Radio.Button>
                                    <Radio.Button value={6}>wav</Radio.Button>
                                </Radio.Group>
                                <div style={{
                                    top: "0",
                                    left: "0",
                                    bottom: "0",
                                    right: "0",
                                    margin: "10px auto",
                                    textAlign: "center"
                                }}>
                                    <Button type={"primary"} onClick={this.confirmVoiceToText}
                                            loading={this.state.confirmLoading}>开始转换</Button>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab={"录音"} key={2}>
                            <div>
                                <Form ref={this.setFormRef} onValuesChange={this.recordSet}
                                      initialValues={this.state.recorderInfo}>
                                    <Form.Item label={"录音格式"} name={'type'} {...formItemLayout}>
                                        <Select>
                                            <Option value={'mp3'}>mp3</Option>
                                            <Option value={'wav'}>wav</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={"比特率"} name={'bitRate'} {...formItemLayout}>
                                        <Input type="number"/>
                                    </Form.Item>
                                    <Form.Item label={"采样率"} name={'sampleRate'} {...formItemLayout}>
                                        <Input type="number"/>
                                    </Form.Item>
                                    <Form.Item label={"录音时长:"}  {...formItemLayout}>
                                        <p style={{textAlign: "center"}}>{duration} s</p>
                                    </Form.Item>
                                    <div style={{
                                        height: "40px",
                                        width: "80%",
                                        borderRadius: '10px',
                                        border: "1px solid #1890FF",
                                        margin: "auto"
                                    }} className="waveView">

                                    </div>
                                    <div style={{
                                        top: "0",
                                        left: "0",
                                        bottom: "0",
                                        right: "0",
                                        margin: "10px auto",
                                        textAlign: "center"
                                    }}>
                                        <Button type={"primary"} style={{margin: "2px 10px"}}
                                                onClick={this.recStart}>开始</Button>
                                        <Button type={"primary"} style={{margin: "2px 10px"}}
                                                onClick={this.recStop}>结束</Button>
                                    </div>
                                </Form>

                            </div>
                        </TabPane>
                    </Tabs>
                </Drawer>
            </div>
        </header>
    }

    recordSet = (changedValues, allValues) => {
        this.setState({
            recorderInfo: {...allValues}
        })
    }

    recStart = () => {
        const {recorderInfo} = this.state;
        let _this = this;
        let wave;
        let rec = Recorder({
            type: recorderInfo.type,
            bitRate: recorderInfo.bitRate,
            sampleRate: recorderInfo.sampleRate,
            onProcess: function (buffers, powerLevel, duration, sampleRate) {
                wave.input(buffers[buffers.length - 1], powerLevel, sampleRate);
                const seconds = duration / 1000;
                _this.setState({
                    duration: seconds,
                })
            }
        })
        this.setState({
            rec: rec
        }, rec.open(function () {
            wave = Recorder.WaveView({elem: ".waveView"});
            rec.start();
        }, function (msg, isUserNotAllow) {
            message.error('未打开麦克风');
        }))
    }


    recStop = () => {
        const {rec} = this.state;
        rec.stop((blob, duration) => {
            // console.log(blob, (window.URL || window.webkitURL).createObjectURL(blob), "时长:" + duration + "ms");
            // window.open((window.URL || window.webkitURL).createObjectURL(blob));
            rec.close();
            let blobUrl = (window.URL || window.webkitURL).createObjectURL(blob);
            let blobType = blob.type.slice(blob.type.lastIndexOf("/") + 1)
            let link = document.createElement('a')
            document.body.appendChild(link);
            link.style.display = null;
            link.href = blobUrl;
            link.download = '录音文件.' + blobType;
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            // const audio = document.createElement("audio");
            // audio.controls = true;
            // document.body.appendChild(audio);
            // audio.src = (window.URL || window.webkitURL).createObjectURL(blob);
            // audio.play();
        }, function (msg) {
            message.error("录音失败:" + msg);
            rec.close();
        })

    }


    setInputValue = (e) => {
        if (e && e.target && e.target.value) {
            let value = e.target.value
            this.setState({
                textArea: value
            })
        }
    }

    radioChange = (e) => {
        if (e && e.target && e.target.value) {
            let value = e.target.value
            this.setState({
                type: value
            })
        }
    }

    goTimeInterval = () => {
        this.startSetInterval = setInterval(() => {
            this.setState({
                time: moment().format('YYYY.MM.DD HH:mm:ss'),
            })
        }, 1000)
    }

    goLogin = () => {
        sessionUtils.deleteUser();
        window.location.href = '/'
    }

    setUserName = () => {
        this.setState({
            userName: sessionUtils.getUser().username
        }, () => {
            // console.log(this.state.userName.substring(0, 5))
            if (this.state.userName.substring(0, 6) === "admin-") {
                this.setState({
                    isShowScroll: true
                })
            }
            if (this.state.userName === "admin" || this.state.userName.substring(0, 5) === "zhzx-") {
                this.setState({
                    isShowScroll: true,
                    isShowKanban: true
                })
            }
        })
    }

    confirmVoiceToText = async () => {
        const {textArea, type} = this.state;
        if (!textArea) {
            message.error("请输入文字")
        } else if (!type) {
            message.error("请选择类型")
        } else {
            let res = await reqGetText2Audio({text: textArea, type: type});
            this.setState({
                confirmLoading: true
            }, () => {
                this.timer = setInterval(async () => {
                    let res1 = await reqGetText2audioStatus({key: res.result});
                    let status = res1.result;
                    if (status === 'pending') {
                        this.setState({
                            confirmLoading: true
                        })
                    } else if (status === 'error') {
                        clearInterval(this.timer);
                        message.error('转换失败');
                        this.setState({
                            confirmLoading: false
                        })
                    } else if (status === 'success') {
                        clearInterval(this.timer);
                        await reqGetDownloadText2audio({key: res.result});
                        window.open(URL_SAFONE + '/api-platform/common/downloadText2audio?key=' + res.result);
                        this.setState({
                            confirmLoading: false
                        }, () => {
                            console.log("confirmLoading:" + this.state.confirmLoading)
                        })
                    }
                }, 3000)
            })
        }

    }

}

export {HeaderBar};