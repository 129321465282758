import React, {Component} from 'react'
import {
    Button,
    message,
    Layout,
    Tree,
    Tabs,
} from 'antd'
import {SUCCESS} from "../../utils/constants";
import "./monitoring.less"
import {HttpClientget} from "../../api/AxiosUtils";
import {URL, zoneCodeConfig} from "../../config/urlConf/urlConf";
import {
    BgColorsOutlined,
    BorderOutlined,
    AppstoreOutlined,
    TableOutlined,
    CloseOutlined,
    VideoCameraAddOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import {reqGetSafeDeviceByDeviceId, reqOpenStreamByDeviceId} from "../../api";

const {Content, Sider} = Layout;
const {TabPane} = Tabs;
export default class Monitoring extends Component {
    constructor(props) {
        super(props);
        this.jessibuca = null;
        this.showOperateBtns = true;
        this.$container = null;
        this.forceNoOffscreen = false;
        this.state = {
            selectedKeys:[],
            playList: [],
            playUrl: '',
            isPlaying: false,
            scriptStatus: 'no',
            treeData: [],
            allDevices: [],
            tabCount: "1",
            showVideo: false,
            videoDataList: [],
            videoDataList1: [],
            videoList: [
                {
                    id: "1",
                    url: "xxx",
                }
            ],
        }
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        const tabCount = this.state.tabCount;
        this.toCreate(tabCount);
        this.getList();
    }

    create = (id) => {
        const {playList} = this.state;
        const $container = document.getElementById('container' + id);
        // this.jessibuca = new window.Jessibuca({
        //     container: $container,
        //     videoBuffer: 0, // 缓存时长
        //     isResize: false,
        //     text: "",
        //     loadingText: "加载中",
        //     debug: false,
        //     showBandwidth: this.showOperateBtns, // 显示网速
        //     operateBtns: {
        //         fullscreen: this.showOperateBtns,
        //         screenshot: this.showOperateBtns,
        //         play: this.showOperateBtns,
        //         audio: this.showOperateBtns,
        //     },
        //     forceNoOffscreen: this.forceNoOffscreen,
        //     isNotMute: false,
        //     useMSE: true,
        // });
        playList.push(this.jessibuca);
        this.setState({
            playList
        })
    }

    selectDevice = async (e, e2) => {
        const level = e2.node.level;
        const {selectedKeys} = this.state;
        selectedKeys.push(e2)
        if (level === 3) {
            this.setState({
                selectedKeys
            },()=>this.showVideoLook(e2.node, this.state.tabCount))
        }
    }

    showVideoLook = async (device, tabCount) => {
        const {playList} = this.state;
        const result = await reqGetSafeDeviceByDeviceId({deviceId: device.deviceId});
        const count = parseInt(tabCount)
        if (result.code === SUCCESS) {
            if (result.result) {
                const res = await reqOpenStreamByDeviceId({deviceId: result.result.cameraDto.deviceId});
                if (res.code === SUCCESS) {
                    if (count === 1) {
                        playList[0].play(res.result);
                    } else if (count === 4 || count === 9) {
                        for (let i = 0; i < playList.length; i++) {
                            if (!playList[i]?.playing) {
                                await playList[i].play(res.result);
                                i = 0;
                                return
                            }
                        }
                    }
                } else {
                    message.error("点播失败: " + result.message)
                }
            } else {
                message.error("设备尚未绑定摄像头");
            }
        }
    };


    onLoadData = treeNode => {
        const {treeData} = this.state;
        return new Promise((resolve) => {
            if (treeNode.children.length > 0) {
                resolve();
                return;
            }
            setTimeout(() => {
                if (treeNode.level === 2 && treeNode.children.length === 0) {
                    const zoneCode = treeNode.key
                    HttpClientget(URL + `/api/safe/device/statisticDevices?zoneCode=${zoneCode}`, {}, {}).then((e) => {
                        if (e.code === 200) {
                            const alarmStatistic = e.result.alarmStatistic
                            const humExceptionStatistic = e.result.humExceptionStatistic
                            const normalStatistic = e.result.normalStatistic
                            const offlineStatistic = e.result.offlineStatistic
                            const oneKeyAlarmStatistic = e.result.oneKeyAlarmStatistic
                            const tempExceptionStatistic = e.result.tempExceptionStatistic
                            const all = [...alarmStatistic, ...humExceptionStatistic, ...normalStatistic, ...offlineStatistic, ...oneKeyAlarmStatistic, ...tempExceptionStatistic];
                            all.forEach(item => {
                                item.key = item.deviceId;
                                item.title = item.deviceId;
                                item.level = 3;
                                item.isLeaf = true;
                                item.icon = item.deviceStatus === "normal" ? <VideoCameraAddOutlined/> :
                                    <VideoCameraOutlined/>
                            })
                            for (let i = 0; i < treeData.length; i++) {
                                const chi = treeData[i].children
                                chi.forEach(item => {
                                    if (item.key === zoneCode) {
                                        item.children = [...all]
                                        item.nodeChildList = [...all]
                                    }
                                })
                                this.setState({
                                    treeData: [...this.state.treeData]
                                })
                            }
                        }

                    })
                }
                resolve();
            }, 200);
        });

    }

    render() {
        const {treeData,selectedKeys} = this.state;
        return (<div style={{width: '100%', height: '100%', background: '#34384D', overflow: 'hidden'}}>
            <Layout className="screen_main">
                <Layout style={{background: 'rgba(0,0,0,0)'}}>
                    <Sider width="16%" className="screen_body_left">
                        <div style={{
                            display: "flex",
                            height: "100%",
                            overflowY: "scroll",
                            background: "white"
                        }}>
                            <div style={{width: "100%"}}>
                                <Tree
                                    showIcon
                                    loadData={this.onLoadData}
                                    treeData={treeData}
                                    onSelect={this.selectDevice}
                                    selectedKeys={selectedKeys}
                                    style={{
                                        background: "rgba(0,0,0,0)",
                                        color: "black",
                                        fontSize: "12px",
                                        fontWeight: 600
                                    }}
                                />
                            </div>
                        </div>
                    </Sider>
                    <Content style={{backgroundColor: "white", padding: "20px"}}>
                        <Tabs onTabClick={(key) => this.tabChange(key)} defaultActiveKey="1" destroyInactiveTabPane
                              className={"tabBar"}>
                            <TabPane key="1" style={{height: "100%"}}
                                     tab={<BorderOutlined style={{marginLeft: "12px"}}/>}>
                                <div className="container-shell"
                                     style={{width: "100%", height: "100%", padding: 0}}>
                                    <div style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }}
                                             id={"container2"}>

                                        </div>
                                        <Button onClick={() => this.onClose(0, 2)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="4" tab={<AppstoreOutlined style={{marginLeft: "12px"}}/>}>
                                <div className="container-shell"
                                     style={{
                                         width: "100%",
                                         height: "100%",
                                         padding: 0,
                                         display: "flex",
                                         flexWrap: "wrap",
                                         alignItems: "center",
                                         justifyContent: "center"
                                     }}>
                                    <div className={"four"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }}
                                             id={"container3"}>
                                        </div>
                                        <Button onClick={() => this.onClose(0, 3)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"four"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }}
                                             id={"container4"}>
                                        </div>
                                        <Button onClick={() => this.onClose(1, 4)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"four"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }}
                                             id={"container5"}>
                                        </div>
                                        <Button onClick={() => this.onClose(2, 5)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"four"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }}
                                             id={"container6"}>
                                        </div>
                                        <Button onClick={() => this.onClose(3, 6)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="9" tab={<TableOutlined style={{marginLeft: "12px"}}/>}>
                                <div className="container-shell"
                                     style={{
                                         width: "100%", height: "100%", padding: 0, display: "flex",
                                         flexWrap: "wrap",
                                         alignItems: "center",
                                         justifyContent: "center"
                                     }}>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container7"}>
                                        </div>
                                        <Button onClick={() => this.onClose(0, 7)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container8"}>
                                        </div>
                                        <Button onClick={() => this.onClose(1, 8)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container9"}>
                                        </div>
                                        <Button onClick={() => this.onClose(2, 9)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container10"}>
                                        </div>
                                        <Button onClick={() => this.onClose(3, 10)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container11"}>
                                        </div>
                                        <Button onClick={() => this.onClose(4, 11)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container12"}>
                                        </div>
                                        <Button onClick={() => this.onClose(5, 12)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container13"}>
                                        </div>
                                        <Button onClick={() => this.onClose(6, 13)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container14"}>
                                        </div>
                                        <Button onClick={() => this.onClose(7, 14)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                    <div className={"nine"}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(13, 14, 27, 0.7)"
                                        }} id={"container15"}>
                                        </div>
                                        <Button onClick={() => this.onClose(8, 15)} ghost icon={<CloseOutlined/>}
                                                className={"close_btn"}/>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </Content>
                </Layout>
            </Layout>
        </div>)
    }

    callback
    //创建播放流实例
    toCreate = (tabCount) => {
        const count = parseInt(tabCount);
        let i = 0;
        if (count === 1) {
            for (i = 1; i < 2; i++) {
                this.create(i + 1);
            }
        } else if (count === 4) {
            for (i = 2; i < 6; i++) {
                this.create(i + 1);
            }
        } else if (count === 9) {
            for (i = 6; i < 15; i++) {
                this.create(i + 1);
            }
        }
    }
    //标签页改变时创建播放流
    tabChange = (key) => {
        const {playList,selectedKeys} = this.state;
        if (playList) {
            for (let i = 0;i<playList.length;i++){
                if (playList[i].playing){
                    playList[i].destroy()
                }
            }
            for (let j=0;j<selectedKeys.length;j++){
                if (selectedKeys[j]){
                    selectedKeys[j].selected=false
                }
            }
            this.setState({
                playList: [],
                selectedKeys:[]
            })
        }
        this.setState({tabCount: key}, () => {
            this.toCreate(key)
        })
    }

    onClose = (key, id) => {
        const {playList} = this.state;
        const {playing} = playList[key]
        if (playing) {
            playList[key].destroy();
            delete playList[key]
            const $container = document.getElementById('container' + id);
            // playList[key] = new window.Jessibuca({
            //     container: $container,
            //     videoBuffer: 0, // 缓存时长
            //     isResize: false,
            //     text: "",
            //     loadingText: "加载中",
            //     debug: false,
            //     showBandwidth: this.showOperateBtns, // 显示网速
            //     operateBtns: {
            //         fullscreen: this.showOperateBtns,
            //         screenshot: this.showOperateBtns,
            //         play: this.showOperateBtns,
            //         audio: this.showOperateBtns,
            //     },
            //     forceNoOffscreen: this.forceNoOffscreen,
            //     isNotMute: false,
            //     useMSE: true,
            // });
        } else {
            message.info("未开启直播")
        }
    }

    //获取水库树1234564415646
    getList = () => {
        HttpClientget(URL + "/api/safe/device/area/centerAreaTree?zoneCode=" + zoneCodeConfig, {}, {}).then((e) => {
            if (e.code === 200) {
                this.setState({
                    treeData: this.listInitKey(e.results)
                })
            }
        })

    }

    listInitKey = (list) => {
        let mlist = list
        mlist.forEach(item => {
            item.key = item.id;
            item.title = item.name + '(' + item.deviceTotalCount + ')';
            item.value = item.id;
            item.icon = item.nodeType === 1 ? <BgColorsOutlined/> : ""
            if (item.nodeChildList) {
                item.children = this.listInitKey(item.nodeChildList)
            }
        })
        return mlist
    }
}
