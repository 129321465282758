/**
 *
 */
import React, {Component} from 'react'
import {Select,Card,Button,Form,Row,Col,Input} from 'antd'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import guanbi from '../../assets/Img/叉叉.png'
import {PAGE_SIZE} from '../../utils/constants'
import {reqGetDeviceLogList,reqGetDeviceLogDetail} from '../../api/clApi'
const {Option}  = Select
var timer=""
export default class  extends Component {
    state={
        pageState:'alarmList',
        imgList:[],
        detailList:[],
        deviceStatus:[
            {
                label:'正常',
                value:'normal'
            },
            {
                label:'离线',
                value:'offline'
            },
            {
                label:'温度异常',
                value:'temp-exception'
            },
            {
                label:'湿度异常',
                value:'hum-exception'
            },
            {
                label:'温湿度异常',
                value:'temp-hum-exception'
            },
            {
                label:'一键报警中',
                value:'one-key-alarming'
            },
            {
                label:'报警中',
                value:'alarming'
            },
        ],
        loading:false,
        showDeviceList:true,
    }
    searchForm=React.createRef()

    componentWillMount(){
        this.initColumn()
    }
    componentWillUnmount(){
        clearInterval(timer)
    }

    componentDidMount(){
        this.getList(1)
    }

    render() {
        const {pageState,show,imgList,imgNum,visible,alarmData,alarmDetail,loading,showDeviceList} = this.state
        const  contentList={
            card_title:<PageTitle title="设备异常日志"  />,
            card_extra:<div style={{display:'flex'}}>
                <Form ref={this.searchForm}>
                    <Row gutter={15}>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="deviceId">
                                <Input style={{width: 150}} placeholder="设备编号"/>
                            </Form.Item>
                        </Col>
                        <Col >
                            <Button type="primary" onClick={()=>{this.getList(1)}}>搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            alarmList:  <div>
                            <div style={{marginBottom:"10px"}}>
                                <Button type={"primary"} style={{marginRight:"10px"}} onClick={(e)=>{
                                    this.setState({showDeviceList:true})
                                }}>设备列表</Button>
                                <Button type={"danger"} onClick={()=>{
                                    this.setState({showDeviceList:false})
                                }}>日志详情</Button>
                            </div>
                            {
                                showDeviceList?
                                    <MyTable column={this.dialColumn} data={alarmData} loading={loading} pageFun={(c)=>{this.getList(c,PAGE_SIZE)}}/>
                                    :
                                    <MyTable column={this.detailColumn} data={alarmDetail} loading={loading} pageFun={(c)=>{this.getDetail(c,PAGE_SIZE)}}/>
                            }
                        </div>
        }

        return (
            <div className="page_home">
                <Card className="card_main" extra={contentList['card_extra']} title={contentList['card_title']}>
                    {contentList[pageState]}
                </Card>
            </div>
        )
    }
    goBack=()=>{
        this.setState({
            visible:false
        })
    }


    //获取信息
    getList=async(page)=>{
        this.setState({loading:true})
        let value = this.searchForm.current.getFieldsValue();
        let res =await reqGetDeviceLogList(page,PAGE_SIZE,value?value:{})
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            console.log(res)
            res.pageNum=page
            this.setState({alarmData:res, pageNum:page})
        }
    }
    //获取详情
    getDetail=async(page,size,value)=>{
        const {detailId} = this.state
        let res =await reqGetDeviceLogDetail(page,size,{deviceId:value?value:detailId})
        if(res.code==200){
            console.log(res)
            this.setState({
                alarmDetail:res,
                loading:false
            })
        }

    }

    lookDetail=(e)=>{
        console.log(e.id)
        this.setState({
            visible:true,
            detailId:e.id,
            loading:true,
            showDeviceList:false
        },()=>{
            this.getDetail(1,10,e.id)
        })

    }

    close=()=>{
        clearInterval(timer)
        this.setState({
            show:false
        })
    }

    look=(e)=>{
        console.log(e)
        this.setState({
            show:true,
            imgList:e,
            imgNum:0
        },()=>{
            timer = setInterval(()=>{
                console.log(this.state.imgNum)
                this.setState({
                    imgNum:this.state.imgNum==this.state.imgList.length-1?this.state.imgList.length-1:this.state.imgNum+1
                })
            },1000)
        })
    }
    /**
     * 初始化数据
     */
    initColumn = ()=>{
        const {deviceStatus}=this.state
        this.dialColumn=[
            {
                title: '设备号',
                dataIndex: 'deviceId',
                key: 'deviceId',
            },
            {
                title: '设备类型',
                dataIndex: 'deviceType',
                key: 'deviceType',
            },
            {
                title: '离线时间',
                dataIndex: 'offlineDuration',
                key: 'offlineDuration',
            },
            {
                title: '设备状态',
                dataIndex: 'deviceStatus',
                key: 'deviceStatus',
                render:(e)=>{
                    return(
                        <div>{this.state.deviceStatus.find(item=>item.value===e)?this.state.deviceStatus.find(item=>item.value===e).label:null}</div>

                    )
                }
            },
            {
                title: '检测时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'lookDetail',
                render:(e)=>{
                    return(
                        <Button type={"primary"} onClick={this.lookDetail.bind(this,e)}>查看详情</Button>

                    )
                }
            }


        ]
        this.detailColumn=[
            {
                title: '设备号',
                dataIndex: 'deviceId',
                key: 'deviceId',
            },
            {
                title: '设备类型',
                dataIndex: 'deviceType',
                key: 'deviceType',
            },
            {
                title: '离线时间',
                dataIndex: 'offlineDuration',
                key: 'offlineDuration',
            },
            {
                title: '设备状态',
                dataIndex: 'deviceStatus',
                key: 'deviceStatus',
                render:(e)=>{
                    return(
                        <div>{this.state.deviceStatus.find(item=>item.value===e)?this.state.deviceStatus.find(item=>item.value===e).label:null}</div>

                    )
                }
            },
            {
                title: '检测时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
        ]
    }
}