/**
 *
 */
import React, {Component} from 'react'
import {Button, message, Upload} from 'antd';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {URL} from '../../config/urlConf/urlConf'

export default class  extends Component {
    constructor(props){
        super(props);
        this.state={
            OSSData: {},
            fileList:[],
        }
    }

    async componentDidMount() {
        await this.init();
    }
    render() {
        const {fileList} = this.state;
        const { type,count ,style} = this.props;
        const props = {
            name: 'file',
            fileList: fileList,
            action: URL+'/api/file/safone-prevent/image/upload.json',
            listType: type==='file'? 'text':type==='image'?"picture-card":'text',
            accept: type==='file'? null:type==='image'?"image/*":".docx,.pdf,.doc,.xls,.xlsx",
            onChange: this.onChange,
            onRemove: this.onRemove,
            transformFile: this.transformFile,
            // data: this.getExtraData,
            beforeUpload: this.beforeUploadCkeckFile,
        };
        return (
            <Upload style={style} {...props}>
                {type==='image'?
                    count?fileList.length<count?<div><PlusOutlined/><div style={{ marginTop: 8 }}>上传图片</div></div>:null:<div><PlusOutlined/><div style={{ marginTop: 8 }}>上传图片</div></div>
                    :
                    count?fileList.length<count?<Button icon={<UploadOutlined />}>点击上传文件</Button>:null:<Button icon={<UploadOutlined />}>点击上传文件</Button>
                }
            </Upload>
        )
    }

    init = async () => {
        setTimeout(()=>{
            const{value} = this.props
            if(value){
                this.setState({fileList:value})
            }
        },300)
    };


    onChange = ({ file,fileList }) => {
        const { onChange,type ,fileNameFun,count} = this.props;
        const { OSSData } = this.state;
        if(file.status==='done') {
            message.success('上传文件成功！')
            file = fileList[fileList.length - 1];
            file.url = file.response.fullname;
            file.uri =  file.response.uri
        }
        if (onChange) {
            let uriList = this.getUriList(fileList)
            this.setState({fileList:[...fileList]})
            if(count===1 && !(type==='image')){
                onChange(uriList[0],fileList)
            }else {
                onChange([...uriList],fileList);
            }
        }
        if(fileNameFun && count===1&&fileList&&fileList[0]){
            fileNameFun(fileList[0].name)
        }
    };

    onRemove = file => {
        const { value, onChange } = this.props;
        const {fileList} =this.state
        const files = fileList.filter(v => v.url !== file.url);
        if (onChange) {
            onChange(files);
        }
    };

    transformFile = file => {
        const { OSSData } = this.state;
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        file.url = OSSData.dir + filename;
        return file;
    };

    getExtraData = file => {
        const { OSSData } = this.state;
        return {

        };
    };

     beforeUploadCkeckFile=(file)=> {
         const {type} = this.props;
         if(type && type==='file'){
             const isLt2M = file.size / 1024 / 1024 < 10;
             if (!isLt2M) {
                 message.info('你的图片超过10M请重新上传！');
                 file.status = "error";
             }
             return isLt2M;
         }else if(type&&type==='image'){
             const isJpgOrPng = [ 'image/jpeg','image/png','image/jpg'].includes(file.type);
             if (!isJpgOrPng) {
                 message.info('请选择类型为 jpeg，png，jpg 图片文件！');
                 file.status = "error";
             }
             const isLt2M = file.size / 1024 / 1024 < 10;
             if (!isLt2M) {
                 message.info('你的图片超过10M请重新上传！');
                 file.status = "error";
             }
             return isJpgOrPng && isLt2M;
         }else {
             let fileTypeList = file.name.split('.')
             let fileType = fileTypeList[fileTypeList.length-1]
             const isFile = ['pdf','docx','doc','xls','xlsx'].includes(fileType)
             if (!isFile) {
                 message.info('请选择正确的文件！（pdf，docx，doc，xls，xlsx）');
                 file.status = "error";
             }
             const isLt2M = file.size / 1024 / 1024 < 100;
             if (!isLt2M) {
                 message.info('你的文件超过100M，请压缩文件或重新选择文件上传！');
                 file.status = "error";
             }
             return isFile && isLt2M;
         }

    }

    beforeUpload = async () => {
        const { OSSData } = this.state;
        const expire = OSSData.expire * 1000;
        if (expire < Date.now()) {
            await this.init();
        }
        return true;
    };

    getUrlList=(list)=> {
        let urlList = []
        list.map(item => {
            if(item.url)
            urlList.push(item.url)
        })
        return urlList
    }

    getUriList=(list)=> {
        let urlList = []
        list.map(item => {
            if(item.uri)
                urlList.push(item.uri)
        })
        return urlList
    }

    remove = (url)=>{
        let {fileList} = this.state;
        if(url && fileList && fileList.length>0){
            fileList = fileList.filter(item => item.url !== url);
        }
        this.setState({
            fileList: fileList
        })
    }
}