import  {HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete,FetchApi} from './AxiosUtils'
import {URL, zoneCodeConfig} from '../config/urlConf/urlConf'

//获取设备统计信息
//  /safe/device/statistic?
export const reqGetScreenList = () => HttpClientget(URL +"/api/safe/device/statisticDevices",{},{});
//获取报警信息
export const getAlarmMessageList=(pageNum,pageSize,value)=>{
    let values = pageHelper(pageNum,pageSize,value)
    return HttpClientget(URL+`/api/safe/device/report/unit/pages.json`,values, {});
}
//获取报警信息
export const getAlarmMessageDetail=(e)=>HttpClientget(URL+`/api/safe/device/${e.id}/report/face/pages.json?page=0&size=15`,{}, {});
//获取所有最高级组织架构
export const reqCollegeGetAllPrimary = (type)=> HttpClientget(URL+"/api/usercenter/organization/allPrimary/?"+"type="+type,{},{});
/**分级报警管理接口*/
//{/waringMgmt页面}
//创建分级报警负责人
export const reqWaringMgmtAdd = (value) => HttpClientpost(URL+`/api/safe/cms/alarm/tier`,value,{});
//查询某个组织下的报警负责人
export const reqWaringMgmtGetWaringMgmt = (pageNum,pageSize,value)=>{
    value = pageHelper(pageNum,pageSize,value)
    let str = urlEncode(value)
    return  HttpClientget(URL+"/api/safe/cms/alarm/tier?"+ str,{},{});
}
//分页查询报警人列表
export const reqWaringMgmtGetPageWaringMgmt = (pageNum,pageSize,value)=>{
    value = pageHelper(pageNum,pageSize,value)
    let str = urlEncode(value)
    return  HttpClientget(URL+"/api/safe/cms/alarm/tier/pages?"+ str,{},{});
}
/**责任人管理接口*/
//{/principal页面}
//创建责任人
export const reqPrincipalAdd = (value)=> HttpClientpost(URL+`/api/safe/cms/responsible`,value,{});
//查询组织下所有负责人
export const reqCollegeGetPagePrincipal = (pageNum,pageSize,value)=>{
    //value = pageHelper(pageNum,pageSize,value)
    let str = urlEncode(value)
    return  HttpClientget(URL+"/api/safe/cms/responsible?"+ str,{},{});
}
//查询分级负责人下拉框
export const reqCollegeGetPrincipalPull = (pageNum,pageSize,value)=>{
    //value = pageHelper(pageNum,pageSize,value)
    let str = urlEncode(value)
    return  HttpClientget(URL+"/api/safe/cms/responsible/pull/down?"+ str,{},{});
}
//分页查询负责人
export const reqCollegeGetPonsibles = (pageNum,pageSize,value)=>{
    value = pageHelper(pageNum,pageSize,value)
    return  HttpClientget(URL+"/api/safe/cms/responsible/pages",value,{});
}

//打开视频
export const reqOpenVideo = (e,num)=>{
    const form = new FormData
    form.append("deviceId",e.deviceId)
    form.append("videoNumber",num)
    form.append("deviceType",e.deviceType)

    return  HttpClientpost(URL+"/api/safe/device/video/trans/open",form,{"Content-Type":"application/x-www-form-urlencoded;charset=UTF-8"});
}
//关闭视频
export const reqCloseVideo = (e,num)=>{
    const form = new FormData
    form.append("deviceId",e.deviceId)
    form.append("videoNumber",num)
    form.append("deviceType",e.deviceType)
    return  HttpClientpost(URL+"/api/safe/device/video/trans/close",form,{"Content-Type":"application/x-www-form-urlencoded;charset=utf-8"});
}
//查询APK
export const reqGetPageApkList = (value) => HttpClientget(URL+`/api/safe/device/version/list`,value,{});
//新增APK
export const reqAddApk = (value) => HttpClientpost(URL+`/api/safe/device/version.json`,value,{});
//删除APK
export const reqDelApk = (id) => HttpClientdelete(URL+`/api/safe/device/version/delete/`+id,{},{});

//添加水库
export const reqAddReservoir = (value) => HttpClientpost(URL+`/api/safe/device/area/reservoir`,value,{});
//查询水库
export const reqGetPageReservoirList = (pageNum,pageSize,value) => {
    let e = pageHelper(pageNum,pageSize,value)
    return HttpClientget(URL+`/api/safe/device/area/reservoirlist`,e,{});
}
//修改水库
export const reqChangeReservoir = (value) => HttpClientput(URL+`/api/safe/device/area/reservoir`,value,{});

//查询区域
export const reqGetReservoirTree = (value)=>HttpClientget(URL+`/api/safe/device/area/tree?zoneCode=`+value,{},{});
//添加区域
export const reqAddAddress = (value) => HttpClientpost(URL+`/api/safe/device/area`,value,{});
//修改区域
export const reqUpAddress = (value) => HttpClientput(URL+`/api/safe/device/area`,value,{});
//添加设备
export const reqAddDevice = (value) => HttpClientpost(URL+`/api/safe/device/device`,value,{});
//获取设备数据
export const getStatisticDevices = (value) => HttpClientget(URL+`/api/safe/device/statisticDevices`,value,{});

//获取拨号记录
export const reqGetDialList = (value)=>HttpClientpost(URL+`/api/safe/alarm/calledrecord/list`,value,{});
//获取短信记录
export const reqGetNoteList = (value)=>HttpClientpost(URL+`/api/safe/alarm/messagerecord/list`,value,{});

//查询设备异常日志
export const reqGetDeviceLogList = (pageNum,pageSize,value) => {
    let e = pageHelper(pageNum,pageSize,value)
    return HttpClientget(URL+`/api/safe/device/groupExceptionLogs`,e,{});
}
//查询设备异常日志详情
export const reqGetDeviceLogDetail = (pageNum,pageSize,value) => {
    let e = pageHelper(pageNum,pageSize,value)
    return HttpClientget(URL+`/api/safe/device/exceptionLogs`,e,{});
}
//获取7天报警数据
export const getWeekAlarmData = (value) => HttpClientget(URL+`/api/safe/device/report/unit/weekAlarmData`,value,{});
//获取一年报警数据
export const getYearAlarmData = (value) => HttpClientget(URL+`/api/safe/device/statistic/yearAlarmData`,value,{});

//获取人脸饼图
export const getStaticsUserFaces = (value) => HttpClientpost(URL+`/api/safe/cms/user/staticsUserFaces`,value,{});

//拨号数，短信数，有效报警数
export const getData = (value) => HttpClientpost(URL+`/api/safe/alarm/countAlarmInfo`,value,{});

//获取水库区域树
export const getDeviceTree = (value) => HttpClientget(URL+`/api/safe/device/area/tree`,value,{});

//获取大屏市区水库树结构
export const getCenterAreaTree = (value) => HttpClientget(URL+`/api/safe/device/area/centerAreaTree`,value,{});
//根据区域查询设备
export const getCenterAreaDevice = (nodeId,nodeType) => HttpClientpost(URL+`/api/safe/device/area/centerAreaTreeDevices?nodeId=${nodeId}&nodeType=${nodeType}`,{},{});
//获取报警人脸数据
export const getDeviceStatisticAlarmFaceData = () => HttpClientget(URL+`/api/safe/device/statistic/alarm/face.json?zoneCode=`+zoneCodeConfig,{},{});

//export const reqAddSchoolInfo=(param)=>HttpClientpost(URL+"/api/safe/device/report/unit/pages.json?page=0&size=10", {...param}, {});
/**
 * 对象转get请求后缀
 * @param param 对象
 * @param key 前置参数
 * @param encode
 * @returns {string}
 */
const urlEncode = (param, key, encode)=> {
    if(param===null) return '';
    let paramStr = '';
    const t = typeof (param);
    if (t === 'string' || t === 'number' || t === 'boolean') {
        paramStr += '&' + key + '=' + ((encode===null||encode) ? encodeURIComponent(param) : param);
    } else {
        for (let i in param) {
            const k = key && key !== null ?  key + (param instanceof Array ? '[' + i + ']' : '.' + i) :i;
            paramStr += urlEncode(param[i], k, encode);
        }
    }
    return paramStr;
};
const pageHelper=(pageNum,pageSize,value)=>{
    let values
    if(value instanceof Object){
        values= value
        values.page = pageNum
        values.size = pageSize
    }else {
        values = {}
        values.page = pageNum
        values.size = pageSize
    }
    return values
}