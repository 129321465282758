import React, {Component} from "react";
import {Modal, Button, message, Popconfirm,Input} from 'antd'
import MyTable from "../../common/myTable/myTable";
import {reqAddApk,reqGetPageApkList,reqDelApk} from "../../api/clApi";
import {PAGE_SIZE} from "../../utils/constants";
import MyOssUpLoad from "../../common/Upload/ossUpload";
import PageTitle from "../../common/pageTitle";
import MyCard from "../../common/MyCard/mainCard";

export default class  extends Component {
    state={
        apkList:[]
    }
    componentWillMount(){
        this.init()
    }
    componentDidMount() {
        this.getList()
    }
    setStateAsync = (state) => {
        return new Promise((resolve, reject) => {
            this.setState(state, resolve);
        });
    };

    render() {
        const {apkList}=this.state
        return (
            <div className="page_home">
                <MyCard className="card_main" title={<PageTitle title="版本管理"/>}>
                    <div style={{display:"flex",justifyContent:"flex-end",margin:"10px 0"}}>
                        {/*<Input id="versionName" placeholder="名称" style={{width:"200px",marginLeft:"10px",marginRight:"10px",color:"black"}}></Input>*/}
                        {/*<Input id="version" placeholder="版本号" style={{width:"200px",marginLeft:"10px",marginRight:"10px",color:"black"}}></Input>*/}

                        {/*<Button type="primary" onClick={this.search}>查询</Button>*/}
                        <Button type="primary" onClick={this.showSet} style={{marginLeft:"20px"}}>添加版本</Button>
                    </div>
                    <MyTable column={this.apkColumn} data={apkList} pageFun={(c)=>{this.getList(c)}}/>
                </MyCard>
                <Modal
                    title="添加APK信息"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div>
                        <MyOssUpLoad onChange={this.onChange} count={1}></MyOssUpLoad>
                    </div>
                    <div style={{display:"flex",width:"100%"}}>
                        <div style={{width:"50%"}}>
                            名称
                            <input type="text" id="addName" style={{display:"block",width:"90%",marginBottom:"5px"}}/>
                        </div>
                    </div>
                    <div>
                        版本号:<input placeholder={"仅限整数"} type="text" id="addVersion" style={{display:"block",width:"80%",marginBottom:"5px"}}/>
                    </div>
                </Modal>
            </div>
        );
    }
    /**
     *接口请求函数
     */
    getList=async(pageNum)=>{
        this.setState({tableLoading:true});
        const res = await reqGetPageApkList();
        this.setState({tableLoading:false});
        if(res && res.code &&res.code===200){
            res.pageNum=pageNum;
            this.setState({apkList:res,pageNum:pageNum})
        }
    };
    //查询
    search=async (e,pageNum)=>{
        const versionName = document.getElementById("versionName").value
        const version = document.getElementById("version").value
        let value = {
            version:version,
            versionName:versionName
        }
        this.setState({tableLoading:true});
        let res = await reqGetPageApkList(value)
        this.setState({tableLoading:false});
        if(res && res.code &&res.code===200){
            res.pageNum=pageNum;
            this.setState({apkList:res,pageNum:pageNum})
        }

    }
    //添加APK
    showSet=()=>{
        this.setState({
            visible:true,
        })
    }
    onChange=(e,item,host)=>{
        console.log(e,item,host)
        if(item.length>0){
            this.setState({
                originName:item[0].name,
                fileSize:item[0].size,
                uri:item[0].originFileObj.url,
                resourceHost:host
            })
        }else{
            this.setState({
                originName:"",
                fileSize:"",
                uri:"",
                resourceHost:""
            })
        }

    }
    handleOk=async ()=>{

        const _that = this
        const name = document.getElementById("addName").value
        const version = document.getElementById("addVersion").value
        if(this.state.uri==""){
            message.info("文件上传失败请重选选择")
            return
        }
        if(!name){
            message.info("请填版本名称")
            return
        }
        if(!version){
            message.info("请填版本号")
            return
        }
        if((version.toString()).indexOf(".") > -1){
            message.info("版本号应为整数")
            return
        }
        var value = {
            "versionName":name,
            "version":version,
            "downloadUri":_that.state.uri,
        }
        await this.setStateAsync({
            loading:true,
            visible:false,
            visible1:false,
        })
        let res = await reqAddApk(value)
        console.log(res)
        if(res.code==200){
            message.success("创建成功")
            this.getList()
        }else{
            message.error("创建失败")
        }


    }
    handleCancel=()=>{
        this.setState({
            visible:false,
        })
    }
    del=async(e)=>{
        console.log(e)

        let res = await reqDelApk(e.id)
        if(res.code==200){
            this.getList()
            message.success("删除成功")
        }else{
            message.error("删除失败")
        }
    }

    init=()=>{
         this.apkColumn = [
            {
                title: '名称',
                dataIndex: 'versionName',
                key: 'versionName',
                align:"center"
            },
             {
                 title:"版本号",
                 dataIndex: 'version',
                 key: 'version',
                 align:"center"
             },
            {
                title:"创建时间",
                dataIndex: 'createdTime',
                key: 'createdTime',
                align:"center"
            },

            {
                title:"删除",
                dataIndex: '',
                key: 'delete',
                align:"center",
                render:(e)=>{
                    return <Popconfirm
                        title="确定删除该版本?"
                        onConfirm={this.del.bind(this,e)}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                }
            },


        ]
    }
}
