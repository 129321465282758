/**
 *看板数据
 */
import React, {Component} from 'react'
import {
    getCenterAreaDevice,
    getData,
    getStaticsUserFaces,
    getStatisticDevices,
    reqGetScreenList,
    getDeviceStatisticAlarmFaceData
} from '../../api/clApi'
import {reqGetSafeDeviceByDeviceId, reqOpenStreamByDeviceId} from "../../api/index";
import {Modal, Button, Image, Layout, message, Tree} from 'antd'
import {BgColorsOutlined,} from '@ant-design/icons';
import DeviceOnline from './componets/deviceOnline'
import Waring from './componets/waring'
import Map from './componets/map'
import FacePie from './componets/facePie'
import {HttpClientget} from '../../api/AxiosUtils';
import {provinceTitle, URL, zoneCodeConfig} from '../../config/urlConf/urlConf'
import './screen.css'
import {SUCCESS} from "../../utils/constants";
import FlvPlayer from "xgplayer-flv";

const { Header, Content, Sider } = Layout;

let player = null;
export default class ScreenHome extends Component {
    constructor(props) {
        super(props);
        this.state={
            closeTime:30,
            treeData:[],
            allDevices:[],
            abnormalDeviceList:[],
            alarmStatistic:[],
            faceList:[],
            searchValue: '',
            playTime:0,
            autoExpandParent: true,
            leftList:[],
            rightList:[],
            onlineNum:0,
            offlineNum:0,
            onlineRate:"0%",
            tsSrc:"",
            deviceStatus:[
                {
                    label:'正常',
                    value:'normal'
                },
                {
                    label:'离线',
                    value:'offline'
                },
                {
                    label:'温度异常',
                    value:'temp-exception'
                },
                {
                    label:'湿度异常',
                    value:'hum-exception'
                },
                {
                    label:'温湿度异常',
                    value:'temp-hum-exception'
                },
                {
                    label:'一键报警中',
                    value:'one-key-alarming'
                },
                {
                    label:'报警中',
                    value:'alarming'
                },
            ],
            showVideo: false,
        };
        this.map = React.createRef();
    }


    getScreenList=async()=>{
        const res = await reqGetScreenList()
        if(res.code === 200){
            // message.success('请求看板数据成功！')
            // this.map.setMarkers(res)
            return res.result
        }else {
            message.error('请求看板数据失败！')
        }
    }
    //获取拨号数，短信数，有效报警数,学校数
    getData=async ()=>{
        let res = await getData()
        let res2 = await  getStaticsUserFaces()
        this.setState({
            allData:res,
            schoolCount:res2?.result.schoolCount,
            havingFaceUserCount:res2?.result.havingFaceUserCount
        })
    }

    getfaceList=async ()=>{
        const res = await getDeviceStatisticAlarmFaceData();
        if(res.code===200){
            if(JSON.stringify(res.results)!==JSON.stringify(this.state.faceList)){
                clearInterval(this.scrollFacetimer)
                this.setState({
                    faceList:res.results,
                    resetScrollFace:true
                },()=>{
                    this.scrollFace()
                })
            }
        }
    }

    getFaceDataTask=()=>{
        this.timerFace=setInterval(async ()=>{
            await this.getfaceList()
        },30000);
    }

    getStatistic=async ()=>{
        let res = await getStatisticDevices()
        if(res.code==200){
            this.setState({
                onlineNumber:res.result.onlineCountStatistic.onlineNum,
                offNumber:res.result.onlineCountStatistic.offlineNum,
                onlineRate:res.result.onlineCountStatistic.onlineRate,
            })
        }
    }

    scrollFace=(time)=>{
        let faceBox = document.getElementById("faceBox")
        this.scrollFacetimer = setInterval(()=>{
            faceBox.scrollLeft+=2
            if((faceBox.scrollWidth-faceBox.scrollLeft)<(faceBox.clientWidth+20)||this.state.resetScrollFace){
                faceBox.scrollLeft=0
                this.setState({
                    resetScrollFace:false
                })
            }
        },50)
    }
    componentDidMount(){
        this.reGetScreenList()
        this.getList()
        this.getStatistic()
        this.getData()
        this.getfaceList();
        this.getFaceDataTask();
    }
    listInitKey = (list)=>{
        let mlist = list
        mlist.forEach(item =>{
            item.key = item.id;
            item.title = item.name+'('+item.deviceTotalCount+')';
            item.value = item.id;
            item.icon=item.nodeType==1?<BgColorsOutlined />:""
            if(item.nodeChildList){
                item.children =  this.listInitKey(item.nodeChildList)
            }
        })
        return mlist
    }
    //获取水库树
    getList= ()=>{
        HttpClientget(URL+"/api/safe/device/area/centerAreaTree?zoneCode="+zoneCodeConfig,{},{
        }).then((e)=>{
            if(e.code==200){
                this.setState({
                    treeData:this.listInitKey(e.results)
                })
            }
        })
    }


    componentWillUnmount () {
        clearInterval(this.startSetInterval)
        clearInterval(this.lookingTime)
        clearInterval(this.timerFace)
    }
    //树形结构

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    onChange = e => {
        const { value } = e.target;
    };

    selectDevice=async (e,e2)=>{
        console.log("e", e);
        console.log("e2", e2);
        if(e.length>0){
            let res= await getCenterAreaDevice(e,e2.node.nodeType)
            if(res.code==200){
                this.setState({
                    allDevices:res.results
                })
            }
        }

        const zoneCode=e[0]
        if(zoneCode){
            HttpClientget(URL+`/api/safe/device/statisticDevices?zoneCode=${zoneCode}`,{},{}).then((e)=>{
                if(e.code===200){
                    const alarmStatistic=e.result.alarmStatistic
                    const humExceptionStatistic=e.result.humExceptionStatistic
                    const normalStatistic=e.result.normalStatistic
                    const offlineStatistic=e.result.offlineStatistic
                    const oneKeyAlarmStatistic= e.result.oneKeyAlarmStatistic
                    const tempExceptionStatistic = e.result.tempExceptionStatistic
                    this.setState({
                        allDevices:[...alarmStatistic,...humExceptionStatistic,...normalStatistic,...offlineStatistic,...oneKeyAlarmStatistic,...tempExceptionStatistic]
                    },()=>{

                    })
                }
            })
        }

    }

    showVideoLook=async(device, index)=>{
        const result = await reqGetSafeDeviceByDeviceId({deviceId: device.deviceId});
        if(result.code === SUCCESS){
            //开启点播
            this.setState({
                showVideo: true,
            }, async ()=>{
                if(result.result.cameraDto){
                    const res = await reqOpenStreamByDeviceId({deviceId: result.result.cameraDto.deviceId});
                    if(res.code === SUCCESS){
                        if(player){
                            player.destroy(false);
                        }
                        player = new FlvPlayer({
                            id: 'mse',
                            isLive: true,
                            playsinline: true,
                            height: '405px',
                            width: '720px',
                            url: res.result,
                            autoplay:true,
                        });
                    }else {
                        message.error("点播失败: " + result.message)
                    }
                }else{
                    message.error("设备尚未绑定摄像头");
                }
            })
        }else{
            message.error("点播失败: " + result.message)
        }
    }

    //从Map中获取点击设备
    getVideo=(e)=>{
        const arr = [{
            deviceId:e.deviceId,
            livePicture:e.livePicture,
            location:e.location,
            liveVideo:e.liveVideo
        }]
        this.setState({
            allDevices:arr
        })
    }
    render() {
        const {leftList,rightList,treeData,onlineNum,offlineNum,onlineRate,allDevices,showVideo,allData,schoolCount,havingFaceUserCount,
            alarmStatistic,showToast,showTip,onlineNumber,offNumber}=this.state
        const contentList = {
            "videoModal":<div
                style={{display: showVideo?"block":"none"}}
            >
                <div className={"white_content"} style={{display: showVideo?"block":"flex"}}>
                    <div id='mse' />
                </div>
                <div className={"black_overlay"} onClick={() => {
                    this.setState({showVideo: !showVideo});
                    if(player){
                        player.destroy(false);
                    }
                    player = new FlvPlayer({
                        id: 'mse',
                        isLive: true,
                        playsinline: true,
                        height: '405px',
                        width: '720px',
                        controls: false,
                        url:'',
                    });
                }}>
                </div>
            </div>,
        };
        return (
            <div style={{width:'100%',height:'100%',background:'#34384D',overflow:'hidden'}}>
                <Layout className="screen_main" style={{background:'#34384D'}}>
                    <Header className="screen_header" style={{padding:0}}>
                        <img className="header_left" src={require('../../assets/screen/titlePicLeft.png')}/>
                        <h1 className="header_center" >{provinceTitle+"防溺水预警指挥中心"}</h1>
                        <img className="header_right" src={require("../../assets/screen/titlePicRight.png")}/>
                    </Header>
                    <Layout style={{background:'rgba(0,0,0,0)'}}>
                        <Sider width="16%" className="screen_body_left">
                            <div style={{display:"flex",height:"60%",overflowY:"scroll",background:"rgba(0,0,0,0.14)"}}>
                                <div style={{width:"50%",borderRight:"1px solid grey"}}>
                                    <Tree
                                        showIcon
                                        treeData={treeData}
                                        onSelect={this.selectDevice}
                                        style={{background:"rgba(0,0,0,0)",color:"white",fontSize:"12px",fontWeight:600}}
                                    />
                                </div>
                                <div style={{width:"45%",paddingLeft:"10px"}}>
                                    <h1 style={{color:"white"}}>设备列表</h1>
                                    {allDevices.map((item,index)=>{
                                        return  <div style={{border:"1px solid white",padding:"5px",marginTop:"5px"}} key={"all-device-"+index}>
                                            <div style={{color:"white"}}>设备号：{item.deviceId}</div>
                                            <Button onClick={()=>{this.showVideoLook(item, 0)}} style={{marginTop:"5px"}}>视频</Button>
                                            <Image src={item.livePicture} alt="" style={{width:"90%",marginTop:"3px"}} fallback="http://ali-oss.safephone.cn/files/safe/device/images/58802BC6F8C14AEC87DFFE8E177C8BD5.jpg"/>
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className="screen_body_left_second">
                                <div style={{alignSelf:'flex-start'}}>
                                    <h1 className="screen_body_title">设备状态</h1>
                                    <img  src={require("../../assets/screen/line.png")} alt="" style={{marginLeft:"0.1rem",width:"0.8rem",height:"2px"}}/>
                                </div>
                                <DeviceOnline onlineRate={onlineRate}/>
                                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
                                    <div className="screen_body_left_second_number">{onlineNumber?onlineNumber:0 + offNumber?offNumber:0}</div><div className="screen_body_left_second_word">总数</div>
                                    <div className="screen_body_left_second_number">{onlineNumber?onlineNumber:0}</div><div className="screen_body_left_second_word">在线数</div>
                                </div>
                                <div  style={{display:'flex',width:'100%',justifyContent:'center',marginTop:20}}>
                                    <div style={{marginLeft:10,color:'#54DEFE'}} className="screen_body_left_second_number">{onlineRate}</div><div className="screen_body_left_second_word">在线占比</div>
                                </div>
                            </div>
                        </Sider>
                        <Content className="screen_body_center" >
                            <Map ref={this.map} getVideo={this.getVideo}/>
                            <h2 className="screen_body_title" style={{color:"red",fontWeight:"600",fontSize:"14px",marginBottom:"5px"}}>实时预警</h2>
                            <div style={{position:"relative",display:"flex",height:"25%",overflowX:"auto"}}>
                                <div id="faceBox" style={{width:"100%",overflowX:"scroll",display:"flex",overflowY:"hidden"}}>

                                    {this.state.faceList.map((items,imgs_index)=>{
                                        return (
                                            items.faceUrl.length>0?
                                                <div key={imgs_index+"img1"} style={{marginLeft:"20px"}}>
                                                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                                        <p>{items.happenedAtStr}</p>
                                                        <p>
                                                            姓名：{items.matchUserName!=""?items.matchUserName.replace(items.matchUserName.substring(1,2),"*"):"无"}
                                                        </p>
                                                        <p>组织：{items.organizationFullName!=""?items.organizationFullName:"无"}</p>
                                                        <p style={{fontSize:"14px",width:"200px"}}>{items.location}</p>
                                                        <img src={items.faceUrl}  alt='' style={{width:"0.55rem",height:"0.55rem",display:"block"}}></img>
                                                    </div>
                                                </div>:""
                                        )
                                    })}
                                </div>
                            </div>
                        </Content>
                        <Sider width="16%" className="screen_body_right" >
                            <div className="screen_body_right_second">
                                <div style={{alignSelf:'flex-start'}}>
                                    <h1 className="screen_body_title">今年预警报警统计</h1>
                                    <img  src={require("../../assets/screen/line.png")} alt="" style={{marginLeft:"0.1rem",width:"0.8rem",height:"2px"}}/>
                                </div>
                                <Waring/>
                            </div>
                            <div className="screen_body_right_three">
                                <FacePie></FacePie>
                            </div>
                            <div className="screen_body_right_schoolNuber">
                                <div style={{display:"flex",justifyContent:"center"}}>
                                    <div className="screen_body_left_second_word">学校数量</div>
                                    <div className="screen_body_left_second_number">{schoolCount}</div>
                                </div>
                                <div style={{display:"flex",justifyContent:"center"}}>
                                    <div className="screen_body_left_second_word">学生人脸数量</div>
                                    <div className="screen_body_left_second_number">{havingFaceUserCount}</div>
                                </div>
                            </div>
                            <div className="screen_body_right_three">
                                <div style={{alignSelf:'flex-start'}}>
                                    <h1 className="screen_body_title">今年预警报警统计</h1>
                                    <img  src={require("../../assets/screen/line.png")} alt="" style={{marginLeft:"0.1rem",width:"0.8rem",height:"2px"}}/>
                                </div>
                                <div>
                                    <div style={{display:"flex",paddingLeft:"20px",marginBottom:"20px"}}>
                                        <div className="screen_body_left_second_word">有效报警数:</div>
                                        <div className="screen_body_left_second_number">{allData?.validAlarmCount}</div>
                                    </div>
                                    <div style={{display:"flex",paddingLeft:"20px",marginBottom:"20px"}}>
                                        <div className="screen_body_left_second_word">成功拨号数:</div>
                                        <div className="screen_body_left_second_number">{allData?.callCount}</div>
                                    </div>
                                    <div style={{display:"flex",paddingLeft:"20px",marginBottom:"20px"}}>
                                        <div className="screen_body_left_second_word">发送信息数:</div>
                                        <div className="screen_body_left_second_number">{allData?.msgCount}</div>
                                    </div>

                                </div>
                            </div>
                        </Sider>
                    </Layout>
                </Layout>
                {contentList["videoModal"]}
            </div>
        )
    }

    reGetScreenList=async ()=>{
        // this.startSetInterval=setInterval(async()=>{
        //     const res = await this.getScreenList()
        //     if(res){
        //         this.setState({
        //             // onlineNum:res.onlineCountStatistic.onlineNum,
        //             // offlineNum:res.onlineCountStatistic.offlineNum,
        //             // onlineRate:res.onlineCountStatistic.onlineRate,
        //             humExceptionStatistic:res.humExceptionStatistic,//湿度异常设备
        //             offlineStatistic:res.offlineStatistic,//离线设备
        //             tempExceptionStatistic:res.tempExceptionStatistic,//温度异常设备
        //             rightList:[...res.humExceptionStatistic,...res.offlineStatistic,...res.tempExceptionStatistic],
        //             alarmStatistic:res.alarmStatistic,//实时预警
        //         })
        //         if(!this.state.showVideo){
        //             this.map.current.setValue(res)
        //         }
        //     }
        //
        // },2000)
    }
}
