/**
 *封装数据格式
 * column:表头数据
 * pageSize:分页大小
 * loading:是否加载中
 * data:{
 *    pageNum:1
 *    totalCount:101
 *    results:表页数据
 * }
 * handlePagination(current):分页回调函数
 *
 */
import React, {Component} from 'react'
import {PAGE_SIZE} from '../../utils/constants'
import {Table} from 'antd'
export default class MyTable extends Component {
    state={
        selectRooms:[]
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.selectRooms){
            if(!this.state.close){
                this.setState({
                    selectRooms:nextProps.selectRooms
                })
            }
        }
    }

    render() {
        const {column,data,loading,pageFun,pageSize,selection,selectionFun, preserveSelectedRowKeys,hiddenScroll,style,keyField}=this.props
        const rowSelection = {
            type:'checkbox',
            fixed:true,
            selectedRowKeys:this.state.selectRooms,
            preserveSelectedRowKeys:preserveSelectedRowKeys?true:false,
            onChange:(selectedRowKeys, selectedRows)=>{
                this.setState({
                    close:true
                },()=>{
                    selectionFun(selectedRowKeys)
                    this.setState({
                        selectRooms:selectedRowKeys
                    })
                })

            },
            onSelect:(record, selected, selectedRows, nativeEvent)=>{
                // console.log(record,'/', selected,'/', selectedRows,'/', nativeEvent)
                //let selectRooms = this.state.selectRooms
                // selectRooms.push(record)
            },
        }
        return (
            <Table
                style={style}
                size="small"
                    bordered
                    childrenColumnName={'sub'}
                    columns={column?column:[]}
                    scroll={hiddenScroll?null:{x:1200,Y:800}}
                    rowKey={record => {
                        if(keyField)
                            return record[keyField];
                        if(record.userId)
                            return record.userId
                        return record.id
                    } }
                    dataSource={data? Array.isArray(data)?data:data.results:[]}
                    loading={loading?loading:false}
                    rowSelection={selection?rowSelection:false}
                    rowClassName={(record, index) => {
                        let className = 'light-row';
                        if (index % 2 === 1) className = 'dark-row';
                        return className;
                    }}
                    pagination={
                        pageFun?
                        {
                            showSizeChanger:false,
                        current:data?data.pageNum:0,
                        total: data?data.totalCount:0,
                        showTotal:()=> {  //设置显示一共几条数据
                            let count = data?data.totalCount:0
                            return '共 ' + count  + ' 条数据';
                        },
                        pageSize: pageSize?pageSize:PAGE_SIZE,
                        onChange(current) {
                            if(pageFun){
                                pageFun(current)
                            }
                        },
                    }
                    :false
                    }
            />
        )
    }
}
