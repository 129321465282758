/**
 *
 */
import React, {Component} from 'react'

import { Table,Select, InputNumber ,Radio,Checkbox,Input,Form,Transfer,Modal,Row,Col,message,Card,Tree, Button,Switch,Cascader } from 'antd';
import {regCompoundGetZone, regCompoundGetZoneChild, reqCollegeGetAllPrimary} from '../../../api/index'
import {SUCCESS} from "../../../utils/constants";


export default class Mtree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treeData:[],
            deviceList: [],
            addDepartmentVisible: false,
            updateDepartmentVisible: false,
            addGroupVisible: false,
            updateGroupVisible: false,
            addRoomVisible: false,
            RadioValue: 1,
            selectNode: {},
            compoundTotal: 0,
            pageNum: 0,
            cityOptions:[],

        }

    }

    componentDidMount() {
        this.getAllPrimary(2);
        this.getAllProvince()

    }


    //获取所有最高级组织架构
    getAllPrimary=async (type)=>{
        const res = await reqCollegeGetAllPrimary(type);
        if(res && res.results && res.results.length>0){
            this.convertValueFiled(res.results);
            this.setState({
                treeData:res.results
            })
            this.props.parent.setState({
                treeData:[...res.results]
            })
        }
    }
    convertValueFiled = (list)=>{
        list.forEach((e) => {
            e.value = e.id;
            e.title = e.name;
            e.key = e.id;
            if(e.children && e.children.length>0){
                this.convertValueFiled(e.children)
            }
        })
    }


    render() {
        const {cityOptions}=this.state
        return (
            <div style={{width:'100%',height:'100%'}}>
                <Card size="small" title="组织架构"   style={{ padding:0,width:'100%',height:'700px',overflowY:"scroll" }}>
                    {/*<Cascader*/}
                    {/*    options={cityOptions}*/}
                    {/*    loadData={this.cascaderLoader}*/}
                    {/*    onChange={this.onChangePro}*/}
                    {/*    changeOnSelect*/}
                    {/*    style={{width:"100%"}}*/}
                    {/*/>*/}
                    <Tree height={650} defaultExpandedKeys={[-1]}  selectedKeys={[this.state.selectNode.key]} titleRender={this.titleRender.bind(this)} showLine={true}  onSelect={this.onSelect} treeData={this.state.treeData}  style={{width:"100%"}}/>
                </Card>
            </div>


        )
    }
    /**
     *{
        title: 'parent 1',
        key: '0-0',
        icon: <CarryOutOutlined />,
        children: []
     * }
     * @param node
     * @returns {XML}
     */
    titleRender=(node)=> {
        return(<div style={{width:100,whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',cursor:'pointer'}}>{node.title}</div>)
    }
    onSelect =  (selectedKeys, info) => {
        if(!info.node.children || info.node.children.length<1){
            this.props.parent.setSelectNode(info.node)
            this.props.parent.getFaceList(info.node.id)
            this.setState({
                selectNode:info.node,
            })
        }

    };
    onChangePro=async (e)=>{
        console.log(e)
        let zoneCode = e[e.length-1]
        console.log(zoneCode)
    }
    //函数
    cascaderLoader=async (selectedOptions)=>{
        const selectedOption = selectedOptions[selectedOptions.length-1];
        selectedOption.loading=true;
        const {cityOptions} = this.state;
        //加载效果
        await this.setState({
            cityOptions: cityOptions
        });
        selectedOption.children = await this.getZoneChildList(selectedOption.value);
        selectedOption.loading=false
        //刷新数据
        this.setState({
            cityOptions: cityOptions
        });
    }
    //获取所有省
    getAllProvince=async ()=>{
        let res = await regCompoundGetZone();
        if(res && res.code && res.code === SUCCESS){
            const options = this.getYunFirstOptions(res.results);
            this.setState({
                cityOptions: options
            })
        }else{
            message.error("获取省市区列表失败");
        }
    }
    getYunFirstOptions=(list)=>{
        let options = [];
        list.forEach(item => {
            options.push({
                value: item.code,
                label: item.name,
                isLeaf: false,
            })
        })
        return options;
    }
    getZoneChildList=async (zoneCode)=>{
        let res = await regCompoundGetZoneChild({code:zoneCode});
        if(res && res.code && res.code===SUCCESS){
            return res.results.map(zone => {
                return {
                    value: zone.code,
                    label: zone.name,
                    isLeaf: zone.level===3,
                }
            })
        }else{
            message.error("网络错误");
        }
    }

}
