/**
 * 部门tree
 */
import React, {Component} from 'react'

import {Card, message, Tree,Cascader} from 'antd';
import {regCompoundGetZone, regCompoundGetZoneChild, reqGetAllPrimaryOrganizationTree} from '../../../api'
import LinkButton from '../../../common/myButton/link-button'
import {DownOutlined, MehOutlined} from '@ant-design/icons';
import {SUCCESS} from "../../../utils/constants";

export default class DutyTree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            compoundList: [
                {
                    title: '所有组织架构',
                    name: '所有组织架构',
                    key: -1,
                    level: 0,
                    children: [
                    ]
                }
            ],
            deviceList:[],
            RadioValue:1,
            selectNode:{},
            compoundTotal: 0,
            pageNum: 0,
            cityOptions:[],

        }
    }

    componentDidMount() {
        this.getAllOrganizationPrimary()
        this.getAllProvince()

    }



    render() {
        const {cityOptions}=this.state

        return (
            <div style={{width:'100%',height:'100%'}}>
                <Card size="small" title="后台组织架构"  extra={<LinkButton/>} style={{ padding:0,width:'100%',height:'700px',overflowY:"auto" }}>
                    <Cascader
                        options={cityOptions}
                        loadData={this.cascaderLoader}
                        onChange={this.onChangePro}
                        changeOnSelect
                        style={{width:"100%"}}
                    />
                    <Tree
                        height={600}
                        defaultExpandedKeys={[-1]}
                        selectedKeys={[this.state.selectNode.key]}
                        onSelect={this.onSelect}
                        treeData={this.state.compoundList}
                        style={{}}/>
                </Card>
            </div>
        )
    }

    getAllOrganizationPrimary = async (value)=>{
        let values = {
            type:2,
            zoneCode:value
        }
        const res =await reqGetAllPrimaryOrganizationTree(values);
        if(res.code === 200){
            let list = this.state.compoundList
            const departmentTree = this.listInitKey(res.results)
            let departmentList = [];
            list[0].children = departmentTree
            if(departmentTree && departmentTree.length>0){
                departmentList = departmentTree;
            }
            this.setState({
                compoundList:[...list]
            },()=>{
                this.props.parent.setState({
                    departmentTree:departmentTree,
                    departmentList:departmentList
                })
            })
        }else {
            message.error('获取失败！！！')
        }
    }

    listInitKey = (list)=>{
        let mlist = list
        mlist.forEach(item =>{
            item.key = item.id;
            item.title = item.name;
            item.value = item.id;
            if(item.children){
                item.children =  this.listInitKey(item.children)
            }
        })
        return mlist
    }

    getTreeSelectList = (node) => {
        const pos = node.pos;
        let arr = pos.split('-');
        let nodeList= this.state.compoundList
        let selectList = []
        for(let i=1;i<arr.length;i++){
            selectList.push( nodeList[arr[i]])
            if(nodeList[arr[i]].children){
                nodeList = nodeList[arr[i]].children
            }
        }
        return selectList
    }

    onSelect =  (selectedKeys, info) => {
        this.props.parent.onTreeSelect(info.node, this.getTreeSelectList(info.node));
        this.setState({
            selectNode: info.node
        })
    }
    onChangePro=async (e)=>{
        console.log(e)
        let zoneCode = e[e.length-1]
        if(e.length!=0){
            this.getAllOrganizationPrimary(zoneCode)
        }
        console.log(zoneCode)
    }
    //函数
    cascaderLoader=async (selectedOptions)=>{
        const selectedOption = selectedOptions[selectedOptions.length-1];
        selectedOption.loading=true;
        const {cityOptions} = this.state;
        //加载效果
        await this.setState({
            cityOptions: cityOptions
        });
        selectedOption.children = await this.getZoneChildList(selectedOption.value);
        selectedOption.loading=false
        //刷新数据
        this.setState({
            cityOptions: cityOptions
        });
    }
    //获取所有省
    getAllProvince=async ()=>{
        let res = await regCompoundGetZone();
        if(res && res.code && res.code === SUCCESS){
            const options = this.getYunFirstOptions(res.results);
            this.setState({
                cityOptions: options
            })
        }else{
            message.error("获取省市区列表失败");
        }
    }
    getYunFirstOptions=(list)=>{
        let options = [];
        list.forEach(item => {
            options.push({
                value: item.code,
                label: item.name,
                isLeaf: false,
            })
        })
        return options;
    }
    getZoneChildList=async (zoneCode)=>{
        let res = await regCompoundGetZoneChild({code:zoneCode});
        if(res && res.code && res.code===SUCCESS){
            return res.results.map(zone => {
                return {
                    value: zone.code,
                    label: zone.name,
                    isLeaf: zone.level===3,
                }
            })
        }else{
            message.error("网络错误");
        }
    }

}