import React, {Component} from "react";
import {Button, message} from "antd";
import PageTitle from "../../common/pageTitle";
import MyCard from '../../common/MyCard/mainCard'
import DeviceAllMap from "./map/deviceAllMap";
import {reqGetScreenList, reqOpenVideo} from "../../api/clApi";
import {videoUrl, zoneCodeConfig} from "../../config/urlConf/urlConf";
//显示所有位置
export default class  extends Component {
    constructor(props) {
        super(props);
        this.state={
            deviceList:[],
        };
        this.deviceMapref = React.createRef()
    }
    componentDidMount() {
        // this.reGetScreenList();
        this.reGetScreenListTask()
    }
    componentWillUnmount() {
        clearInterval(this.startSetInterval)
    }

    render() {
        const {deviceList}=this.state
        return (
            <div className="page_home">
                <div style={{width:"100%",height:"100%",background:"white"}}>
                    <MyCard className="card_main" title={<PageTitle title="设备地图"/>}>
                        <div style={{width:"100%",height:"100%",position:"relative"}} id={"map"}>
                            <DeviceAllMap ref={this.deviceMapref} deviceList={deviceList} getVideo={this.getVideo}/>
                        </div>
                    </MyCard>
                </div>
            </div>
        );
    }

    //从Map中获取点击设备
    getVideo=(num,e)=>{
        this.showVideoLook(e,num)
    }
    showVideoLook=async(e,num)=>{
        clearInterval(this.playVideoTimer)
        message.destroy()
        const res = await reqOpenVideo(e,num)
        console.log(res)
        this.setState({
            tsSrc:videoUrl+`/${e.deviceId}_${num}`,
            playTime:0,
        },()=>{
            message.loading("视频加载中",0)
            if(res.code === 200){
                const hdVideo =document.getElementById('hdVideo');
                this.jessibuca = new window.Jessibuca({
                    container: hdVideo,
                    videoBuffer: 0, // 缓存时长
                    isResize: false,
                    text: "",
                    loadingText: "加载中",
                    debug: false,
                    showBandwidth: this.showOperateBtns, // 显示网速
                    operateBtns: {
                        fullscreen: this.showOperateBtns,
                        screenshot: this.showOperateBtns,
                        play: this.showOperateBtns,
                        audio: this.showOperateBtns,
                    },
                    forceNoOffscreen: this.forceNoOffscreen,
                    isNotMute: false,
                    useMSE: true,
                });
            }else {
                message.error("视频打开失败")
                this.setState({
                })
            }
        })
    }
    getScreenList=async()=>{
        const res = await reqGetScreenList('zoneCode='+zoneCodeConfig)
        if(res.code === 200){
            //this.map.setMarkers(res)
            return res.result
        }else {
            message.error('请求数据失败！')
        }
    }
    reGetScreenList=async ()=>{
        const res = await this.getScreenList()
        if(res){
            this.setState({
                onlineNum:res.onlineCountStatistic.onlineNum,
                offlineNum:res.onlineCountStatistic.offlineNum,
                onlineRate:res.onlineCountStatistic.onlineRate,
                humExceptionStatistic:res.humExceptionStatistic,//湿度异常设备
                offlineStatistic:res.offlineStatistic,//离线设备
                tempExceptionStatistic:res.tempExceptionStatistic,//温度异常设备
                rightList:[...res.humExceptionStatistic,...res.offlineStatistic,...res.tempExceptionStatistic],
                alarmStatistic:res.alarmStatistic,//实时预警
            })
            this.deviceMapref.current.setValue(res)
        }
    }
    reGetScreenListTask=async ()=>{
        const _that = this;
        this.startSetInterval=setInterval(async()=>{
            _that.reGetScreenList();
        },3000)
    }
}