import React, {Component} from "react";
import {Modal, Button, message, Popconfirm, Input, Col, Form, Row, Cascader, TreeSelect, Switch} from 'antd'
import MyTable from "../../common/myTable/myTable";
import {reqAddReservoir,reqGetPageReservoirList,reqDelApk,reqAddAddress,reqChangeReservoir,reqUpAddress} from "../../api/clApi";
import {PAGE_SIZE, SUCCESS} from "../../utils/constants";
import MyOssUpLoad from "../../common/Upload/ossUpload";
import PageTitle from "../../common/pageTitle";
import MyCard from "../../common/MyCard/mainCard";
import RTree from "./tree/reservoirTree";
import {regCompoundGetZone, regCompoundGetZoneChild, reqUserGetUserList} from "../../api";
import {reqGetPageDevice} from "../../api/llzApi";
const { SHOW_PARENT } = TreeSelect;
export default class  extends Component {
    state={
        apkList:[],
        cityOptions:[],
        personData:[],
        selectPersonList:[],
        lookDeviceList:[],
        changedeviceSelectList:[],
        zoneCode:"",
        selectId:""
    }
    updateReservoirForm=React.createRef()
    updateAddressForm = React.createRef()
    searchForm=React.createRef()
    changeReservoirForm = React.createRef()
    updateUpAddressForm = React.createRef()
    componentWillMount(){
        this.init()
    }
    componentDidMount() {
        this.getAllProvince()
        this.getUserList()
        this.getDeviceList(1)
    }
    setStateAsync = (state) => {
        return new Promise((resolve, reject) => {
            this.setState(state, resolve);
        });
    };

    render() {
        const {apkList,visible,visibleAddAddress,loading,cityOptions,personData,deviceList,time,
            visibleLookDevice,visibleUpAddress,changeReservoirVisible}=this.state
        const tProps = {
            treeData:personData,
            value: this.state.selectPersonList,
            onChange: this.onChangePersonList,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: '请选择区域负责人',
            style: {
                width: '100%',
            },
        };
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <div className="page_home">
                <MyCard className="card_main" title={<PageTitle title="水库管理"/>}>
                    <div style={{display:"flex",justifyContent:"flex-end",margin:"10px 0"}}>
                        <Button type="primary" onClick={this.showAddAddress} style={{marginRight:"20px"}}>添加区域</Button>
                        <Button type="danger" onClick={this.showUpAddress} style={{marginRight:"20px"}}>修改区域</Button>

                        <Button type="primary" onClick={this.showSet} style={{marginRight:"20px"}}>添加水库</Button>
                        {/*<Form ref={this.searchForm}>*/}
                        {/*    <Row>*/}
                        {/*        <Col>*/}
                        {/*            <Form.Item style={{marginBottom: 10}} name="name">*/}
                        {/*                <Input style={{width: 150}} placeholder="水库名称"/>*/}
                        {/*            </Form.Item>*/}
                        {/*        </Col>*/}
                        {/*        <Col >*/}
                        {/*            <Button type="primary" onClick={()=>{this.getReservoirList(1)}}>搜索</Button>*/}
                        {/*            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</Form>*/}
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div style={{width:"25%"}}>
                            <RTree parent={this}/>
                        </div>
                        <div style={{width:"74%"}}>
                            <MyTable column={this.apkColumn} data={apkList} pageFun={(c)=>{this.getReservoirList(c)}}/>
                        </div>
                    </div>
                </MyCard>
                <Modal
                    forceRender={true}
                    title="添加区域信息"
                    okText='确定'
                    cancelText='关闭'
                    visible={visibleAddAddress}
                    confirmLoading={loading}
                    onOk={async()=>{
                        let validateValues = await this.updateAddressForm.current.validateFields();
                        let updateReservoir = this.state.updateReservoir

                        let values = this.updateAddressForm.current.getFieldsValue()

                        values.zoneCode = values.zoneCode[values.zoneCode.length-1]
                        if(this.state.selectId){
                            values.fid = this.state.selectId
                        }
                        console.log(values)
                        await this.addAddress(values)
                        this.setState({visibleAddAddress:false,updateReservoir:{}})

                    }}
                    onCancel={()=>{this.setState({visibleAddAddress:false,updateReservoir:{}})}}>
                    <Form ref={this.updateAddressForm} style={{width:'100%'}} {...formItemLayout}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="区域名称" name="name" rules={[{required: true, message: '请填写名称'}]}>
                                    <Input  placeholder="区域名称"/>
                                </Form.Item>
                                <Form.Item  style={{marginBottom:10}} label="管理人员" name="adminIds">
                                    <TreeSelect {...tProps} />
                                </Form.Item>
                                <Form.Item label="所属区域" name={'zoneCode'} style={{"margin":"0px 10px 0px 0px"}} rules={[{required: true, message: '请选择地区！'}]}>
                                    <Cascader
                                        options={cityOptions}
                                        loadData={this.cascaderLoader}
                                        changeOnSelect
                                    />
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal
                    forceRender={true}
                    title="修改区域信息"
                    okText='确定'
                    cancelText='关闭'
                    visible={visibleUpAddress}
                    confirmLoading={loading}
                    key={time+"1"}
                    onOk={async()=>{
                        let validateValues = await this.updateUpAddressForm.current.validateFields();
                        let updateReservoir = this.state.updateReservoir

                        let values = this.updateUpAddressForm.current.getFieldsValue()
                        values.id = this.state.selectInfo.id
                        console.log(values)
                        await this.upAddress(values)
                        this.setState({visibleUpAddress:false,updateReservoir:{}})

                    }}
                    onCancel={()=>{this.setState({visibleUpAddress:false,updateReservoir:{}})}}>
                    <Form ref={this.updateUpAddressForm} style={{width:'100%'}}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="区域名称" name="name">
                                    <Input  placeholder="区域名称"/>
                                </Form.Item>
                                <Form.Item  style={{marginBottom:10}} label="管理人员" name="adminIds" >
                                    <TreeSelect {...tProps} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal
                    forceRender={true}
                    title="添加水库信息"
                    okText='确定'
                    cancelText='关闭'
                    visible={visible}
                    confirmLoading={loading}
                    width={1000}
                    onOk={async()=>{
                        let validateValues = await this.updateReservoirForm.current.validateFields();
                        let updateReservoir = this.state.updateReservoir
                        if(!validateValues.name){
                            message.info("请输入水库名称")
                            return
                        }
                        let values = this.updateReservoirForm.current.getFieldsValue()
                        values.areaId = this.state.selectId
                        values.deviceIds = this.state.deviceSelectList
                        console.log(values)
                        await this.addReservoir(values)
                        this.setState({visible:false,updateReservoir:{}})

                    }}
                    onCancel={()=>{this.setState({visible:false,updateReservoir:{}})}}>
                    <Form ref={this.updateReservoirForm} style={{width:'100%'}}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="水库名称" name="name" >
                                    <Input  placeholder="请输入水库名称" rules={[{required: true, message: '请输入水库名称！'}]}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <h2>选择水库下的设备</h2>
                        <MyTable selection={true} selectionFun={this.selectionFun} preserveSelectedRowKeys={true}  column={this.deviceColumn} data={deviceList} pageFun={(c)=>{this.getDeviceList(c)}}/>
                    </Form>
                </Modal>

                <Modal
                    forceRender={true}
                    title="修改水库信息"
                    okText='确定'
                    cancelText='关闭'
                    visible={changeReservoirVisible}
                    confirmLoading={loading}
                    width={1000}
                    key={this.state.time}
                    onOk={async()=>{
                        let validateValues = await this.changeReservoirForm.current.validateFields();
                        let changeReservoir = this.state.changeReservoir
                        console.log(changeReservoir)
                        let values = this.changeReservoirForm.current.getFieldsValue()
                        values.id = changeReservoir.id
                        values.areaId = this.state.selectId
                        values.deviceIds = this.state.deviceSelectList
                        console.log(values)
                        await this.changeReservoir(values)
                        this.setState({changeReservoirVisible:false,changeReservoir:{},deviceSelectList:[]})


                    }}
                    onCancel={()=>{this.setState({changeReservoirVisible:false,changeReservoir:{},deviceSelectList:[]})}}>
                    <Form ref={this.changeReservoirForm} style={{width:'100%'}}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item  style={{marginBottom:10}} label="水库名称" name="name" >
                                    <Input  placeholder="请输入水库名称" rules={[{required: true, message: '请输入水库名称！'}]}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <h2>选择水库下的设备</h2>
                        <MyTable selection={true} selectionFun={this.selectionFun}
                                 selectRooms={this.state.changedeviceSelectList}
                                 preserveSelectedRowKeys={true}  column={this.deviceColumn} data={deviceList} pageFun={(c)=>{this.getDeviceList(c)}}/>
                    </Form>
                </Modal>

                <Modal
                    forceRender={true}
                    title="设备列表信息"
                    okText='确定'
                    cancelText='关闭'
                    visible={visibleLookDevice}
                    width={1000}
                    onOk={async()=>{
                        this.setState({visibleLookDevice:false})
                    }}
                    onCancel={()=>{this.setState({visibleLookDevice:false})}}>
                    <div style={{maxHeight:"500px",overflowY:"auto"}}>
                        {this.state.lookDeviceList.map((item,index)=>{
                            return <div key={index+"device"} style={{margin:"10px"}}>设备号：{item.deviceId}</div>
                        })}
                    </div>
                </Modal>
            </div>
        );
    }
    /**
     *接口请求函数
     */
    getReservoirList=async(pageNum)=>{
        this.setState({tableLoading:true});
        // let value = this.searchForm.current.getFieldsValue();
        let value ={}
        if(this.state.selectId){
            value.areaId = this.state.selectId
        }
        const res = await reqGetPageReservoirList(pageNum,PAGE_SIZE,value?value:{});
        this.setState({tableLoading:false});
        if(res && res.code &&res.code==200){
            res.pageNum=pageNum;
            this.setState({apkList:res,pageNum:pageNum})
        }else{
            message.error("网络繁忙")
        }
    };
    //获取账号列表
    getUserList=async ()=>{

        const res =await reqUserGetUserList({pageNum:1,pageSize:1000});

        if(res.code===200){
            let newList = this.listInitKey(res.results)
            this.setState({
                personData:newList,
            });
        }
    }
    //获取设备列表
    getDeviceList=async(pageNum)=>{
        this.setState({tableLoading:true});
        const res = await reqGetPageDevice(pageNum,PAGE_SIZE,{});
        this.setState({tableLoading:false});
        if(res && res.code &&res.code===200){
            res.pageNum=pageNum;
            this.setState({deviceList:res,pageNum:pageNum})
        }
    };
    selectionFun=(selectedRowKeys)=>{
        this.setState({deviceSelectList:selectedRowKeys})
    }
    listInitKey = (list)=>{
        let mlist = list
        mlist.forEach(item =>{
            if(item.status==1){
                item.key = item.id;
                item.title = item.name+"-"+item.role.roleDesc;
                item.value = item.id;
            }
        })
        return mlist
    }
    //添加区域
    showAddAddress=()=>{
        console.log(this.state.selectId)
        this.setState({
            visibleAddAddress:true,
        })
    }
    //添加区域
    addAddress=async (value)=>{
        const _that = this
        this.setState({loading:true})
        let res = await reqAddAddress(value)
        this.setState({loading:false})
        console.log(res)
        if(res.code==200){
            message.success("创建成功")
            setTimeout(()=>{
                window.location.reload()
            },1500)
            // this.getReservoirList()
        }else{
            message.error("创建失败")
        }
    }
    //修改区域
    showUpAddress=()=>{
        console.log(this.state.selectId,this.state.selectInfo)
        const {selectInfo}=this.state
        if(selectInfo){
            this.setState({
                visibleUpAddress:true,
            },()=>{
                this.updateUpAddressForm.current.setFieldsValue({
                    name:selectInfo.name,
                    adminIds:selectInfo.adminIdList
                });
            })
        }else{
            message.info("请先选择左侧区域")
        }

    }
    upAddress=async (value)=>{
        const _that = this
        this.setState({loading:true})
        let res = await reqUpAddress(value)
        this.setState({loading:false})
        console.log(res)
        if(res.code==200){
            message.success("修改成功")
            setTimeout(()=>{
                window.location.reload()
            },1500)
            // this.getReservoirList()
        }else{
            message.error("修改失败")
        }
    }

    //添加水库
    showSet=()=>{
        console.log(this.state.selectId)
        if(this.state.selectId){
            this.setState({
                time:new Date(),
                visible:true,
            })
        }else{
            message.info("请先在左侧选择区域")
        }

    }
    //添加水库
    addReservoir=async (value)=>{
        const _that = this
        this.setState({loading:true})
        let res = await reqAddReservoir(value)
        this.setState({loading:false})
        console.log(res)
        if(res.code==200){
            message.success("创建成功")
            // setTimeout(()=>{
            //     window.location.reload()
            // },1500)
            this.getReservoirList(1)
        }else{
            message.error("创建失败")
        }
    }
    //修改水库
    changeReservoir=async (value)=>{
        const _that = this
        this.setState({loading:true})
        let res = await reqChangeReservoir(value)
        this.setState({loading:false})
        console.log(res)
        if(res.code==200){
            message.success("创建成功")
            // setTimeout(()=>{
            //     window.location.reload()
            // },1500)
            this.getReservoirList(1)
        }else{
            message.error("创建失败")
        }
    }


    // del=async(e)=>{
    //     console.log(e)
    //
    //     let res = await reqDelApk(e.id)
    //     if(res.code==200){
    //         this.getList()
    //         message.success("删除成功")
    //     }else{
    //         message.error("删除失败")
    //     }
    // }

    //函数
    cascaderLoader=async (selectedOptions)=>{
        const selectedOption = selectedOptions[selectedOptions.length-1];
        selectedOption.loading=true;
        const {cityOptions} = this.state;
        //加载效果
        await this.setState({
            cityOptions: cityOptions
        });
        selectedOption.children = await this.getZoneChildList(selectedOption.value);
        selectedOption.loading=false
        //刷新数据
        this.setState({
            cityOptions: cityOptions
        });
    }
    //获取所有省
    getAllProvince=async ()=>{
        let res = await regCompoundGetZone();
        if(res && res.code && res.code === SUCCESS){
            const options = this.getYunFirstOptions(res.results);
            this.setState({
                cityOptions: options
            })
        }else{
            message.error("获取省市区列表失败");
        }
    }
    getYunFirstOptions=(list)=>{
        let options = [];
        list.forEach(item => {
            options.push({
                value: item.code,
                label: item.name,
                isLeaf: false,
            })
        })
        return options;
    }
    getZoneChildList=async (zoneCode)=>{
        let res = await regCompoundGetZoneChild({code:zoneCode});
        if(res && res.code && res.code===SUCCESS){
            return res.results.map(zone => {
                return {
                    value: zone.code,
                    label: zone.name,
                    isLeaf: zone.level===3,
                }
            })
        }else{
            message.error("网络错误");
        }
    }
    //选区域负责人
    onChangePersonList=(value)=>{
        console.log('onChange ', value);
    }
    init=()=>{
        this.apkColumn = [
            {
                title:"水库名称",
                dataIndex: 'name',
                key: 'name',
                align:"center"
            },
            {
                title:"查看设备",
                dataIndex: 'deviceList',
                key: 'look',
                align:"center",
                width:'150px',
                render:(e)=>{
                    return <Button onClick={()=>{
                        this.setState({visibleLookDevice:true,lookDeviceList:e})
                    }}>查看</Button>
                }
            },
            {
                title:"修改",
                key: 'update',
                align:"center",
                width:'150px',
                render:(e)=>{
                    return <Button onClick={()=>{
                        this.setState({changeReservoirVisible:true,changeReservoir:e,changedeviceSelectList:[],time:new Date()},()=>{
                            this.changeReservoirForm.current.setFieldsValue({
                                name:e.name,
                            })
                            let arr = []
                            e.deviceList.map((item,index)=>{
                                arr.push(item.id)
                            })
                            this.setState({
                                changedeviceSelectList:arr
                            })
                        })
                    }}>修改</Button>
                }
            },

            // {
            //     title:"删除",
            //     dataIndex: '',
            //     key: 'delete',
            //     align:"center",
            //     render:(e)=>{
            //         return <Popconfirm
            //             title="确定删除该版本?"
            //             onConfirm={this.del.bind(this,e)}
            //             okText="Yes"
            //             cancelText="No"
            //         >
            //             <Button type="danger">删除</Button>
            //         </Popconfirm>
            //     }
            // },


        ]
        this.deviceColumn=[
            {
                title: '设备序号',
                dataIndex: 'deviceId',
                key: 'deviceId',
                align: 'center',
                fixed:'left'
            },
            {
                title: '设备类型',
                dataIndex: 'deviceType',
                key: 'deviceType',
                align: 'center',
            },
            {
                title: '位置描述',
                dataIndex: 'locationDesc',
                key: 'locationDesc',
                align: 'center',
            },

        ]
    }
}
