import React, {Component} from 'react'
import {Col,Row,Modal,Tree,Button, Card, Checkbox, Descriptions, Form, Input, message} from 'antd'
import {reqUpdateOrganization,reqAddOrganization,reqGetAllPrimaryOrganizationTree} from '../../api'
import {SUCCESS} from "../../utils/constants";
import DutyTree from "./DutyTree";
import {DownOutlined} from "@ant-design/icons";
import {treeFind} from "../../utils/aladdin";

const {TextArea} = Input;
const CheckboxGroup = Checkbox.Group;

export default class DutyManage extends Component {
    constructor(props) {
        super(props);
        this.state={
            manageStatus:1,
            oldRoleName:null,
            oldRoleDesc:null,
            oldRoleId:null,
            visible:false,//更新
            visible2:false,//新增
            loading:true,
            show:false,
            count:0,
            uploading:false,
            selectNode:{},
            selectNodeList:[],
            //treeSelect的值
            permissionTree:[],
            treeSelectValue:[],
            oldTreeSelectValue:[],
            updateTreeSelectValue:[],
            //Modal里面的受控组件属性
            updateRoleName:'',
            updateRoleDesc:'',
            roleName:'',
            roleDesc:'',
            schoolList:[],
            selectSchoolList:[],
            schoolTree:[],
            showSchool:false,
            schoolOptions:[],
            schoolMap: {},
            updateSchoolIds:[],
        }
        this.newOrganizationForm = React.createRef();
        this.oldOrganizationForm = React.createRef();
        this.departmentRef = React.createRef();
        this.schoolRef = React.createRef();
    }
    componentDidMount(){
        this.getAllSchool();
    }

    render() {
        const _that = this;
        const {updateSchoolIds,schoolMap,schoolOptions,showSchool,schoolTree,schoolList, selectSchoolList,selectNode,selectNodeList} = this.state;
        const contentList = {
            schoolModal:<Modal
                width={800}
                title="关联学校"
                visible={showSchool}
                onOk={this.onChangeSchool}
                onCancel={() => {
                    this.setState({showSchool: false})
                }}
            >
                <Form style={{width:'100%'}} ref={this.schoolRef}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={"first"} style={{border:"1px solid grey", minHeight:'400px',maxHeight:'600px', overflow:'auto'}}>
                                <Tree
                                    treeData={[
                                        {
                                            title: '所有学校',
                                            name: '所有学校',
                                            key: '-1',
                                            children: schoolTree
                                        }
                                    ]}
                                    showLine={true}
                                    switcherIcon={<DownOutlined />}
                                    onSelect={this.selectSchool}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"second"} style={{border:"1px solid grey", minHeight:'400px',maxHeight:'600px', overflow:'auto'}}>
                                <CheckboxGroup
                                    style={{margin:'5px'}}
                                    options={schoolOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        };
        return (
            <div className={"page_home"}>
                <Card
                    className={"card_main"}
                    title={<span style={{fontSize: 20, letterSpacing: 5, fontWeight: 600}}>架构管理</span>}
                >
                    <div style={{height:'100%',display:'flex'}}>
                        <div style={{width:'20%',height:'100%',margin:10}}>
                            <DutyTree
                                ref={this.departmentRef}
                                parent = {this}
                            />
                        </div>
                        <div style={{height:'100%',width:'85%',margin:10}}>
                            {this.state.manageStatus===1?
                                <div>
                                    <div>
                                        <Button type="primary"
                                                onClick={this.showAddPrimaryDepartment}
                                                style={{margin:"15px 10px "}}
                                                disabled={selectNode.level > 0 ? true:false}>
                                            新增上级组织
                                        </Button>
                                        <Button type="primary"
                                                onClick={this.showAddSubDepartment}
                                                style={{margin:"15px 10px "}}
                                                disabled={selectNode.level > 0 ? false:true}>
                                            新增下级组织
                                        </Button>
                                        <Button type="primary"
                                                onClick={this.updateOrganization}
                                                style={{margin:"15px 10px "}}
                                                disabled={selectNode.level > 0 ? false:true}>
                                            保存
                                        </Button>
                                    </div>
                                    {this.state.selectNode.level?
                                        <div style={{width:"50%"}}>
                                            <Form ref={this.oldOrganizationForm} initialValues={selectNode}>
                                                <Descriptions size="small" bordered column={1}>
                                                    <Descriptions.Item label="上级组织">{this.getFatherDepartmentName(selectNodeList)}</Descriptions.Item>
                                                    <Descriptions.Item label="组织名称">
                                                        <Form.Item name="name">
                                                            <Input placeholder="请输入组织名称"/>
                                                        </Form.Item>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="描述">
                                                        <Form.Item name="desc"  style={{margin: "10px 0px 10px 0px"}}>
                                                            <Input.TextArea maxLength={500} />
                                                        </Form.Item>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="学校列表">
                                                        <div>
                                                            {selectNode.relativeOrganizations
                                                                    ?selectNode.relativeOrganizations.map((id)=>{return (<p key={id}>-{schoolMap[id]}</p>)})
                                                                :null}
                                                        </div>
                                                        <Button type={'primary'} style={{float:'right'}} onClick={()=>{_that.setState({showSchool:true})}}>修改</Button>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Form>
                                        </div>
                                        :null}
                                </div>
                                :null}
                            {this.state.manageStatus===2?
                                <div>
                                    <div>
                                        <Button type="primary"
                                                onClick={() => this.return2manage(1)}
                                                style={{margin:"15px 10px "}}
                                        >
                                            返回
                                        </Button>
                                        <Button type="primary"
                                                onClick={this.addOrganization}
                                                style={{margin:"15px 10px "}}
                                        >
                                            保存
                                        </Button>
                                    </div>
                                    <div style={{width:"50%"}}>
                                        <Form ref={this.newOrganizationForm}>
                                            <Descriptions size="small" bordered column={1}>
                                                <Descriptions.Item label="上级部门">{this.getFatherDepartmentName(this.state.selectNodeList)}</Descriptions.Item>
                                                <Descriptions.Item label="部门名称">
                                                    <Form.Item name="name">
                                                        <Input placeholder="请输入部门名称"/>
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="描述">
                                                    <Form.Item name="desc"  style={{margin: "10px 0px 10px 0px"}}>
                                                        <Input.TextArea maxLength={500} />
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="学校列表">
                                                    <div>
                                                        {updateSchoolIds?updateSchoolIds
                                                                .map((id)=>{return (<p key={id}>-{schoolMap[id]}</p>)})
                                                            :null}
                                                    </div>
                                                    <Button type={'primary'} style={{float:'right'}} onClick={()=>{_that.setState({showSchool:true})}}>添加</Button>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Form>
                                    </div>
                                </div>
                                :null
                            }
                            {this.state.manageStatus===3?
                                <div>
                                    <div>
                                        <Button type="primary"
                                                onClick={() => this.return2manage(1)}
                                                style={{margin:"15px 10px "}}
                                        >
                                            返回
                                        </Button>
                                        <Button type="primary"
                                                onClick={this.addOrganization}
                                                style={{margin:"15px 10px "}}
                                        >
                                            保存
                                        </Button>
                                    </div>
                                    <div style={{width:"50%"}}>
                                        <Form ref={this.newOrganizationForm}>
                                            <Descriptions size="small" bordered column={1}>
                                                <Descriptions.Item label="上级部门">{selectNode.name}</Descriptions.Item>
                                                <Descriptions.Item label="部门名称">
                                                    <Form.Item name="name">
                                                        <Input placeholder="请输入部门名称"/>
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="描述">
                                                    <Form.Item name="desc"  style={{margin: "10px 0px 10px 0px"}}>
                                                        <TextArea maxLength={500} />
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="学校列表">
                                                    <div>
                                                        {updateSchoolIds?updateSchoolIds
                                                                .map((id)=>{return (<p key={id}>-{schoolMap[id]}</p>)})
                                                            :null}
                                                    </div>
                                                    <Button type={'primary'} style={{float:'right'}} onClick={()=>{_that.setState({showSchool:true})}}>添加</Button>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Form>
                                    </div>
                                </div>
                                :null
                            }
                            {contentList['schoolModal']}
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

    //函数
    //获取所有学校
    getAllSchool=async ()=>{
        const res = await reqGetAllPrimaryOrganizationTree({type:2});
        if(res && res.code && res.code === SUCCESS){
            const schoolList = this.list2CheckOptions(res.results);
            let schoolMap = {};
            const schoolTree = this.listInitKey(res.results, schoolMap);
            this.setState({
                schoolList: schoolList,
                schoolTree: schoolTree,
                schoolMap: schoolMap,
            })
        }else{
            message.error("获取学校列表失败");
        }
    }
    list2CheckOptions=(list)=>{
        if(list){
            return list.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
        }
        return [];
    }
    //显示新增初级部门
    showAddPrimaryDepartment=()=>{
        this.setState({
            manageStatus:2
        })
    }
    //显示新增下级部门
    showAddSubDepartment=()=>{
        this.setState({
            manageStatus:3
        })
    }
    getFatherDepartmentName=(selectNodeList)=>{
        if(selectNodeList && selectNodeList.length>2){
            return selectNodeList[selectNodeList.length-2].name;
        }else{
            return "";
        }
    }
    addOrganization= async () => {
        const form = this.newOrganizationForm.current;
        let param = form.getFieldsValue();
        const {selectNode, updateSchoolIds} = this.state;
        if (selectNode && selectNode.id) {
            param.fid = selectNode.id;
        }
        param.relativeOrganizations = updateSchoolIds;
        param.zoneCode=500000;
        param.type=1;
        let res = await reqAddOrganization(param);
        if(res.code === SUCCESS){
            message.success("添加部门成功");
            this.freshMainPage();
        }else{
            message.error("添加部门失败");
        }
    }
    //刷新组织架构树，返回展示页
    freshMainPage=()=>{
        this.departmentRef.current.getAllOrganizationPrimary();
        this.setState({
            manageStatus:1,
        })
    }
    return2manage=(status)=>{
        this.setState({
            manageStatus: status
        })
    }
    updateOrganization=async ()=>{
        const form = this.oldOrganizationForm.current;
        let param = form.getFieldsValue();
        const {selectNode} = this.state;
        if (this.state.selectNode && this.state.selectNode.id) {
            param.id = this.state.selectNode.id;
        }else{
            message.error("修改失败");
            return;
        }
        param.relativeOrganizations = selectNode.relativeOrganizations;
        console.log("update", param);
        let res = await reqUpdateOrganization(param);
        if(res.code === SUCCESS){
            message.success("修改成功");
        }else{
            message.error("修改失败");
        }
    }
    onTreeSelect=(node, nodeList)=>{
        const form = this.oldOrganizationForm.current;
        const {relativeOrganizations} = node;
        if(relativeOrganizations && relativeOrganizations.length>0){
            //关联学校的回显


        }
        this.setState({
            selectNodeList: nodeList,
            selectNode: node
        }, ()=>{
            if(form){
                form.setFieldsValue(node)
            }
        })
    }
    listInitKey = (list, map)=>{
        let mlist = list
        mlist.forEach(item =>{
            map[item.id] = item.fullName;
            item.key = item.id;
            item.title = item.name;
            item.value = item.id;
            if(item.children){
                item.children =  this.listInitKey(item.children, map)
            }
        })
        return mlist
    }
    onChangeSchool=()=>{
        const form = this.schoolRef.current;
        if(form){
            const values = form.getFieldsValue();
            const {selectNode} = this.state;
            if(selectNode && values){
                selectNode.relativeOrganizations = values.second;
            }
            this.setState({
                updateSchoolIds: values && values.second?values.second:[],
                showSchool: false,
                selectNode:selectNode,
            })
        }
    }
    selectSchool=(selectKey)=>{
        console.log("selectKey", selectKey);
        const {schoolTree} = this.state;
        const nodes = [{
            label: '所有学校',
            title: '所有学校',
            value: '-1',
            id: '-1',
            children: schoolTree
        }];
        const selectNode = treeFind(nodes, selectKey[0], 'id', 'children');
        if(selectNode && selectNode.children){
            const children = selectNode.children.map((child) => {return {
                label:child.name,
                value:child.id,
            }})
            this.setState({
                schoolOptions: children
            })
        }
    }
}