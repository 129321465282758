/**
 *
 */
import React, {Component} from 'react'
import {Select,Card,Button,Form,Row,Col,Input,message} from 'antd'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import guanbi from '../../assets/Img/叉叉.png'
import {PAGE_SIZE} from '../../utils/constants'
import {getAlarmMessageList,getAlarmMessageDetail} from '../../api/clApi'
const {Option}  = Select
var timer=""
export default class  extends Component {
    state={
        pageState:'alarmList',
        imgList:[],
        detailList:[],
        loading:true
    }
    searchForm=React.createRef()

    componentWillMount(){
        this.initColumn()
    }
    componentWillUnmount(){
        clearInterval(timer)
    }

    componentDidMount(){
        this.getList(1)
    }

    render() {
        const {pageState,show,imgList,imgNum,visible,alarmData,alarmDetail,loading} = this.state
        const  contentList={
            card_title:<PageTitle title="报警信息"  />,
            card_extra:<div style={{display:'flex'}}>
                <Form ref={this.searchForm}>
                    <Row gutter={15}>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="deviceId">
                                <Input style={{width: 150}} placeholder="设备编号"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="matchStatus">
                                <Select style={{width: 150}} placeholder="匹配状态">
                                    <Option value="invalid">无效</Option>
                                    <Option value="preparing">匹配中</Option>
                                    <Option value="full-match">完全匹配</Option>
                                    <Option value="part-match"> 部分匹配</Option>
                                    <Option value="full-fail"> 全不匹配</Option>
                                    <Option value="success"> 匹配成功</Option>
                                    <Option value="fail"> 匹配失败</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="alarmStatus">
                                <Select style={{width: 150}} placeholder="报警状态">
                                    <Option value="alarming">报警中</Option>
                                    <Option value="full-alarmed">全部已报警</Option>
                                    <Option value="part-alarmed">部分报警</Option>
                                    <Option value="full-failed">全部报警失败</Option>
                                    <Option value="none">无负责人</Option>
                                    <Option value="calling">拨号中</Option>
                                    <Option value="called-fail">拨号失败</Option>
                                    <Option value="success">报警成功</Option>
                                    <Option value="node-heard">无人接听</Option>
                                    <Option value="ignore">忽略本次报警</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col >
                            <Button type="primary" onClick={()=>{this.getList(1)}}>搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            alarmList:<MyTable column={this.column} data={alarmData} loading={loading} pageFun={(c)=>{this.getList(c,PAGE_SIZE)}}/>
        }
        const  detailList={
            card_title:<PageTitle returnFun={()=>{this.goBack()}} type="return" title="详情"/>,
            alarmList:<MyTable column={this.detailColumn} data={alarmDetail}  loading={loading} />
        }
        return (
            <div className="page_home">
                <Card className="card_main" extra={contentList['card_extra']} title={visible?detailList['card_title']:contentList['card_title']}>
                    {/*{*/}
                        {/*visible?<Button onClick={this.goBack}>返回</Button>:""*/}
                    {/*}*/}
                    {
                        visible?detailList[pageState]:contentList[pageState]
                    }
                    {/*人脸照片、现场照片*/}
                    <div style={{position:"fixed",top:"30%",left:"40%",display:show?"block":"none",backgroundColor: "white",padding:"10px 20px",borderRadius:"10px"}}>
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <div></div>
                            <div onClick={this.close} style={{fontSize:"18px",cursor:"pointer"}}>
                                <img src={guanbi} alt="" style={{width:"20px",height:"20px",marginBottom:"5px"}}/>
                            </div>
                        </div>
                        <div style={{background:"#f1f2f6",padding:"15px",minHeight:"100px",minWidth:"100px"}}>
                            <img src={imgList[imgNum]} alt="" id="img" style={{width:"300px",height:"300px"}}/>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
    goBack=()=>{
        this.setState({
            visible:false
        })
    }


    //获取报警信息
    getList=async(page)=>{
        this.setState({loading:true})
        let value = this.searchForm.current.getFieldsValue();
        let res =await getAlarmMessageList(page-1,PAGE_SIZE,value?value:{})
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            console.log(res)
            res.pageNum=page
            this.setState({alarmData:res, pageNum:page})
        }


    }
    //获取详情
    getDetail=async(e)=>{
        let res =await getAlarmMessageDetail(e)
        if(res.code==200){
            console.log(res)
            this.setState({
                alarmDetail:res,
                loading:false
            })
        }

    }

    lookDetail=(e)=>{
        console.log(e.id)
        this.setState({
            visible:true,
            detailId:e.id,
            loading:true
        },()=>{
            this.getDetail(e)
        })

    }

    close=()=>{
        clearInterval(timer)
        this.setState({
            show:false
        })
    }

    look=(e)=>{
        console.log(e)
        if(e.length==0){
            message.info("暂无图片")
        }else{
            this.setState({
                show:true,
                imgList:e,
                imgNum:0
            },()=>{
                timer = setInterval(()=>{
                    console.log(this.state.imgNum)
                    this.setState({
                        imgNum:this.state.imgNum==this.state.imgList.length-1?this.state.imgList.length-1:this.state.imgNum+1
                    })
                },1000)
            })
        }

    }
    /**
     * 初始化数据
     */
    initColumn = ()=>{
        this.column=[
            // {
            //     title: 'id',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: '设备序号',
                dataIndex: 'deviceId',
                key: 'deviceId',
            },
            {
                title: '匹配状态',
                dataIndex: 'matchStatusStr',
                key: 'matchStatusStr',

            },

            {
                title: '报警状态',
                dataIndex: 'alarmStatusStr',
                key: 'alarmStatusStr',

            },

            {
                title: '上报时间',
                dataIndex: 'reportedAtStr',
                key: 'reportedAtStr',

            },
            {
                title: '发生时间',
                dataIndex: 'createdAtStr',
                key: 'createdAtStr',

            },
            {
                title: '详情',
                dataIndex: '',
                key: 'detail',
                render:(e)=>{
                    return <Button type="primary" onClick={this.lookDetail.bind(this,e)}>查看</Button>
                }
            },
            {
                title: '人脸照片',
                dataIndex: 'liveFaces',
                key: 'liveFaces',
                render:(e)=>{
                    return <Button type="primary" onClick={this.look.bind(this,e)}>查看</Button>
                }
            },
            {
                title: '现场照片',
                dataIndex: 'livePics',
                key: 'livePics',
                render:(e)=>{
                    return <Button type="primary" onClick={this.look.bind(this,e)}>查看</Button>
                }
            },

        ]
        this.detailColumn=[
            // {
            //     title: 'Id',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            // {
            //     title: '报警Id',
            //     dataIndex: 'reportId',
            //     key: 'reportId',
            // },
            // {
            //     title: '匹配Id',
            //     dataIndex: 'matchUserId',
            //     key: 'matchUserId',
            // },
            {
                title: '匹配状态',
                dataIndex: 'matchStatusStr',
                key: 'matchStatusStr',
            },
            {
                title: '所属域组',
                dataIndex: 'matchGroupName',
                key: 'matchGroupName',
            },
            {
                title: '所属单位',
                dataIndex: 'matchGroupUnitName',
                key: 'matchGroupUnitName',
            },
            {
                title: '匹配对象',
                dataIndex: 'matchUserName',
                key: 'matchUserName',
            },
            {
                title: '匹配失败原因',
                dataIndex: 'failReason',
                key: 'failReason',
            },
            {
                title: '报警状态',
                dataIndex: 'alarmStatusStr',
                key: 'alarmStatusStr',
            },
            {
                title: '照片',
                dataIndex: 'faceUrl',
                key: 'faceUrl',
                render:(e)=>{
                    return  <div><img src={e} alt="" style={{width:"80px",height:"80px"}}/></div>
                }
            },

        ]
    }
}