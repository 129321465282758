/**
 *
 */
import React, {Component} from 'react'
import { Upload, message, Button } from 'antd';
import { UploadOutlined,PlusOutlined} from '@ant-design/icons';
import {reqCompoundGetAuth} from '../../api/index'
export default class  extends Component {
    constructor(props){
        super(props)
        this.state={
            OSSData: {},
            fileList:[],
        }
    }
    async componentDidMount() {
        await this.init();
    }
    render() {
        const {fileList} = this.state
        const { type,count } = this.props;
        const props = {
            name: 'file',
            fileList: fileList,
            action: this.state.OSSData.host,
            listType:type==='image'?"picture-card":'text',
            accept:type==='image'?"image/*":".docx,.pdf,.doc,.xls,.xlsx,.zip",
            onChange: this.onChange,
            onRemove: this.onRemove,
            transformFile: this.transformFile,
            data: this.getExtraData,
            beforeUpload: this.beforeUpload,
        };
        return (
            <Upload {...props}>
                {type==='image'?
                    count?fileList.length<count?<div><PlusOutlined/><div style={{ marginTop: 8 }}>上传图片</div></div>:null:<div><PlusOutlined/><div style={{ marginTop: 8 }}>上传图片</div></div>
                    :
                    count?fileList.length<count?<Button icon={<UploadOutlined />}>点击上传文件</Button>:null:<Button icon={<UploadOutlined />}>点击上传文件</Button>
                }
            </Upload>
        )
    }

    init = async () => {
        const res = await reqCompoundGetAuth();
        console.log("reqCompoundGetAuth",res)
        if (res.code && res.code === 200) {
            this.setState({OSSData:res.result})
        }
        const{value} = this.props
        if(value){
            this.setState({fileList:value})
        }
    };

    onChange = ({ file,fileList }) => {
        console.log(file)
        const { onChange,type ,fileNameFun,count} = this.props;
        const { OSSData } = this.state;
        if(file.status==='done') {
            message.success('上传文件成功！')
            file = fileList[fileList.length - 1];
            file.url = OSSData.host + '/' + file.originFileObj.url;
        }
        if (onChange) {
            let urlList = this.getUrlList(fileList)
            this.setState({fileList:[...fileList]})
            if(urlList.length===1 && type!=='image'){
                onChange(urlList[0],   fileList,OSSData.host)
            }else {
                onChange([...urlList], fileList,OSSData.host);
            }
        }
        if(fileNameFun && count===1){
            fileNameFun(fileList[0].name)
        }
    };

    onRemove = file => {
        const { value, onChange } = this.state;

        // const files = value.filter(v => v.url !== file.url);
        // if (onChange) {
        //     onChange(files);
        // }
    };

    transformFile = file => {
        const { OSSData } = this.state;
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        file.url = OSSData.dir + filename;
        return file;
    };

    getExtraData = file => {
        const { OSSData } = this.state;
        return {
            key: file.url,
            name:OSSData.name,
            OSSAccessKeyId: OSSData.accessid,
            policy: OSSData.policy,
            success_action_status: 200,
            signature: OSSData.signature,
        };
    };

    beforeUpload = async () => {
        const { OSSData } = this.state;
        const expire = OSSData.expire * 1000;
        if (expire < Date.now()) {
            await this.init();
        }
        return true;
    };

    getUrlList=(list)=> {
        let urlList = []
        list.map(item => {
            if(item.url)
                urlList.push(item.url)
        })
        return urlList
    }

    remove = (url)=>{
        let {fileList} = this.state;
        if(url && fileList && fileList.length>0){
            fileList = fileList.filter(item => item.url !== url);
        }
        this.setState({
            fileList: fileList
        })
    }
}