/**
 *
 */
import React, {Component} from 'react'
import {Table} from 'antd'
import {PAGE_SIZE} from '../../utils/constants'
export default class  extends Component {
    state={
        loading:false
    };
    render() {
        const {data,column,style,pageSize,keyField} = this.props;
        const {loading} = this.state;
        return (
                <Table
                    size={'small'}
                    bordered
                    style={{width:'100%',...style}}
                    loading={loading}
                    columns = {column}
                    dataSource = {Array.isArray(data)?data:[]}
                    rowKey={(record) => {
                        if(keyField){
                            return record[keyField];
                        }else  if(record.userId){
                            return record.userId
                        }else if(record.id){
                            return record.id
                        }
                    } }
                    pagination={{
                    pageSize:pageSize?pageSize:PAGE_SIZE,
                    showSizeChanger:false,
                    // current:item.data.pageNum?item.data.pageNum:0,
                    total: Array.isArray(data)?data.length:0,
                    showTotal:()=> {  //设置显示一共几条数据
                        let count = Array.isArray(data)?data.length:0;
                        return '共 ' + count  + ' 条数据';
                    },
                    onChange:(current)=> {
                        this.setState({loading:true})
                        setTimeout(()=>{
                            this.setState({loading:false})
                        },100)

                    },
                }}/>
        )
    }
}