/**
 * Created by 12728 on 2020/10/12.
 */
const menuList = [
    {
        title: '报警中心',
        icon: 'laptop',
        key: '/alarm',
        permission: 'warning',
        subs: [
            {key: '/alarm/alarmMessage', title: '报警信息', icon: '', permission: 'warning-info'},
            {key: '/alarm/alarmManage', title: '报警管理', icon: '', permission: 'warning-manage'},
            {key: '/alarm/alarmDial', title: '拨号记录', icon: '', permission: 'warning-manage'},
            {key: '/alarm/alarmNote', title: '短信记录', icon: '', permission: 'warning-manage'},
        ]
    },
    {
        title: '人员管理',
        icon: 'laptop',
        key: '/person',
        permission: 'people',
        subs: [
            {key: '/person/face', title: '人脸管理', icon: '', permission: 'people-face',},
            {key: '/person/principal', title: '责任人管理', icon: '', permission: 'people-inCharge',},
        ]
    },
    {
        title: '功能管理',
        icon: 'laptop',
        key: '/function',
        permission: 'function',
        subs: [
            {key: '/function/camera', title: '摄像头管理', icon: '', permission: 'function-device'},
            {key: '/function/device', title: '设备管理', icon: '', permission: 'function-device'},
            {key: '/function/safeDevice', title: '安防设备管理', icon: '', permission: 'function-safeDevice'},
            {key: '/function/deviceMap', title: '设备地图', icon: '', permission: 'function-deviceMap'},
            {key: '/function/deviceLog', title: '设备异常日志', icon: '', permission: 'function-deviceLog'},
            {key: '/function/version', title: '版本管理', icon: '', permission: 'function-version'},
            {key: '/function/monitoring', title: '视频监控', icon: '', permission: 'function-monitoring'},
        ]
    },
    {
        title: '水库管理',
        icon: 'laptop',
        key: '/reservoirAll',
        permission: 'reservoirAll',
        subs:[
            {key: '/function/reservoir', title: '区域/水库管理', icon: '', permission: 'reservoirAll-reservoir'},
        ]
    },
    {
        title: '数据管理',
        icon: 'laptop',
        key: '/data',
        permission: 'data',
        subs: [
            {key: '/data/college', title: '院校管理', icon: '', permission: 'data-college'},
        ]
    },
    {
        title: '后台管理',
        icon: 'laptop',
        key: '/backend',
        permission: 'backend',
        subs: [
            {key: '/backend/organization', title: '组织机构', icon: '', permission: 'backend-organization'},
            {key: '/backend/user', title: '账号管理', icon: '', permission: 'backend-user'},
            {key: '/backend/role', title: '角色管理', icon: '', permission: 'backend-role'},
        ]
    },

]
export default menuList