/**
 * Created by 12728 on 2020/10/12.
 */
// const URL = "http://smart-gateway.safone.ltd:13200"
// const URL = "http://drown-front.safone.ltd:9500"//测试
// const URL = "http://localhost:13000"
// const URL = "http://www.safephone.cn:17009"
const URL = ""
// const URL = "http://192.168.0.61:13000"

// const URL_SAFONE = "http://192.168.0.61:8599"
const URL_SAFONE = ""
//线上
// const URL = "http://drown-gateway.safephone.cn"
const videoUrl = "ws://61.128.208.26:18082"
const provinceTitle = "赛丰"
const zoneCodeConfig = "530000"
// const URL = "http://192.168.0.124:13200"
//const EMERGENCY_URL = "http://192.168.0.70:13000"
// const URL = "http://192.168.0.117:13000"
// const videoUrl = "ws://192.168.0.240:18082"
export {URL,URL_SAFONE,videoUrl,provinceTitle,zoneCodeConfig}
