/**
 *
 */
import React, {Component} from 'react'
import ReactEcharts from 'echarts-for-react';
import {getStaticsUserFaces} from'../../../api/clApi'
export default class Waring extends Component {
    constructor(props) {
        super(props);
        this.state={
            havingFaceUserCount:0,
            noFaceUserCount:0
        }
    }
    componentDidMount() {
        this.getData()
    }

    getData=async ()=>{
        let res = await getStaticsUserFaces()
        if(res.code==200){
            this.setState({
                havingFaceUserCount:res.result.havingFaceUserCount,
                noFaceUserCount:res.result.noFaceUserCount,
                allCount:res.result.userTotalCount
            })
        }

        console.log(res)
    }


    getOptions=()=>{
        const {havingFaceUserCount,noFaceUserCount}=this.state
        return {
            title: {
                text: '人脸数据统计',
                left: 'center',
                textStyle:{
                    color:"white"
                }
            },
            color:['rgb(111,220,134)','rgb(59,133,233)'],
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
            },
            series: [
                {
                    name: '人脸数据',
                    type: 'pie',
                    radius: '50%',
                    data: [
                        {value: havingFaceUserCount, name: '已录入'},
                        {value: noFaceUserCount, name: '未录入'},
                    ],
                }
            ]
        };

    }

    render() {
        return (
            <div style={{width:'100%',height:'85%'}}>
                <ReactEcharts style={{width:'100%',height:'100%'}} option={this.getOptions()}/>
                <div style={{color:"white",textAlign:"center"}}>总人数：{this.state.allCount}</div>
            </div>
        )
    }
}