import React, {Component} from 'react'
import {message} from 'antd'
import "./selectProvince.less"
import * as echarts from 'echarts';
import {
    TitleComponent,
    GridComponent,
    DataZoomComponent
} from 'echarts/components';
import chinaJson from '../../common/Json/china.json'
echarts.registerMap('china', chinaJson);
// 注册必须的组件
echarts.use(
    [TitleComponent, GridComponent, DataZoomComponent]
);
echarts.registerMap('china', chinaJson);

export default class  extends Component {
    state={
        dataList:[
            {
                name: "南海诸岛",
                selected:false,
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '北京',
                selected:false,
                cp:[116.4551,40.2539],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '天津',
                selected:false,
                cp:[117.4219,39.4189],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '上海',
                selected:false,
                cp:[121.4648,31.2891],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '重庆',
                selected:false,
                cp:[107.7539,30.1904],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '河北',
                selected:false,
                cp:[115.4004,37.9688],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '河南',
                selected:false,
                cp:[113.4668,33.8818],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '云南',
                selected:false,
                cp:[101.8652,25.1807]
            },
            {
                name: '辽宁',
                selected:false,
                cp:[122.3438,41.0889],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '黑龙江',
                selected:false,
                cp:[128.1445,48.5156],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '湖南',
                selected:false,
                cp:[111.5332,27.3779],
            },
            {
                name: '安徽',
                selected:false,
                cp:[117.2461,32.0361],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '山东',
                selected:false,
                cp:[118.7402,36.4307],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '新疆',
                selected:false,
                cp:[84.9023, 41.748],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '江苏',
                selected:false,
                cp:[120.0586,32.915],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '浙江',
                selected:false,
                cp:[120.498,29.0918],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '江西',
                selected:false,
                cp:[116.0156,27.29],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '湖北',
                selected:false,
                cp:[112.2363,31.1572],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '广西',
                selected:false,
                cp:[108.2813,23.6426],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '甘肃',
                selected:false,
                cp:[95.7129,40.166],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '山西',
                selected:false,
                "cp":[112.4121,37.6611],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '内蒙古',
                selected:false,
                cp:[117.5977, 44.3408],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '陕西',
                selected:false,
                cp:[109.5996,35.6396],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '吉林',
                selected:false,
                cp:[126.4746,43.5938],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '福建',
                selected:false,
                cp:[118.3008,25.9277],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '贵州',
                selected:false,
                cp:[106.6113,26.9385],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '广东',
                selected:false,
                cp:[113.4668,22.8076],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '青海',
                selected:false,
                cp:[96.2402, 35.4199],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '西藏',
                selected:false,
                cp:[88.7695, 31.6846],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '四川',
                selected:false,
                cp:[102.9199, 30.1904],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '宁夏',
                selected:false,
                cp:[105.9961,37.3096],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '海南',
                selected:false,
                isVideo:false,
                cp:[109.9512,19.2041],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '台湾',
                selected:false,
                isVideo:false,
                cp:[121.0254,23.5986],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '香港',
                selected:false,
                isVideo:false,
                cp:[114.2578,22.3242],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            },
            {
                name: '澳门',
                selected:false,
                isVideo:false,
                cp:[113.5547,22.1484],
                itemStyle: {
                    areaColor: '#6F798D',
                    opacity: 0.5
                }
            }
        ],
        provinceUrl:{
            "云南":"http://drown-front.safephone.cn/",
        }
    }
    componentDidMount() {
        const {dataList,provinceUrl}=this.state
        const _that = this
        var myChart = echarts.init(document.getElementById('Map'));

        var mapName = 'china'
        var geoCoordMap = {};
        var mapFeatures = echarts.getMap(mapName).geoJson.features;

        mapFeatures.forEach(function(v) {
            // 地区名称
            var name = v.properties.name;
            // 地区经纬度
            geoCoordMap[name] = v.properties.cp;

        });
        var option = {
            geo: {
                aspectScale: 0.75,
                map: "china",
                roam: false,
                layoutCenter: ["50%", "51.5%"], //地图位置
                layoutSize: '100%',
                zoom: 1.1,
                label: {
                    normal: {
                        show: true,
                        fontSize: 12,
                        color: "#ED8A44",
                        fontWeight:600
                    },
                },
                regions: dataList,
                itemStyle: {
                    areaColor: '#0072E3',
                    borderWidth: 0.8, //设置外层边框
                    borderColor: '#fff38c',
                },
                select:{
                    label:{
                        color:"#ED8A44",
                        show:true
                    },
                    itemStyle: {
                        borderColor: '#D9281E',
                        borderWidth: 1,
                        shadowColor: '#B96232',
                        shadowOffsetY: 3,
                        shadowBlur: 10,
                        areaColor: '#D9281E',
                    }
                },
                emphasis: {
                    label:{
                        color:"#ED8A44",
                        show:true
                    },
                    itemStyle: {
                        borderColor: '#D9281E',
                        borderWidth: 1,
                        shadowColor: '#B96232',
                        shadowOffsetY: 3,
                        shadowBlur: 10,
                        areaColor: '#D9281E',
                    }
                },
                scaleLimit:{
                    max:3,min:1
                },
                zlevel: 2
            },

        };
        // 绘制图表
        myChart.setOption(option);
        myChart.on('click', function (params) {
            console.log(params.name)
            if(provinceUrl[params.name]){
                // window.location.href=provinceUrl[params.name]
                sessionStorage.setItem("province",params.name)
                _that.props.history.push("./login")
            }else{
                message.info("暂未部署")
            }
        });
    }

    render() {
        return(
            <div className="selectProvince" >
                <h1 style={{color:"white",fontSize:"34px"}}>防溺卫士后台管理系统</h1>
                <h2 style={{color:"white"}}>请先选择所属省份</h2>
                <div id="Map" style={{ width:"60%", height:"100%"}}></div>
            </div>
        ) ;
    }
}