/**
 *
 */
import React, {Component} from 'react'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import Mtree from '../../common/myTree/MyTree'
import {reqCollegeGetPrincipalPull,reqWaringMgmtAdd,reqCollegeGetPagePrincipal,reqWaringMgmtGetWaringMgmt,reqWaringMgmtGetPageWaringMgmt,reqCollegeGetPonsibles} from '../../api/clApi'
import moment from 'moment'
import { Card,Form,InputNumber,Layout, Menu , Select,Tree,TreeSelect,Popconfirm, message,Table,DatePicker,Dropdown,Button,Modal,Input,Radio} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import guanbi from '../../assets/Img/叉叉.png'
import {PAGE_SIZE} from '../../utils/constants'
const {Option} = Select;
export default class  extends Component {
    constructor(props) {
        super(props);
        this.state={
            pageState:'alarmList',
            groupList:[],
            groupUnitList:[],
            prinList:[],
            loading:false,
            addWaringMgmtConfirmLoading:false,
            addDevice:true,
            treeData:[],
            principalList:[],
            selectNode:{},
            selectNodeFather:{},
            selectNodeList:[],
            waring:[],
            groupName:"选择组",
            groupUnitName:"选择单位",
            prinName:"选择负责人",

        }
        this.addWaringMgmtForm=React.createRef()
    }
    componentWillMount(){
        this.getPrin(1,{})
        this.initColumn()
    }
    render() {
        const {pageState,show,waring,loading} = this.state
        const  contentList={
            card_title:<PageTitle title="报警管理"/>,
            alarmList:<MyTable column={this.column} data={waring} loading={loading}
                               // pageFun={(c)=>{this.getPrin(c,{})}}
            />
        }
        const addWaringMgmt=async (value)=>{
            this.setState({addWaringMgmtConfirmLoading:true})
            let seletNodeId = this.state.selectNode.id
            value.organizationId = seletNodeId
            await this.addWaringMgmt(value)
            this.setState({addWaringMgmtConfirmLoading:false},()=>{
                this.setState({addWaringMgmtVisible:false})
            })
            this.getWaringMgmtList(0,{organizationId:seletNodeId})
        }
        return (
            <div className="page_home">
                <Card className="card_main" title={contentList['card_title']}>

                    <div style={{display:"flex"}}>
                        <div style={{width:"13%"}}>
                            <Mtree parent={this}/>
                        </div>
                        <div style={{width:"84%",marginLeft:"20px"}}>
                            {this.state.selectNode.id?<Button type="primary" onClick={this.handleAddWaringMgmt} style={{marginBottom:"20px"}} >添加报警设置</Button>:null}
                            {contentList[pageState]}
                        </div>

                    </div>


                    <Modal
                        confirmLoading={this.state.addWaringMgmtConfirmLoading}
                        forceRender={true}
                        title="添加报警信息"
                        okText="确定"
                        cancelText="取消"
                        visible={this.state.addWaringMgmtVisible}
                        onOk={this.addWaringMgmtHandleOk}
                        onCancel={this.addWaringMgmtHandleCancel}
                        key={this.state.key}
                    >
                        <Form   ref={this.addWaringMgmtForm}  onFinish={addWaringMgmt} >
                            <Form.Item label="报警人等级" name="tierSort" rules={[{required: true, message: '报警人等级不能为空！'}]}>
                                <InputNumber style={{width:'100%'}} placeholder='数字越低，报警等级越高' min={0} max={100}/>
                            </Form.Item>
                            <Form.Item label="选择责任人" name="responsibleId" rules={[{required: true, message: '请选择责任人！'}]}>
                                <Select placeholder='请选择责任人'
                                        showSearch
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {/*{this.state.principalList.map(item =>{return <Option value={item.id} key={item.id+'_pr'}>{item.name}/{item.mobileNum}</Option>})}*/}
                                    {this.state.principalList.map(item =>{return <Option value={item.key} key={item.id+'_pr'}>{item.value}</Option>})}
                                </Select>
                            </Form.Item>
                        </Form>


                    </Modal>

                    <div style={{position:"fixed",top:"20%",left:"45%",display:show?"block":"none",backgroundColor: "white",padding:"10px 20px",borderRadius:"10px"}}>
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <div></div>
                            <div onClick={this.close} style={{fontSize:"18px",cursor:"pointer"}}>
                                <img src={guanbi} alt="" style={{width:"20px",height:"20px",marginBottom:"5px"}}/>
                            </div>
                        </div>
                        <div style={{background:"#f1f2f6",padding:"15px"}}>
                            {/* <img src={this.state.bigImg} alt="" id="img" style={{width:"200px",height:"200px"}}/> */}
                            {this.state.groupUnitList.map((item,index)=>{
                                return  <div key={index+"img"} style={{marginTop:"10px",width:"150px"}}>
                                    {item.unitName}
                                </div>
                            })}
                        </div>

                    </div>
                </Card>
            </div>
        )
    }
    /**
     * 接口调用函数
     */

    /**分页查询负责人列表*/
        //获取负责人
    getPrin=async (pageNum,value)=>{
        ///api/safe/device/principal/page.json?page=0&size=10
        this.setState({loading:true})
        const res = await reqWaringMgmtGetPageWaringMgmt(pageNum,PAGE_SIZE,value);
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            res.pageNum=pageNum;
            this.setState({waring:res, pageNum:pageNum})
        }


    }

    /**分页查询负责人列表*/
        //查询组织下所有负责人
    getPagePrin=async (pageNum,value)=>{
        const res = await reqCollegeGetPagePrincipal(pageNum,10,value);
        if(res&&res.results)
            this.setState({
                principalList:res.results,
            })
    }
    /**查询分级负责人下拉框*/
    getPrincipalPull= async(pageNum,value)=>{
        const res = await reqCollegeGetPrincipalPull(pageNum,10,value);
        let selectNode = this.state.selectNode
        if(res&&res.result)
            this.setState({
                principalList:res.result[selectNode.primaryId],
            })
    }

    /**查询某个组织下的报警负责人*/
    getWaringMgmtList=async (pageNum,value)=>{
        this.setState({loading:true})
        const res = await reqWaringMgmtGetWaringMgmt(pageNum,10,value);
        this.setState({loading:false})
        if(res.code===200){
            this.setState({
                waring:res,
                loading:false
            })
        }
    }

    /**创建分级报警负责人*/
    addWaringMgmt=async (value)=>{
        const res = await reqWaringMgmtAdd(value)
        if(res&&res.code===200){
            message.success('添加分级报警负责人成功！')
        }else {
            message.error('创建分级报警负责人失败！')
        }
    }
    initColumn=()=>{
        this.column= [
            // {
            //     title: '序号',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title:"报警等级",
                dataIndex: 'tierSort',
                key: 'tierSort',
            },
            {
                title:"负责人",
                dataIndex: 'responsibleName',
                key: 'responsibleName',
            },
            {
                title:"所属组织",
                dataIndex: 'organizationFullName',
                key: 'organizationFullName',
            },
            {
                title:"创建时间",
                dataIndex: 'createdAtStr',
                key: 'createdAtStr',
            },


            // {
            //     title:"组名",
            //     dataIndex: 'groupName',
            //     key: 'groupName',
            // },
            // {
            //     title:"单位",
            //     dataIndex: 'groupUnitName',
            //     key: 'groupUnitName',
            // },


            // {
            //     title:"删除",
            //     dataIndex: '',
            //     key: 'delete',
            //     render:(e)=>{
            //         return <Popconfirm
            //                     title="确定删除该设备?"
            //                     onConfirm={this.del.bind(this,e)}
            //                     onCancel={cancel}
            //                     okText="Yes"
            //                     cancelText="No"
            //                 >
            //                     <Button type="danger">删除</Button>
            //                 </Popconfirm>
            //     }
            // },

        ]
    }


    handleAddWaringMgmt=async ()=>{
        await this.getPrincipalPull(0,{organizationId:this.state.selectNode.id})
        this.addWaringMgmtForm.current.resetFields()
        this.setState({addWaringMgmtVisible:true})
    }
    addWaringMgmtHandleOk=async ()=>{
        this.addWaringMgmtForm.current.submit()
    }

    addWaringMgmtHandleCancel=()=>{
        this.setState({addWaringMgmtVisible:false})
    }

    setSelectNode=(selectNode)=>{
        console.log('selectNode',selectNode)
        this.setState({
            selectNode
        })
    }

    setSelectFatherNode=(selectNodeFather)=>{
        console.log('selectNodeFather',selectNodeFather)
        this.setState({
            selectNodeFather
        })
    }


    close=()=>{
        this.setState({
            show:false
        })
    }





}