/**
 * Created by 12728 on 2020/11/2.
 * 校级安全接口
 */
import  {HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete,FetchApi} from './AxiosUtils'
import {message} from 'antd'
import {URL} from '../config/urlConf/urlConf'




//获取设备列表
export const reqGetPageDevice=(pageNum,pageSize,value)=>{
    let e = pageHelper(pageNum,pageSize,value)
    return HttpClientget(URL+`/api/safe/device/page.json`,e,{});
}

//修改设备信息
export const reqUpdateDevice = (value)=>HttpClientput(URL+"/api/safe/device/device.json",value,{})

//修改设备位置
export const reqUpdateDevicePos = (value)=>{
    const form = new FormData
    form.append("pos",value.pos)
    form.append("locationDesc", value.locationDesc)
    return HttpClientput(URL+`/api/safe/device/position/${value.id}`,form,{"Content-Type":"application/x-www-form-urlencoded;charset=UTF-8"})
}


//查询当前管理员下的人脸数据
export const  reqGetUserFaceDevice = (value)=>HttpClientget(URL+"/api/safe/cms/users",value,{})

//创建用户基础信息
export const reqAddUser = (value)=> HttpClientpost(URL+`/api/safe/cms/user.json`,value,{});

//更新用户基础信息
export const reqUpdateUser = (value)=> HttpClientpost(URL+`/api/safe/cms/user/update`,value,{});

//添加用户人脸
export const reqAddUserFace = (value)=> HttpClientpost(URL+`/api/safe/cms/user/face/add.json`,value,{});
/**
 * 对象转get请求后缀
 * @param param 对象
 * @param key 前置参数
 * @param encode
 * @returns {string}
 */
const urlEncode = (param, key, encode)=> {
    if(param===null) return '';
    let paramStr = '';
    const t = typeof (param);
    if (t === 'string' || t === 'number' || t === 'boolean') {
        paramStr += '&' + key + '=' + ((encode===null||encode) ? encodeURIComponent(param) : param);
    } else {
        for (let i in param) {
            const k = key && key !== null ?  key + (param instanceof Array ? '[' + i + ']' : '.' + i) :i;
            paramStr += urlEncode(param[i], k, encode);
        }
    }
    return paramStr;
};

const pageHelper=(pageNum,pageSize,value)=>{
    let values
    if(value instanceof Object){
        values = value
        values.page = pageNum
        values.size = pageSize
    }else {
        values = {}
        values.page = pageNum
        values.size = pageSize
    }
    return values
}

const pager=(pageNum,pageSize,value)=>{
    let pager = {
        pageNum:pageNum,
        pageSize:pageSize,
    }
    value.pager = pager
    return value
}

