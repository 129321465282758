/**
 *
 */
import React, {Component} from 'react'

import { Table,Select, InputNumber ,Radio,Checkbox,Input,Form,Transfer,Modal,Row,Col,message,Card,Tree, Button,Switch } from 'antd';
import {reqCollegeGetAllPrimary}from '../../api/clApi'


export default class Mtree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treeData:[],
            deviceList: [],
            addDepartmentVisible: false,
            updateDepartmentVisible: false,
            addGroupVisible: false,
            updateGroupVisible: false,
            addRoomVisible: false,
            RadioValue: 1,
            selectNode: {},
            compoundTotal: 0,
            pageNum: 0,

        }

    }

    componentDidMount() {
        this.getAllPrimary(2);
    }


    //获取所有最高级组织架构
    getAllPrimary=async (type)=>{
        const res = await reqCollegeGetAllPrimary(type);
        if(res && res.results && res.results.length>0){
            this.convertValueFiled(res.results);
            this.setState({
                treeData:res.results
            })
            this.props.parent.setState({
                treeData:[...res.results]
            })
        }
    }
    convertValueFiled = (list)=>{
        list.forEach((e) => {
            e.value = e.id;
            e.title = e.name;
            e.key = e.id;
            if(e.children && e.children.length>0){
                this.convertValueFiled(e.children)
            }
        })
    }


    render() {
        return (
            <div style={{width:'100%',height:'100%'}}>
                <Card size="small" title="组织架构"   style={{ padding:0,width:'100%',height:'800px',overflowY:"scroll" }}>
                    <Tree  defaultExpandedKeys={[-1]}  selectedKeys={[this.state.selectNode.key]} titleRender={this.titleRender.bind(this)} showLine={true}  onSelect={this.onSelect} treeData={this.state.treeData}  style={{width:200}}/>
                </Card>
            </div>


        )
    }
    /**
     *{
        title: 'parent 1',
        key: '0-0',
        icon: <CarryOutOutlined />,
        children: []
     * }
     * @param node
     * @returns {XML}
     */
    titleRender=(node)=> {
        return(<div style={{width:100,whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',cursor:'pointer'}}>{node.title}</div>)
    }
    onSelect =  (selectedKeys, info) => {
        if(!info.node.children || info.node.children.length<1) {
            this.props.parent.setSelectNode(info.node)
            let primaryName = info.node.primaryName
            let fatherNode = this.state.treeData.find(item => item.name === primaryName)
            this.props.parent.setSelectFatherNode(fatherNode)
            this.props.parent.getWaringMgmtList(0, {organizationId: info.node.id})
            this.setState({
                selectNode: info.node
            })
        }
    };

}
