/**
 *
 */
import React, {Component} from 'react'

import {
    Table,
    Select,
    InputNumber,
    Radio,
    Checkbox,
    Input,
    Form,
    Transfer,
    Modal,
    Row,
    Col,
    message,
    Card,
    Tree,
    Button,
    Switch,
    Cascader
} from 'antd';
import {reqGetReservoirTree}from '../../../api/clApi'
import {regCompoundGetZone, regCompoundGetZoneChild} from "../../../api";
import {SUCCESS} from "../../../utils/constants";


export default class Mtree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treeData:[],
            deviceList: [],
            addDepartmentVisible: false,
            updateDepartmentVisible: false,
            addGroupVisible: false,
            updateGroupVisible: false,
            addRoomVisible: false,
            RadioValue: 1,
            selectNode: {},
            compoundTotal: 0,
            pageNum: 0,
            cityOptions:[],


        }

    }

    componentDidMount() {
        this.getAllPrimary("");
        this.getAllProvince()
    }


    //获取树结构
    getAllPrimary=async (value)=>{
        const res = await reqGetReservoirTree(value);
        if(res.code==200){
            let list = this.listInitKey(res.results);
            console.log(list)
            const obj = {
                title: '所有组织架构',
                name: '所有组织架构',
                key: 1,
                level: 0,
                children: [],
                id:1

            }
            obj.children.push(...list)
            console.log(obj)
            this.setState({treeData:[obj]})
            // this.props.parent.setState({
            //     treeData:[...res.results]
            // })
        }
    }
    listInitKey = (list)=>{
        let mlist = list
        mlist.forEach(item =>{
            item.key = item.id;
            item.title = item.name;
            item.value = item.id;
            if(item.childList){
                item.children =  this.listInitKey(item.childList)
            }
        })
        return mlist
    }


    render() {
        const {cityOptions}=this.state
        return (
            <div style={{width:'100%',height:'100%'}}>
                <Card size="small" title="区域显示"   style={{ padding:0,width:'100%',height:"100%",overflowY:"scroll" }}>
                    <Cascader
                        options={cityOptions}
                        loadData={this.cascaderLoader}
                        onChange={this.onChangePro}
                        changeOnSelect
                        style={{width:"100%"}}
                    />
                    <Tree  defaultExpandedKeys={[1]}  selectedKeys={[this.state.selectNode.key]} titleRender={this.titleRender.bind(this)}   onSelect={this.onSelect} treeData={this.state.treeData}  style={{width:200}}/>
                </Card>
            </div>


        )
    }
    /**
     *{
        title: 'parent 1',
        key: '0-0',
        icon: <CarryOutOutlined />,
        children: []
     * }
     * @param node
     * @returns {XML}
     */
    titleRender=(node)=> {
        return(<div style={{maxWidth:'150px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',cursor:'pointer'}}>{node.title}</div>)
    }
    onSelect =  (selectedKeys, info) => {
        if(info.node.id!=1){
            this.setState({selectNode:info.node})
            console.log(selectedKeys, info)
            this.props.parent.setState({
                selectId:info.node.id,
                selectFid:info.node.fid,
                selectInfo:info.node,
                time:new Date(),
            },()=>{
                this.props.parent.getReservoirList(1)
            })
        }

    };
    onChangePro=async (e)=>{
        console.log(e)
        let zoneCode = e[e.length-1]
        if(e.length!=0){
            this.getAllPrimary(zoneCode)
        }
        console.log(zoneCode)
    }
    //函数
    cascaderLoader=async (selectedOptions)=>{
        const selectedOption = selectedOptions[selectedOptions.length-1];
        selectedOption.loading=true;
        const {cityOptions} = this.state;
        //加载效果
        await this.setState({
            cityOptions: cityOptions
        });
        selectedOption.children = await this.getZoneChildList(selectedOption.value);
        selectedOption.loading=false
        //刷新数据
        this.setState({
            cityOptions: cityOptions
        });
    }
    //获取所有省
    getAllProvince=async ()=>{
        let res = await regCompoundGetZone();
        if(res && res.code && res.code === SUCCESS){
            const options = this.getYunFirstOptions(res.results);
            this.setState({
                cityOptions: options
            })
        }else{
            message.error("获取省市区列表失败");
        }
    }
    getYunFirstOptions=(list)=>{
        let options = [];
        list.forEach(item => {
            options.push({
                value: item.code,
                label: item.name,
                isLeaf: false,
            })
        })
        return options;
    }
    getZoneChildList=async (zoneCode)=>{
        let res = await regCompoundGetZoneChild({code:zoneCode});
        if(res && res.code && res.code===SUCCESS){
            return res.results.map(zone => {
                return {
                    value: zone.code,
                    label: zone.name,
                    isLeaf: zone.level===3,
                }
            })
        }else{
            message.error("网络错误");
        }
    }

}
