import React, {Component} from 'react'
import {Button, Card, Col, Form, Input, message, Modal, Row, Table} from 'antd'
import {reqDeleteDevice, reqGetAllPrimaryOrganizationTree, reqGetDeviceList,} from "../../api/safoneIndex"
import {SUCCESS} from "../../utils/constants";
import sessionUtils from "../../utils/sessionUtils";
import moment from "moment";

const PAGE_SIZE = 10;
const extra_map = {
    "table_info": "card_main",
}
const header_map = {
    "table_info": "摄像头管理",
}
const {confirm} = Modal;
let player = "";
let deviceListTimer = null;
let recordTimer = null;
export default class Camera extends Component {
    constructor(props) {
        super(props);
        this.state={
            pageStatus:"table_info",
            dataList: [],
            dataTotal:0,
            pageNum: 1,
            buttonLoading: false,
            tableLoading: false,
            organizationOptions:[],
            loadingBtn:"",
            tableParam:{},
        }
        this.searchFormRef = React.createRef();
        this.addFormRef = React.createRef();
        this.updateFormRef = React.createRef();
        this.recordFormRef = React.createRef();
    }
    componentWillMount(){
        if(!deviceListTimer){
            deviceListTimer = setInterval(()=>{this.getDeviceList()}, 30000);
        }
        this.initColumns();
    }
    componentWillUnmount() {
        if(deviceListTimer){
            clearInterval(deviceListTimer);
        }
        if(recordTimer){
            clearInterval(recordTimer);
        }
    }
    componentDidMount(){
        this.getDeviceList({pageNum:1});
        this.getOrganizationTree();
    }
    render() {
        const {pageStatus,loading,dataList,dataTotal,pageNum} = this.state;
        const _that = this;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 6 },
        };
        const contentList = {
            table_info: <Table  columns={this.column}
                                bordered
                                rowKey={record => record.id}
                                dataSource={dataList}
                                loading={loading}
                                scroll={{x:1500}}
                                rowClassName={(record, index) => {
                                    let className = 'light-row';
                                    if (index % 2 === 1) className = 'dark-row';
                                    return className;
                                }}
                                pagination={{
                                    total: dataTotal,
                                    showTotal: function () {  //设置显示一共几条数据
                                        return '共 ' + dataTotal + ' 条数据';
                                    },
                                    current:pageNum,
                                    pageSize: PAGE_SIZE,
                                    onChange(current) {
                                        const form = _that.searchFormRef.current;
                                        let param = form.getFieldsValue();
                                        param.pageNum = current;
                                        _that.getDeviceList(param)
                                    },
                                }}
            />,
            card_main:<div style={{margin:"10px 0",position:"relative"}}>
                <Form ref={this.searchFormRef}
                      style={{margin:"0 15px ", display:"inline-block"}}
                      name="advanced_search"
                      className="ant-advanced-search-form"
                      onFinish={this.searchOnFinish} >
                    <Row gutter={24}>
                        <Col >
                            <Form.Item  style={{marginBottom:10}} name="deviceId"  >
                                <Input style={{width:150}} placeholder="设备序列号"/>
                            </Form.Item>
                        </Col>
                        <Col >
                            <Button type="primary" htmlType="submit">搜索</Button>
                            <Button style={{ margin: '0 8px' }} onClick={()=>{this.searchFormRef.current.resetFields()}}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            record_extra:<div style={{margin:"10px 0",position:"relative"}}>
                <Form ref={this.recordFormRef}
                      style={{margin:"0 15px ", display:"inline-block"}}
                      name="advanced_search"
                      className="ant-advanced-search-form"
                >
                    <Row gutter={24}>
                        <Col >
                            <Button onClick={()=>{this.setState({pageStatus:"table_info",loadingBtn:"",})}} style={{marginLeft:"10px"}}>返回</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
        }
        return (<div className={"page_home"}>
            <Card
                className={"card_main"}
                title={<span style={{fontSize: 20, letterSpacing: 5, fontWeight: 600}}>{header_map[pageStatus]}</span>}
                extra={contentList[extra_map[pageStatus]]}
            >
                <div style={{height:'100%',display:'flex'}}>
                    <div style={{height:'100%',width:'95%',margin:10}}>
                        {contentList[pageStatus]}
                    </div>
                </div>
            </Card>
        </div>);
    }

    initColumns =()=>{
        this.column = [
            {
                title:"序号",
                dataIndex: 'id',
                key: 'id',
                fixed: "left",
                width: 70,
                render:(text, record, index)=>{
                    return index+1;
                }
            },
            {
                title:"设备号",
                dataIndex: 'deviceId',
                key: 'deviceId',
                fixed: "left",
                width: 150,
            },
            {
                title:"心跳时间",
                dataIndex: 'keepaliveTime',
                key: 'keepaliveTime',
                width: 150,
            },
            {
                title:"在线状态",
                dataIndex: 'online',
                key: 'online',
                fixed: "right",
                width: 70,
                render: (text, record)=>{
                    const {keepaliveTime} = record;
                    let timeOver = false;
                    if(keepaliveTime){
                        timeOver = moment(keepaliveTime).isBefore(moment().subtract(30, 'minutes'))
                    }
                    return text === 1 && !timeOver ? <span>在线</span>:<span style={{color:"red"}}>离线</span>
                }
            },
            {
                title:"操作",
                dataIndex: '',
                key: 'delete',
                fixed: "right",
                width: 200,
                render:(e, record)=>{
                    return <div>
                        <Button type='link' onClick={()=>{this.goVideo(record)}}>播放</Button>
                        <Button type='link' onClick={()=>{this.goGBRecord(record)}}>国标回看</Button>
                        <Button type='link' onClick={()=>{this.showDeleteConfirm(record)}}>删除</Button>
                    </div>
                }
            },
        ]
    }

    getDeviceList=async (param)=>{
        param = param ? param : this.state.tableParam;
        param.pageNum = param.pageNum?param.pageNum:1;
        this.setState({tableLoading: true, tableParam: param});
        const res = await reqGetDeviceList(param);
        this.setState({tableLoading: false});
        if(res.code === SUCCESS){
            this.setState({
                dataList: res.results,
                dataTotal: res.totalCount,
                pageNum: param.pageNum
            })
        }else{
            message.error("获取分组列表失败");
        }
    }

    searchOnFinish=(values)=>{
        values.pageNum = 1;
        this.getDeviceList(values);
    }

    goAlarmPage=async (device)=>{
        sessionUtils.saveDeviceInfo(device)
        window.location.href='./#/data/alarm'
    }

    showDeleteConfirm=async (record)=>{
        confirm({
            title:'删除设备',
            content: <div>
                确认删除<span style={{color:"red"}}>{record.deviceId}</span>吗？
            </div>,
            onOk:async ()=>{
                const res = await reqDeleteDevice(record.deviceId);
                if(res.code === SUCCESS){
                    message.success("删除成功");
                    this.getDeviceList({pageNum: 1});
                }else{
                    message.error("删除失败");
                    Modal.destroyAll();
                }
            }
        })
    }

    goVideo=(deviceInfo)=>{
        sessionUtils.saveDeviceInfo(deviceInfo);
        window.location.href='./#/video'
    }

    goGBRecord=async (device)=>{
        sessionUtils.saveDeviceInfo(device);
        window.location.href='./#/record'
    }

    openRecordPlayer=(url)=>{
        player.start(url);
        player.play();
    }

    getOrganizationTree=async ()=>{
        const res = await reqGetAllPrimaryOrganizationTree();
        if(res.code === SUCCESS){
            this.setState({
                organizationOptions: this.dto2treeData(res.results)
            })
        }else{
            message.error("获取代理商列表失败");
        }
    }
    dto2treeData(treeData){
        let nodes = [];
        if(treeData){
            treeData.map(e => {
                let node = {
                    "key":e.id,
                    "title":e.name,
                    "value":e.id,
                    "fullName":e.fullName
                };
                if(e.children){
                    node.children = this.dto2treeData(e.children);
                }
                nodes.push(node);
            })
        }
        return nodes;
    }

}
