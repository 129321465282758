/**
 * 部门tree
 */
import React, {Component} from 'react'

import {Card, message, Tree} from 'antd';
import {reqGetAllPrimaryOrganizationTree} from '../../api'
import LinkButton from '../../common/myButton/link-button'
import {DownOutlined} from '@ant-design/icons';

export default class DutyTree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            compoundList: [
                {
                    title: '所有组织架构',
                    name: '所有组织架构',
                    key: -1,
                    level: 0,
                    children: [
                    ]
                }
            ],
            deviceList:[],
            RadioValue:1,
            selectNode:{},
            compoundTotal: 0,
            pageNum: 0,
        }
    }

    componentDidMount() {
        this.getAllOrganizationPrimary()
    }

    getAllOrganizationPrimary = async ()=>{
        const res =await reqGetAllPrimaryOrganizationTree({type:1});
        if(res.code === 200){
            let list = this.state.compoundList
            const departmentTree = this.listInitKey(res.results)
            let departmentList = [];
            list[0].children = departmentTree
            if(departmentTree && departmentTree.length>0){
                departmentList = departmentTree;
            }
            this.setState({
                compoundList:[...list]
            },()=>{
                this.props.parent.setState({
                    departmentTree:departmentTree,
                    departmentList:departmentList
                })
            })
        }else {
            message.error('获取失败！！！')
        }
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%'}}>
                <Card size="small" title="后台组织架构"  extra={<LinkButton/>} style={{ padding:0,width:'100%',height:'800px',overflowY:"auto" }}>
                    <Tree
                        switcherIcon={<DownOutlined />}
                        defaultExpandedKeys={[-1]}
                        selectedKeys={[this.state.selectNode.key]}
                        showLine={true}
                        onSelect={this.onSelect}
                        treeData={this.state.compoundList}
                        style={{}}/>
                </Card>
            </div>
        )
    }

    listInitKey = (list)=>{
        let mlist = list
        mlist.forEach(item =>{
            item.key = item.id;
            item.title = item.name;
            item.value = item.id;
            if(item.children){
                item.children =  this.listInitKey(item.children)
            }
        })
        return mlist
    }

    getTreeSelectList = (node) => {
        const pos = node.pos;
        let arr = pos.split('-');
        let nodeList= this.state.compoundList
        let selectList = []
        for(let i=1;i<arr.length;i++){
            selectList.push( nodeList[arr[i]])
            if(nodeList[arr[i]].children){
                nodeList = nodeList[arr[i]].children
            }
        }
        return selectList
    }

    onSelect =  (selectedKeys, info) => {
        this.props.parent.onTreeSelect(info.node, this.getTreeSelectList(info.node));
        this.setState({
            selectNode: info.node
        })
    }
}