/**
 *
 */
import React, {Component} from 'react'
import ReactEcharts from 'echarts-for-react';
import {getYearAlarmData} from'../../../api/clApi'
export default class Waring extends Component {
    constructor(props) {
        super(props);
        this.state={
            dataList:[]
        }
    }
    componentDidMount() {
        this.getData()
    }

    getData=async ()=>{
        let res = await getYearAlarmData()
        let keys = Object.keys(res)
        let values = Object.values(res)
        let dataList = []
        keys.map((e,index)=>{
            let arr = [e.substring(5,10),values[index]]
            dataList.push(arr)
        })
        this.setState({
            dataList:dataList
        },()=>{
            console.log(this.state.dataList)
        })
    }

    getOptions2=()=>{
        return {
            grid:{
                top:50,
                left:50,
                x:30,
                x2:10,
                y2:38
            },
            tooltip: {
                show:true,
            },
            legend:{
                    left:'auto',
                    itemWidth:10,
                    itemHeight:10,
                    borderRadius: [0, 0, 0, 0],
                    textStyle:{
                        color:'white',
                    }
            },
            dataset: {
                source: this.state.dataList
            },
            xAxis: {
                type: 'category',
                splitLine:{show: false},
                axisLabel: {
                    color:'#1AE0FA',
                    interval: 0,
                    rotate:45,
                    // formatter:function(value)
                    // {
                    //     return value.split("").join("\n");
                    // }
                },
                axisLine:{
                    lineStyle:{
                        color:'#1AE0FA',
                    }
                },
            },
            yAxis: {
                splitLine:{show: false},
                type:'value',
                axisLabel: {
                    color:'#1AE0FA',
                },
                axisLine:{
                    lineStyle:{
                        color:'#1AE0FA',
                    }
                }
            },
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                {type: 'bar'},
            ],
            textStyle:{
                color:'#1AE0FE'
            },
            color:['red']
        };
    }


    render() {
        return (
                <ReactEcharts style={{width:'100%',height:'80%'}} option={this.getOptions2()}/>
        )
    }
}