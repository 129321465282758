import React, { Component } from 'react';
import 'moment/locale/zh-cn';
import{Image} from 'antd'
import {PNG_Header} from '../../assets'
import './index.less'
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import moment from 'moment'
import {provinceTitle} from '../../config/urlConf/urlConf'
export default class Header extends Component{
    constructor(props) {
        super(props);
        this.state={
            userName:"",
            time:moment().format('YYYY年MM月DD日'),
        }
    }
    componentDidMount() {

    }

    getEnter = (e) => {
        switch (e.index) {
            case 0:
                return {
                    rotate: 90,
                    opacity: 0,
                    y: -60,
                };
            case 10:
            case 1:
                return {
                    y: -60,
                    x: -10,
                    opacity: 0,
                };
            case 9:
            case 2:
                return {
                    y: -60,
                    x: 20,
                    opacity: 0,
                };
            case 3:
                return {
                    y: 60,
                    opacity: 0,
                };
            case 8:
            case 4:
                return {
                    x: 30,
                    opacity: 0,
                };
            case 5:
                return {
                    enter: [
                        {
                            scale: 2,
                            opacity: 0,
                            type: 'set',
                        },
                        { scale: 1.2, opacity: 1, duration: 300 },
                        { scale: 0.9, duration: 200 },
                        { scale: 1.05, duration: 150 },
                        { scale: 1, duration: 100 },
                    ],
                    leave: {
                        opacity: 0, scale: 0,
                    },
                };
            case 6:
                return {
                    scale: 0.8,
                    x: 30,
                    y: -10,
                    opacity: 0,
                };
            case 7:
                return {
                    scale: 0.8,
                    x: 30,
                    y: 10,
                    opacity: 0,
                };
            default:
                return {
                    opacity: 0,
                };
        }
    }
    // render (){
    //     return <div style={{width:"100%",display:"flex",justifyContent:"space-around"}}>
    //         <header className="header" style={{width:"100%",display:"flex",justifyContent:"space-around",padding:"10px 0",alignItems:"center"}}>
    //             <div style={{width:"33%"}}>
    //                 <p style={{textAlign:"center",fontSize:"40px",color:"white",margin:"0",fontWeight:"600"}}>视频智能分析系统</p>
    //             </div>
    //         </header>
    //     </div>
    // }

    render (){
        const {style} = this.props;
        return <div>
            <header className="header_" style={{position:'relative',...style}}>
                <img style={{width:'100%'}} src={PNG_Header}  alt=""/>
                <Texty className="header_text" enter={this.getEnter} >{provinceTitle+"防溺水平台"}</Texty>
            </header>
        </div>
    }
}