/**
 *
 */
import React, {Component} from "react";
import Particles from "react-particles-js";
import {
    PNG_LoginBack,
    PNG_LoginButtonBack,
    PNG_LoginInputBack,
    PNG_LoginPage3Back,
    PNG_LoginTitleBack,
    PNG_PasswordIcon,
    PNG_UserNameIcon
} from "../../assets";
import {Button, Form, Input, message} from "antd";
import {reqLogin} from "../../api/index.js";
import VideaoHeader from "../../components/VideoStrHeader";
import sessionUtil from "../../utils/sessionUtils";
import "./index.less";
export default class  extends Component {


    /**
     * 接口函数
     */
    login=async (e)=>{
        const res = await reqLogin(e);
        if(res.code === 200){
            sessionUtil.saveUser(e.username,res);
            // this.props.history.replace('/');
             this.props.history.push('./');

        }else {
            message.info('登陆失败！')
        }
    }

    goBack=()=>{
        // window.location.href = ""
        this.props.history.push("./selectProvince")
    }


    render() {
        return (
            <div className="login3_" >
                <img style={{position:'absolute'}} width='100%' height='100%' src={PNG_LoginPage3Back}/>
                <div  style={{position:'absolute',width:'100%'}}>
                    <VideaoHeader />
                </div>
                <Particles
                    params={{
                        "particles": {
                            "number": {
                                "value": 85,
                                "density": {
                                    "enable": true,
                                    "value_area": 1000
                                }
                            },
                            "color": {
                                "value": "rgba(0,0,0,0)"
                            },
                            "shape": {
                                "type": "polygon",
                                "stroke": {
                                    "width": 1,
                                    "color": "#ffffff"
                                },
                                "polygon": {
                                    "nb_sides": 5
                                },
                                "image": {
                                    "src": "img/github.svg",
                                    "width": 100,
                                    "height": 100
                                }
                            },
                            "opacity": {
                                "value": 0.5,
                                "random": false,
                                "anim": {
                                    "enable": false,
                                    "speed": 1,
                                    "opacity_min": 0.1,
                                    "sync": false
                                }
                            },
                            "size": {
                                "value": 1,
                                "random": true,
                                "anim": {
                                    "enable": false,
                                    "speed": 40,
                                    "size_min": 0.3,
                                    "sync": false
                                }
                            },
                            "line_linked": {
                                "enable": true,
                                "distance": 150,
                                "color": "#ffffff",
                                "opacity": 0.2,
                                "width": 1
                            },
                            "move": {
                                "enable": true,
                                "speed": 5,
                                "direction": "none",
                                "random": false,
                                "straight": false,
                                "out_mode": "out",
                                "bounce": false,
                                "attract": {
                                    "enable": false,
                                    "rotateX": 600,
                                    "rotateY": 1200
                                }
                            }
                        },
                        "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "push"
                                },
                                "resize": true
                            },
                            "modes": {
                                "grab": {
                                    "distance": 100,
                                    "line_linked": {
                                        "opacity": 0.5
                                    }
                                },
                                "bubble": {
                                    "distance": 100,
                                    "size": 40,
                                    "duration": 2,
                                    "opacity": 8,
                                    "speed": 3
                                },
                                "repulse": {
                                    "distance": 100,
                                    "duration": 0.2
                                },
                                "push": {
                                    "particles_nb": 4
                                },
                                "remove": {
                                    "particles_nb": 2
                                }
                            }
                        },
                        "retina_detect": false
                    }}
                    style={{zIndex:1,position:'absolute'}}
                    height="100%"
                    width="100%"
                />
                <div className="login3_login" >
                    <img style={{position:'absolute'}} width='100%' height='100%' src={PNG_LoginBack}/>
                    <div className="login_content">
                        <div style={{width:'100%',height:"100%",display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Form className="log" onFinish={this.login}>
                                <div className="login_title">
                                    <img style={{position: 'absolute', top: '8px'}} width='100%' height='60%' src={PNG_LoginTitleBack}/>
                                    用户登录
                                </div>
                                <div className="login_input">
                                    <img style={{position: 'absolute'}} width='100%' height='100%' src={PNG_LoginInputBack}/>
                                    <div style={{width:'80%',display:'flex',alignItems:'center',paddingTop:'5px',justifyContent:'space-around'}}>
                                        <img  width='10%' height='100%' src={PNG_UserNameIcon}/>
                                        <Form.Item style={{marginTop:'24px'}} name="username" rules={[{required: true, message: '用户名不能为空！'}]}>
                                            <Input
                                                className="input_user"
                                                placeholder='请输入账户'
                                                style={{width:"80%",background:'rgba(0,0,0,0)'}}
                                                bordered={false}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="login_input">
                                    <img style={{position: 'absolute'}} width='100%' height='100%' src={PNG_LoginInputBack}/>
                                    <div style={{width:'80%',display:'flex',alignItems:'center',paddingTop:'5px',justifyContent:'space-around'}}>
                                        <img  width='9%' height='100%' src={PNG_PasswordIcon}/>
                                        <Form.Item style={{marginTop:'24px'}} name="password" rules={[{required: true, message: '密码不能为空！'}]}>
                                            <Input
                                                className="input_password"
                                                type='password'
                                                placeholder='请输入账户'
                                                style={{width:"80%"}}
                                                bordered={false}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="login_button">
                                    <img style={{position: 'absolute'}} width='100%' height='100%' src={PNG_LoginButtonBack}/>
                                    <Button className="button" htmlType="submit" style={{width:'100%',height:"100%",fontSize:'18px',color:"white",fontWeight:600}} type="text"  >登录</Button>
                                </div>
                                {/*<div className="login_button" style={{display:"flex",justifyContent:"center"}}>*/}
                                {/*    <img style={{position: 'absolute'}} width='100%' height='100%' src={PNG_LoginButtonBack2}/>*/}
                                {/*    <Button className="button" onClick={this.goBack} style={{width:'50%',height:"100%",fontSize:'18px',color:"white",fontWeight:600}} type="text"  >返回选省</Button>*/}
                                {/*</div>*/}
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}