/**
 *
 */
import React, {Component} from 'react'
import ReactEcharts from 'echarts-for-react';
import {HttpClient, HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete,FetchApi} from '../../../api/AxiosUtils';

export default class DeviceOnline extends Component {
    constructor(props) {
        super(props);
        this.state={
            angle:0,//角度，用来做简单的动画效果的
            value:this.props.onlineRate,
            data:[0.8,0.8,0.8,]
        }
    }
  
    //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
     getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
        let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
        return {
            x: x1,
            y: y1
        }
    }

     draw =()=>{
        this.setState({
            angle:this.state.angle+3
        })
        //window.requestAnimationFrame(draw);
    }
    getOptions=(angle,value)=>{
        const _that = this
         return{
             backgroundColor:"rgba(0,0,0,0)",
             title: {
                 text: '{a|'+ value +'}{c|%}',
                 x: 'center',
                 y: 'center',
                 textStyle: {
                     rich:{
                         a: {
                             fontSize: 18,
                             color: '#29EEF3'
                         },

                         c: {
                             fontSize: 10,
                             color: '#ffffff',
                             // padding: [5,0]
                         }
                     }
                 }
             },
             legend: {
                 type: "plain",
                 orient: "vertical",
                 right: 0,
                 top: "10%",
                 align: "auto",
                 data: [],
                 textStyle: {
                     color: "white",
                     fontSize: 16,
                     padding: [10, 1, 10, 0]
                 },
                 selectedMode:false
             },
             series: [ {
                 name: "ring5",
                 type: 'custom',
                 coordinateSystem: "none",
                 renderItem: function(params, api) {
                     return {
                         type: 'arc',
                         shape: {
                             cx: api.getWidth() / 2,
                             cy: api.getHeight() / 2,
                             r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.6,
                             startAngle: (0+angle) * Math.PI / 180,
                             endAngle: (90+angle) * Math.PI / 180
                         },
                         style: {
                             stroke: "#0CD3DB",
                             fill: "transparent",
                             lineWidth: 1.5
                         },
                         silent: true
                     };
                 },
                 data: [0]
             }, {
                 name: "ring5",
                 type: 'custom',
                 coordinateSystem: "none",
                 renderItem: function(params, api) {
                     return {
                         type: 'arc',
                         shape: {
                             cx: api.getWidth() / 2,
                             cy: api.getHeight() / 2,
                             r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.6,
                             startAngle: (180+angle) * Math.PI / 180,
                             endAngle: (270+angle) * Math.PI / 180
                         },
                         style: {
                             stroke: "#0CD3DB",
                             fill: "transparent",
                             lineWidth: 1.5
                         },
                         silent: true
                     };
                 },
                 data: [0]
             }, {
                 name: "ring5",
                 type: 'custom',
                 coordinateSystem: "none",
                 renderItem: function(params, api) {
                     return {
                         type: 'arc',
                         shape: {
                             cx: api.getWidth() / 2,
                             cy: api.getHeight() / 2,
                             r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65,
                             startAngle: (270+-angle) * Math.PI / 180,
                             endAngle: (40+-angle) * Math.PI / 180
                         },
                         style: {
                             stroke: "#0CD3DB",
                             fill: "transparent",
                             lineWidth: 1.5
                         },
                         silent: true
                     };
                 },
                 data: [0]
             }, {
                 name: "ring5",
                 type: 'custom',
                 coordinateSystem: "none",
                 renderItem: function(params, api) {
                     return {
                         type: 'arc',
                         shape: {
                             cx: api.getWidth() / 2,
                             cy: api.getHeight() / 2,
                             r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65,
                             startAngle: (90+-angle) * Math.PI / 180,
                             endAngle: (220+-angle) * Math.PI / 180
                         },
                         style: {
                             stroke: "#0CD3DB",
                             fill: "transparent",
                             lineWidth: 1.5
                         },
                         silent: true
                     };
                 },
                 data: [0]
             }, {
                 name: "ring5",
                 type: 'custom',
                 coordinateSystem: "none",
                 renderItem: function(params, api) {
                     let x0 = api.getWidth() / 2;
                     let y0 = api.getHeight() / 2;
                     let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65;
                     let point = _that.getCirlPoint(x0, y0, r, (90+-angle))
                     return {
                         type: 'circle',
                         shape: {
                             cx: point.x,
                             cy: point.y,
                             r: 2
                         },
                         style: {
                             stroke: "#0CD3DB",//粉
                             fill: "#0CD3DB"
                         },
                         silent: true
                     };
                 },
                 data: [0]
             }, {
                 name: "ring5",  //绿点
                 type: 'custom',
                 coordinateSystem: "none",
                 renderItem: function(params, api) {
                     let x0 = api.getWidth() / 2;
                     let y0 = api.getHeight() / 2;
                     let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65;
                     let point = _that.getCirlPoint(x0, y0, r, (270+-angle))
                     return {
                         type: 'circle',
                         shape: {
                             cx: point.x,
                             cy: point.y,
                             r: 2
                         },
                         style: {
                             stroke: "#0CD3DB",      //绿
                             fill: "#0CD3DB"
                         },
                         silent: true
                     };
                 },
                 data: [0]
             }, {
                 name: '吃猪肉频率',
                 type: 'pie',
                 radius: ['58%', '45%'],
                 silent: true,
                 clockwise: true,
                 startAngle: 90,
                 z: 0,
                 zlevel: 0,
                 label: {
                     normal: {
                         position: "center",

                     }
                 },
                 data: [{
                     value: value,
                     name: "",
                     itemStyle: {
                         normal: {
                             color: { // 完成的圆环的颜色
                                 colorStops: [{
                                     offset: 0,
                                     color: '#4FADFD' // 0% 处的颜色
                                 }, {
                                     offset: 1,
                                     color: '#28E8FA' // 100% 处的颜色
                                 }]
                             },
                         }
                     }
                 },
                     {
                         value: 100-value,
                         name: "",
                         label: {
                             normal: {
                                 show: false
                             }
                         },
                         itemStyle: {
                             normal: {
                                 color: "#173164"
                             }
                         }
                     }
                 ]
             },

                 {
                     name: "",
                     type: "gauge",
                     radius: "58%",
                     center: ['50%', '50%'],
                     startAngle: 0,
                     endAngle: 359.9,
                     splitNumber: 8,
                     hoverAnimation: true,
                     axisTick: {
                         show: false
                     },
                     splitLine: {
                         length: 10,
                         lineStyle: {
                             width: 3,
                             color: "#061740"
                         }
                     },
                     axisLabel: {
                         show: false
                     },
                     pointer: {
                         show: false
                     },
                     axisLine: {
                         lineStyle: {
                             opacity: 0
                         }
                     },
                     detail: {
                         show: false
                     },
                     data: [{
                         value: 0,
                         name: ""
                     }]
                 },

             ]
         }
}


    componentDidMount(){
        this.startSetInterval=setInterval(()=>{
            this.draw()
        },100)
    }
  

    componentWillReceiveProps(props){
        this.setState({
            value:props.onlineRate.replace("%","")
        })
    }

    render() {
        return (
                <ReactEcharts style={{width:"80%",height:'50%'}} option={this.getOptions(this.state.angle,this.state.value)}/>
        )
    }
}