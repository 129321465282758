/**
 *
 */
import React, {Component} from 'react'
import {Card,Divider,Row,Col,Transfer,Form,Layout, Menu , Select,Tree,TreeSelect,Popconfirm, message,Table,DatePicker,Dropdown,Button,Modal,Input,Radio} from 'antd';
import PageTitle from '../../common/pageTitle'
import {reqCollegeGetAllPrimary,reqCollegeGetPagePrincipal,reqCollegeGetPonsibles,reqPrincipalAdd} from '../../api/clApi'
import MyTable from '../../common/myTable/myTable'
import PTree from './tree/principalTree'
import {PAGE_SIZE} from '../../utils/constants'
const {Option} = Select;
export default class  extends Component {
    constructor(props) {
        super(props);
        this.state={
            pageState:'principal',
            /** 搜索受控数据*/
            organizationId_search:null,
            /** 搜索受控数据*/
            groupList:[],
            prinList:[],
            treeData:[],
            TransferTargetKeys:[],
            selectNode:"",
            selectNodeList:[],
            groupName:"选择组名",
            groupUnitList:[],
            loading:false,
            addDevice:true,
            addPrincipalVisible:false,
            addPrincipalVisible2:false,
        }
        this.addPrincipalForm=React.createRef()
        this.addPrincipalForm2=React.createRef()
        this.searchForm=React.createRef()
    }
    componentDidMount(){
        this.getAllPrimary(2)
        // this.getPagePrin(1)
        this.initColumn()

    }

    render() {
        const {loading,prinList,pageState}=this.state
        const  contentList={
            card_title:<PageTitle title="责任人管理"/>,
            card_extra:<div style={{display:'flex'}}>
                    <Button type="primary" onClick={this.handleShowAddPrincipal_2}>添加负责人</Button>
                    {/*<Form ref={this.searchForm} style={{margin:"0 10px "}} name="advanced_search" className="ant-advanced-search-form" >*/}
                        {/*<Row gutter={24}>*/}
                            {/*<Col>*/}
                                {/*<Form.Item style={{marginBottom:10}} label="所属组织" name="organizationId">*/}
                                    {/*<Select placeholder='请选择' style={{width:150}} onChange={value=>{this.setState({organizationId_search:value})}}>*/}
                                        {/*{this.state.treeData.map(item =>{return <Option value={item.id} key={item.id}>{item.name}</Option>})}*/}
                                    {/*</Select>*/}
                                {/*</Form.Item>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                                {/*<Button type="primary"  onClick={this.handleSearch}>搜索</Button>*/}
                                {/*<Button style={{ margin: '0 8px' }} onClick={this.handleSearchReset}>重置</Button>*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                    {/*</Form>*/}
            </div>,
            principal:<div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                <div style={{width:'18%'}}>
                        <PTree parent={this}/>
                </div>
                <div style={{width:'80%'}}>
                    <MyTable column={this.column} data={prinList} loading={loading} pageFun={(c)=>{this.getPagePrin(c,this.state.selectNode?this.state.selectNode.key:null)}}/>
                </div>
            </div>
        }

        return (
            <div className="page_home">
                <Card extra={contentList['card_extra']} className="card_main" title={contentList['card_title']}>

                    {/*<Divider style={{margin:'0px 0px 10px 0px'}} />*/}

                    {/*表*/}
                    <div style={{width:"100%"}}>
                        {contentList[pageState]}
                    </div>
                </Card>

                <Modal
                    forceRender={true}
                    title="添加责任人"
                    visible={this.state.addPrincipalVisible2}
                    onOk={this.addPrincipalHandleOk2}
                    onCancel={this.addPrincipalHandleCancel2}
                >
                    <Form   ref={this.addPrincipalForm2}   >
                        <Form.Item label="责任人姓名" name="name" rules={[{required: true, message: '请输入责任人姓名！'}]}>
                            <Input placeholder='请输入责任人姓名' maxLength={11}/>
                        </Form.Item>
                        <Form.Item label="责任人手机号" name="mobile" validateTrigger={""} rules={[{required: true, message: '请输入责任人手机号！'}]}>
                            <Input placeholder='请输入责任人手机号' maxLength={11} />
                        </Form.Item>
                        <Form.Item label="描述" name="describe" >
                            <Input placeholder='请输入责任人描述' maxLength={50}/>
                        </Form.Item>
                        <Form.Item label="选择组织" name="organizationId" rules={[{required: true, message: '请选择组织'}]}>
                            <Select placeholder='请选择组织'>
                                {this.state.treeData.map(item =>{return <Option value={item.id} key={item.id}>{item.name}</Option>})}
                            </Select>
                        </Form.Item>

                    </Form>


                </Modal>
            </div>
        )
    }

    initColumn=()=>{
        this.column= [

            {
                title: '负责人',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
            },
            {
                title:"电话",
                dataIndex: 'mobileNum',
                key: 'mobileNum',
                align: 'center',
            },
            {
                title:"所属组织",
                dataIndex: 'organizationName',
                key: 'organizationName',
                align: 'center',
            },
            {
                title:"描述",
                dataIndex: 'description',
                key: 'description',
                align: 'center',
            },
            {
                title:"创建时间",
                dataIndex: 'createdAtStr',
                key: 'createdAtStr',
                align: 'center',
            },
        ]
    }
    /**
     *接口请求函数
     * */
        //添加负责人
    addPrincipal=async (principal)=>{
        const res = await reqPrincipalAdd(principal)
        if(res&&res.code===200){
            message.success('添加负责人成功！')
            this.getPagePrin(1,this.state.selectNode.key)
        }else {
            message.error('添加负责人失败！'+res.message)

        }
    }
    /**获取所有最高级组织架构*/
    getAllPrimary=async (type)=>{
        const res = await reqCollegeGetAllPrimary(type);
        if(res && res.results && res.results.length>0){
            this.setState({
                treeData:res.results
            })
        }else {
            message.info(res.message)
        }
    }

    /**分页查询负责人列表*/
        //查询组织下所有负责人
    getPagePrin=async (pageNum,organizationId)=>{
        ///api/safe/device/principal/page.json?page=0&size=10
        let value = {}
        if(organizationId){
            value.organizationId = organizationId
        }
        this.setState({loading:true})
        const res = await reqCollegeGetPonsibles(pageNum-1,PAGE_SIZE,value);
        this.setState({ loading:false})
        if(res&&res.code&&res.code===200){
            res.pageNum = pageNum
            this.setState({prinList:res,pageNum:pageNum})
        }

    }
    handleSearchReset=()=>{
        this.searchForm.current.resetFields()
        this.setState({organizationId_search:null})
    }

    handleSearch=async ()=>{
        let searchValue = this.getSearchValue()
        await this.getPagePrin(1,searchValue)
    }

    handleShowAddPrincipal_2=()=>{
        console.log(this.state.selectNode)
        if(!this.state.selectNode){
            message.info("请先选择左侧组织")
            return
        }
        this.addPrincipalForm2.current.resetFields()
        this.setState({addPrincipalVisible2:true})
    }
    addPrincipalHandleOk2=async ()=>{
        let principalValue = this.addPrincipalForm2.current.getFieldsValue();
        if(!principalValue.name){
            message.info("名字不能为空")
            return
        }
        if(!principalValue.mobile){
            message.info("手机号不能为空")
            return
        }
        if(!principalValue.organizationId){
            message.info("请选择组织")
            return
        }

        await this.addPrincipal(principalValue)
        this.setState({addPrincipalVisible2:false})
        let searchValue = this.getSearchValue()
        if(searchValue.organizationId){
            this.getPagePrin(0,searchValue)
        }

    }
    addPrincipalHandleCancel2=()=>{
        this.setState({addPrincipalVisible2:false})
    }

    getSearchValue = ()=>{
        let searchValue={}
        searchValue.organizationId = this.state.organizationId_search
        searchValue = this.removePropertyOfNull(searchValue)
        return searchValue
    }

    removePropertyOfNull=(obj)=>{
        Object.keys(obj).forEach(item=>{
            if(!obj[item])  delete obj[item]
        })
        return obj;
    }


    addPrincipalHandleOk=async ()=>{
        let principalValue = this.addPrincipalForm.current.getFieldsValue();
        let selectOrganization = this.state.TransferTargetKeys
        principalValue.organizationIds = selectOrganization
        delete principalValue['fullName']
        await this.addPrincipal(principalValue)
        this.getPagePrin(0,{
            organizationId:this.state.selectNode.id
        })
        this.setState({
            addPrincipalVisible:false,
            selectNodeList:[],
            TransferTargetKeys:[],
        })
    }

    addPrincipalHandleCancel=()=>{
        this.addPrincipalForm.current.resetFields()
        this.setState({
            addPrincipalVisible:false,
            selectNodeList:[],
            TransferTargetKeys:[],
        })
    }



    handleShowAddPrincipal=()=>{
        let nodeList = this.getSelectNodeList()
        this.setState({
            selectNodeList:nodeList,
        },()=>{
            this.setState({
                addPrincipalVisible:true,
            })
        })
    }

    setSelectNode=(selectNode)=>{
        console.log('selectNode',selectNode)
        this.setState({
            selectNode
        })
    }

    /**
     * 获取当前选中层级所有数据
     */
    getSelectNodeList=()=>{
        const treeData = this.state.treeData
        const selectNode = this.state.selectNode
        if(selectNode&&selectNode.level>1){
            const fatherNode = this.findFatherNode(treeData,selectNode)
            this.addPrincipalForm.current.setFieldsValue({fullName:fatherNode.fullName})
            return fatherNode.children
            //selectNode.fid
        }else{
            return treeData
        }
    }

    findFatherNode=(List,selectNode)=>{
        for(let i=0;i<List.length;i++){
            if(List[i].id === selectNode.fid){
                let fatherNode = List[i]
                return fatherNode
            }else if(List[i].children&&List[i].children.length>0){
                let  fatherNode =  this.findFatherNode(List[i].children,selectNode)
                if(fatherNode){
                    return fatherNode
                }
            }
        }
    }
}